import React, { Component } from 'react';
import $ from 'jquery';
import { config, DISPATCH_ID } from "../libs/utils/Const";
import { connect } from "react-redux";
import './MenuCategorias.css';

class MenuCategories extends Component {

    state = {
        platforms: [],
        action: 'initial',
    };

    componentDidMount() {
        this.initial();
    }

    initial = () => {
        const internalThis = this;
        $(document).ready(function () {
            $(".menu-categories-button").click(function () {
                internalThis.setFirstCategories();
                $("#menuCategories").toggleClass('open-menu-categories');
                if ($("#menuCategories").hasClass('open-menu-categories')) {
                    $("#menuCategories").css('background', 'rgba(0, 0, 0, 0.425)');
                } else {
                    $("#menuCategories").css('background', 'transparent');
                }
            });
            $("#menuCategories").click(e => {
                if (e.target.id !== 'menuCategories') return;
                $("#menuCategories").toggleClass('open-menu-categories');
                if ($("#menuCategories").hasClass('open-menu-categories')) {
                    $("#menuCategories").css('background', 'rgba(0, 0, 0, 0.425)');
                } else {
                    $("#menuCategories").css('background', 'transparent'); 
                }
            });
        });
    };

    cerrarModal() {
        const menuCategories = $("#menuCategories");
        menuCategories.removeClass('open-menu-categories');
        menuCategories.css('background', 'transparent');
    }

    setFirstCategories = () => {
        const { itemsReducer: { tags } } = this.props;

        let platform = this.setPlatform(tags, 0);

        this.setState({
            platforms: [{ platform }],
            action: 'initial'
        })

    };

    getChildren = (father) => {
        const { platforms } = this.state;
        if (!father.children.length) return;
        let platform = this.setPlatform(father.children, platforms.length);
        platforms.push({ platform });
        this.setState({
            platforms: platforms,
            action: 'push',
        })

    };

    goBack = () => {
        const { platforms } = this.state;
        this.setState({
            action: 'pop',
        })
    };

    hover = name => {
        $('.platforms li:hover').css({ "background-color": config.navBar.menuCategoriesBackgroundHover });
    };

    leaveHover = name => {
        $('.platforms li').css({ "background-color": 'transparent' });
    };

    setPlatform = (tags, index) => {

        return <div key={index} className={'platforms platform-effect'} id={'platform' + index} style={{ transform: 'translate3d( -' + ((index === 0 || index === 1) ? 0 : index - 1) + '00%,0,0)' }}>
            <div className="menu-burger">
            <div className='' id='menuTitle' onClick={index === 0
                ? () => { }
                : () => this.goBack(index)}>
                <div className='d-flex justify-content-between'>
                    <div className='pl-5 pt-5'>
                        <img src={config.navBar.iconoSerdi} width={50} height={50}/>
                    </div>
                    <div id='cerrar' onClick={this.cerrarModal}>
                        <p className='circulo'>x</p>
                    </div>
                </div>
                <div className='col-4 text-left' style={{ fontSize: 25, margin: 0, padding: 0 }}>
                    {index === 0
                        ? <i className="fas" />
                        : <i className={config.icons.arrowLeft} style={{ margin: 0, padding: 0, marginLeft: 10 }} />
                    }
                </div>

            </div>
                <ul>
                    {tags.map((tag, index) => {
                        return <li key={tag.category.code + tag.category.name + index} id={tag.category.code + tag.category.name + index} className='row justify-content-center'
                            style={{
                                margin: 3,
                                padding: 0,
                                padding: 5,
                                color: config.navBar.textColorCategorie,
                                fontSize: 20,
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }} onMouseOver={() => this.hover(tag.category.code + tag.category.name + index)}
                            onMouseLeave={() => this.leaveHover(tag.category.code + tag.category.name + index)}
                            onClick={tag.category.enabled ? () => this.search(tag.category) : () => this.getChildren(tag)}>
                            <div className='col-10 text-left' style={{
                                paddingLeft: 10,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                color: '#ffff'
                            }}>
                                {tag.category.name}
                            </div>
                        </li>
                    })}
                </ul>
            </div>
        </div>;
    };

    search = async (category) => {
        const { setCategory, setIdCategory, setLocation, itemsReducer: { searchByCategories }, setNextPage } = this.props;
        await setCategory(category.name);
        await setIdCategory(category.name);
        await setNextPage(0);
        await setLocation('menuCategories');
        await searchByCategories(category.name);
    };

    getPlatforms = () => {
        const { action, platforms } = this.state;
        return platforms.map(platform => {
            return platform.platform
        });
    };

    addClass = () => {
        const { platforms, action } = this.state;


        if (action === 'stop') return;

        setTimeout(() => {
            if (action === 'initial') {
                $('#platform0').css('transform', 'translate3d(0%,0,0)');
            }

            if (action === 'push') {
                platforms.map((platform, index) => {
                    let newTranslate = index;
                    if (index !== platforms.length - 1) {
                        newTranslate = index + 1;
                    }
                    $('#platform' + index).css('transform', 'translate3d( -' + (newTranslate) + '00%,0,0)');
                });
            }

            if (action === 'pop') {
                platforms.map((platform, index) => {
                    let newTranslate = 0;
                    if (index === platforms.length - 2) {
                        $('#platform' + index).css('transform', 'translate3d(-' + (index) + '00%,0,0)');
                    }
                    if (index === platforms.length - 1) {
                        $('#platform' + index).css('transform', 'translate3d(-' + (index - 1) + '00%,0,0)');
                    }

                });

                setTimeout(() => {
                    platforms.pop();
                    this.setState({
                        platforms,
                        action: 'stop',
                    })
                }, 500)

            }
        }, 10)
    };

    render() {
        const { platforms } = this.state;
        return (
            <div className="menu-categories" id='menuCategories'>
                <nav className="menu-sub-categories" style={{ background: config.navBar.menuCategoriesBackground }}>
                    {this.getPlatforms()}
                </nav>
                {this.addClass()}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return { itemsReducer: store.ItemsReducer };
};

const mapDispatchToProps = dispatch => {
    return {
        setCategory: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_CATEGORY, value }),
        setIdCategory: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value }),
        setNextPage: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value }),
        setLocation: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_LOCATION, value }),
    };
};

function Prueba(valor) {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuCategories);
export { Prueba }
