import React, { Component } from 'react';
import { ApiClient } from "../libs/apiClient/ApiClient";
import { config, DISPATCH_ID, ROLES, SERVICE_API, SERVICE_RESPONSE } from "../libs/utils/Const";
import { connect } from "react-redux";
import Modal from './Modal';
import $ from 'jquery';

let modal = new Modal();

let apiClient = ApiClient.getInstance();

class SearchItemsActions extends Component {

    componentDidMount() {
        const {
            setSearchItemsByKeyReference,
            setSearchItemsByCategoriesReference,
            updateFavoriteReference,
            addShoppingCartReference,
            addBackOrderReference,
            deleteShoppingCartReference,
            deleteBackOrderReference,
            openItemDetailsReference,
            searchByDashOption
        } = this.props;

        setSearchItemsByKeyReference(this.searchItemsByKey);
        updateFavoriteReference(this.updateFavorite);
        addShoppingCartReference(this.addShoppingCart);
        addBackOrderReference(this.addBackOrder);
        deleteBackOrderReference(this.deleteBackOrder);
        deleteShoppingCartReference(this.deleteShoppingCart);
        openItemDetailsReference(this.openItemDetails);
        setSearchItemsByCategoriesReference(this.searchItemsByCategories);
        searchByDashOption(this.searchItemsByCategoriesHome);
        this.searchItemsByCategoriesHome();
        this.searchAutoComplete();
    }


    searchAutoComplete = async () => {
        const { setItemsAutoComplete } = this.props;
        let suggestions = await apiClient.AutoComplete();
        if (suggestions.data) {
            suggestions = suggestions.data.data;
            setItemsAutoComplete(suggestions);
        }
    }

    searchItemsByKey = async (page = 0, view = null, isPagination = false) => {
        const { setMinPrice,setMaxPrice,sessionReducer: { role }, itemsReducer, setItemsCategories, setItemsFilters, setItemsSearch, setTotalRows, notificationReducer: { showAlert }, configReducer: { history }, enableSpinner, setItemsViewSearch, itemsReducer:{location}, setLocation} = this.props;
        let key = 'FindAll';
        let uniqueFilter = '';
        let user = {};
        let whs = '';
        let currentUser = {};
        await setItemsViewSearch(view)
        const { itemsReducer: { viewOneS } } = this.props
        if(location === ('menuCategories') || location === ('menuMarcas')){
            setLocation('navBar');
        }
        try {
            user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
            currentUser = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser'));
            whs = user.U_FMB_Handel_ALMA || '01';
        } catch (error) {
            whs = '01';
            currentUser = {};
        }
        if (itemsReducer.search && itemsReducer.search !== '') {

            key = itemsReducer.search;
        }

        // if(uniqueFilter.length !== 0  && page == 0){

        //     if(view == null) {
        //  setItemsFilters({});

        //     }
        // } 

        if (itemsReducer.uniqueFilter && !isPagination) {
            uniqueFilter = Object.entries(itemsReducer.uniqueFilter).length !== 0 ? itemsReducer.uniqueFilter : '';
        } else if (itemsReducer.uniqueFilter && isPagination) {
            setItemsFilters({});
            uniqueFilter = '';
        }

        let localShoppingCart = undefined;
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }

        enableSpinner(true);
        let response = null;
        //comenar por si falla
        if (view === "Lite") {
            uniqueFilter =
                { property: 'searchLite', value: '1' };
        }

        let k2 = key.split(/[~,]/)
        let key1 = ''
        for (let index = 0; index < k2.length; index++) {
            const element = k2[index];
            if (element.trim() != '' && element.length > 5) {
                key1 = element.trim();
            }

        }
        key1.replace(/['"]/, '');

        const search = {
            key: key1 || key,
            localShoppingCart: localShoppingCart,
            whs: whs,
            page: page,
            uniqueFilter: uniqueFilter || '',
            view: await viewOneS || '',
        }

        response = await apiClient.searchByKey(search);
        page = page === 0 ? 1 : page / 30 + 1;
        localStorage.setItem('currentPage', page);

        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            setItemsSearch(response.data.results);
            setTotalRows(response.data.totalRows);
            setItemsCategories(response.data.allCategories);
            setMinPrice(response.data.allCategories.pricesSideBar.min || 0 );
            setMaxPrice(response.data.allCategories.pricesSideBar.max || 0 );
            this.applyFilters(response.data.results);
            if (view === 'marcaOne') {
                history.goItemsPolar();
            } else if (view === 'marcaTwo') {
                history.goItemsBlanca();
            } else if (view === 'marcaThree') {
                history.goItemsRoutlet();
            } else if (view === 'marcaFour') {
                history.goItemsMarcaFour();
            } else if (view === 'marcaFive') {
                history.goItemsMarcaFive();
            } else if (view === 'Lite') {
                history.goQuote();
            } else if (view === 'vitrinaView') {
                // history.goItemsRoutlet();
            } else {
                history.goItems();
            }
            return;
        }
        if (currentUser === null) {
            showAlert({ type: 'warning', message: "No se ha seleccioando un cliente", timeOut: 8000 });

        } else {
            showAlert({ type: 'error', message: response.message, timeOut: 8000 });

        }
    };

    searchItemsByCategories = async (category, page = 0, view = null) => {
        const { sessionReducer: { role }, itemsReducer: { nextPage }, setItemsCategories, setItemsSearch, setTotalRows, notificationReducer: { showAlert }, configReducer: { history }, enableSpinner } = this.props;
        let whs = '';
        let user = {};
        let currentUser = {};

        try {
            user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
            currentUser = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser'));
            whs = user.U_FMB_Handel_ALMA || '01';
        } catch (error) {
            whs = '01';
            currentUser = {};

        }

        let localShoppingCart = undefined;
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }

        enableSpinner(true);
        let response = await apiClient.searchByCategory(category, localShoppingCart, whs, page);
        page = page === 0 ? 1 : page / 30 + 1;
        localStorage.setItem('currentPage', page);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            setItemsSearch(response.data.results);
            setTotalRows(response.data.totalRows);
            setItemsCategories(response.data.allCategories);
            this.applyFilters(response.data.results);
            setTimeout(() => {
                if (view === 'marcaOne') {
                    history.goItemsPolar();
                } else if (view === 'marcaTwo') {
                    history.goItemsBlanca();
                } else if (view === 'marcaThree') {
                    history.goItemsRoutlet();
                } else if (view === 'marcaFour') {
                    history.goItemsMarcaFour();
                } else if (view === 'marcaFive') {
                    history.goItemsMarcaFive();
                } else if (view === 'Lite') {
                    history.goQuote();
                } else if (view === 'vitrinaView') {
                    // history.goItemsRoutlet();
                } else {
                    history.goItems();
                }

            }, 500);

            return;
        }
        if (currentUser === null) {
            showAlert({ type: 'warning', message: "No se ha seleccioando un cliente", timeOut: 8000 });

        } else {
            showAlert({ type: 'error', message: response.message, timeOut: 8000 });

        }
    };


    applyFilters = data => {
        const { setItemsFilterSearch, enableSpinner } = this.props;
        // enableSpinner(true);
        setItemsFilterSearch(data);
        // enableSpinner(false);

        $("#menuCategories").removeClass('open-menu-categories');
    };

    searchItemsByCategoriesHome = async () => {
        const { sessionReducer: { role }, setItemsSearch1, setItemsSearch2, notificationReducer: { showAlert }, enableSpinner } = this.props;

        let localShoppingCart = undefined;

        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }

        enableSpinner(true);

        let data = {
            shoppingCart: localShoppingCart,
            nextNum: 0
        };


        await apiClient.ProductsEspecial(data).then(result => {
            if (result.status) {
                setItemsSearch1(result.data.responseBody);
                this.applyFilters1(result.data.responseBody);
                setItemsSearch2(result.data.responseBody2);
                this.applyFilters2(result.data.responseBody2);
            }

        });
        enableSpinner(false);
    };

    applyFilters1 = data => {
        const { setItemsFilterSearch1, enableSpinner } = this.props;
        enableSpinner(true);
        setItemsFilterSearch1(data);
        enableSpinner(false);

        $("#menuCategories").removeClass('open-menu-categories');
    };

    applyFilters2 = data => {
        const { setItemsFilterSearch2, enableSpinner } = this.props;
        enableSpinner(true);
        setItemsFilterSearch2(data);
        enableSpinner(false);

        $("#menuCategories").removeClass('open-menu-categories');
    };

    updateFavorite = async item => {
        const { sessionReducer: { role }, itemsReducer: { items }, notificationReducer: { showAlert }, enableSpinner } = this.props;



        if (role === ROLES.PUBLIC) {
            await this.needToCreateAccount();
            return;
        }
        enableSpinner(true);
        let response = await apiClient.updateFavorite(item);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 8000 });
            return;
        }
        item.favorite = response.data.value; // change item details

        items.filter(itemFilter => {
            if (item.ItemCode === itemFilter.ItemCode) {
                itemFilter.favorite = response.data.value;
            }
        });

        this.applyFilters(items);

    };

    needToCreateAccount = () => {
    };

    addShoppingCart = async data => {
        const {
            enableSpinner,
            itemsReducer: { items: itemsSearch, itemDetails, items1, items2 },
            sessionReducer: { role },
            shoppingCartReducer: { items },
            notificationReducer: { showAlert },
            setShoppingCart,
            setItemDetailsSearch
        } = this.props;

        if (role === ROLES.PUBLIC) {
            this.addShoppingCartPublic(data);
            return;
        }
        enableSpinner(true);
        let response = await apiClient.updateShoppingCart(data);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 8000 });
            return;
        }

        let exist = response.data.value;
        let shoppingCardUpdate = response.data.shoppingCart || [];

        if (!exist) {
            items.push({ ItemCode: data.item.ItemCode, quantity: data.quantity });
        }

        for (let index = 0; index < shoppingCardUpdate.length; index++) {
            const itemUpdate = shoppingCardUpdate[index];
            for (let index = 0; index < itemsSearch.length; index++) {
                const item = itemsSearch[index];
                if (item.ItemCode === itemUpdate.ItemCode) {
                    item.quantity = itemUpdate.quantity;
                    item.PriceTaxAfterDisc = itemUpdate.isNewPrice ? itemUpdate.PriceTaxAfterDisc : item.PriceTaxAfterDisc;
                    item.DiscountPercentSpecial = itemUpdate.isNewPrice ? itemUpdate.DiscountPercentSpecial : item.DiscountPercentSpecial;
                }
            }
        }

        for (let index = 0; index < shoppingCardUpdate.length; index++) {
            const itemUpdate = shoppingCardUpdate[index];
            if (itemUpdate.ItemCode === itemDetails.ItemCode) {
                itemDetails.quantity = itemUpdate.quantity
                itemDetails.PriceTaxAfterDisc = itemUpdate.isNewPrice ? itemUpdate.PriceTaxAfterDisc : itemDetails.PriceTaxAfterDisc;
                itemDetails.DiscountPercentSpecial = itemUpdate.isNewPrice ? itemUpdate.DiscountPercentSpecial : itemDetails.DiscountPercentSpecial;
            }

        }

        for (let index = 0; index < shoppingCardUpdate.length; index++) {
            const itemUpdate = shoppingCardUpdate[index];
            for (let index = 0; index < items1.length; index++) {
                const item = items1[index];
                if (item.ItemCode === itemUpdate.ItemCode) {
                    item.quantity = itemUpdate.quantity;
                    item.PriceTaxAfterDisc = itemUpdate.isNewPrice ? itemUpdate.PriceTaxAfterDisc : item.PriceTaxAfterDisc;
                    item.DiscountPercentSpecial = itemUpdate.isNewPrice ? itemUpdate.DiscountPercentSpecial : item.DiscountPercentSpecial;
                }
            }
        }

        for (let index = 0; index < shoppingCardUpdate.length; index++) {
            const itemUpdate = shoppingCardUpdate[index];
            for (let index = 0; index < items2.length; index++) {
                const item = items2[index];
                if (item.ItemCode === itemUpdate.ItemCode) {
                    item.quantity = itemUpdate.quantity;
                    item.PriceTaxAfterDisc = itemUpdate.isNewPrice ? itemUpdate.PriceTaxAfterDisc : item.PriceTaxAfterDisc;
                    item.DiscountPercentSpecial = itemUpdate.isNewPrice ? itemUpdate.DiscountPercentSpecial : item.DiscountPercentSpecial;
                }
            }
        }



        for (let index = 0; index < shoppingCardUpdate.length; index++) {
            const itemUpdate = shoppingCardUpdate[index];
            for (let index = 0; index < items.length; index++) {
                const item = items[index];
                if (item.ItemCode === itemUpdate.ItemCode && data.item.ItemCode === itemUpdate.ItemCode) {
                    item.quantity = itemUpdate.quantity;
                    item.PriceAfterDiscount = itemUpdate.isNewPrice ? Number((Number(item.PriceBeforeDiscount) - Number(Number(item.PriceBeforeDiscount) * Number(itemUpdate.DiscountPercentSpecial / 100)  )).toFixed(2)) : Number(item.PriceBeforeDiscount);
                    item.PriceTaxAfterDisc = itemUpdate.isNewPrice ? (Number((item.PriceAfterDiscount + (item.PriceAfterDiscount * (Number(item.taxRate) / 100)) ).toFixed(2))) : item.PriceTaxAfterDisc;
                    item.DiscountPercentSpecial = itemUpdate.isNewPrice ? itemUpdate.DiscountPercentSpecial : item.DiscountPercentSpecial;
                }
            }
        }

        await this.applyFilters(itemsSearch);
        await this.applyFilters1(items1);
        await this.applyFilters2(items2);
        await setItemDetailsSearch(itemDetails);
        await setShoppingCart(items);
    };

    addShoppingCartPublic = async data => {
        const { itemsReducer: { items: itemsSearch, items1, items2 }, shoppingCartReducer: { items }, setShoppingCart } = this.props;


        let localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
        localShoppingCart = JSON.parse(localShoppingCart) || [];

        let exist = localShoppingCart.filter((itemFilter) => {
            return (itemFilter.ItemCode === data.item.ItemCode)
        });

        if (!exist.length) {
            localShoppingCart.push({ ItemCode: data.item.ItemCode, quantity: data.quantity });
            items.push({ ItemCode: data.item.ItemCode, quantity: data.quantity });
        } else {
            localShoppingCart.map((itemMap) => {
                if (data.item.ItemCode === itemMap.ItemCode) {
                    itemMap.quantity = data.quantity;
                }
            });
            items.map((itemMap) => {
                if (data.item.ItemCode === itemMap.ItemCode) {
                    itemMap.quantity = data.quantity;
                }
            });
        }


        let newItem = undefined;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.quantity = data.quantity;
                newItem = data.item;
                newItem.quantity = data.quantity;
            }
        });


        items.map(itemFilter => {
            if (data.item.ItemCode === itemFilter.ItemCode) {
                itemFilter.quantity = data.quantity;
            }
        });

        for (let index = 0; index < items1.length; index++) {
            const item = items1[index];
            if (item.ItemCode === data.item.ItemCode) {
                item.quantity = data.quantity;
            }
        }
        for (let index = 0; index < items2.length; index++) {
            const item = items2[index];
            if (item.ItemCode === data.item.ItemCode) {
                item.quantity = data.quantity;
            }
        }


        localStorage.setItem(config.general.localStorageNamed + 'shoppingCart', JSON.stringify(localShoppingCart));
        setShoppingCart(items);
        this.applyFilters(itemsSearch);
        this.applyFilters1(items1);
        this.applyFilters2(items2);
    };


    deleteShoppingCart = async data => {
        const { enableSpinner,itemsReducer: { items: itemsSearch, items1, items2 }, sessionReducer: { role }, shoppingCartReducer: { items }, notificationReducer: { showAlert }, setShoppingCart } = this.props;
        enableSpinner(true)
        if (role === ROLES.PUBLIC) {
            this.deleteShoppingCartPublic(data);
            return;
        }

        let response = await apiClient.deleteShoppingCart(data);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 8000 });
            return;
        }

        let newItems = items.filter(itemFilter => {
            return (itemFilter.ItemCode !== data.item.ItemCode)
        });

        if (data.deleteAll) {
            newItems = [];
        }
        setShoppingCart(newItems);

        let newItem = undefined;
       
        if(data.deleteAll){
            for (let index = 0; index < items1.length; index++) {
                const item = items1[index];
                if(Number(item.quantity) > 1){
                    item.quantity = ''
                } 
            }
            for (let index = 0; index < items2.length; index++) {
                const item = items2[index];
                if(Number(item.quantity) > 1){
                    item.quantity = '';
                }
            }
            for (let index = 0; index < itemsSearch.length; index++) {
                const item = itemsSearch[index];
                if(Number(item.quantity) > 1){
                    item.quantity = '';
                }
            }
        }else{
            for (let index = 0; index < itemsSearch.length; index++) {
                const item = itemsSearch[index];
                if (item.ItemCode === data.item.ItemCode) {
                    item.quantity = '';
                    newItem = data.item;
                    newItem.quantity = '';
                }
            }
            for (let index = 0; index < items1.length; index++) {
                const item = items1[index];
                if (item.ItemCode === data.item.ItemCode) {
                    item.quantity = '';
                }
            }
            for (let index = 0; index < items2.length; index++) {
                const item = items2[index];
                if (item.ItemCode === data.item.ItemCode) {
                    item.quantity = '';
                }
            }
        }
        
        this.applyFilters(itemsSearch);
        this.applyFilters1(items1);
        this.applyFilters2(items2);
        enableSpinner(false)
        //  this.changeInfoDetails(newItem);
    };

    deleteShoppingCartPublic = data => {
        const { itemsReducer: { items: itemsSearch }, shoppingCartReducer: { items }, setItemsSearch, setShoppingCart } = this.props;


        let localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');

        localShoppingCart = JSON.parse(localShoppingCart) || [];

        let newItems = items.filter(itemFilter => {
            return (itemFilter.ItemCode !== data.item.ItemCode)
        });

        if (data.deleteAll) {
            newItems = [];
        }

        localStorage.setItem(config.general.localStorageNamed + 'shoppingCart', JSON.stringify(newItems));
        setShoppingCart(newItems);

        let newItem = undefined;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.quantity = '';
                newItem = data.item;
                newItem.quantity = '';
            }
        });
        this.applyFilters(itemsSearch);
        //this.changeInfoDetails(newItem);
    };

    addBackOrder = async data => {
        const { itemsReducer: { items }, sessionReducer: { role }, shoppingCartReducer: { backOrder }, notificationReducer: { showAlert }, enableSpinner, setBackOrder } = this.props;


        if (Number(data.quantity) >= 100) {
            items.map(itemFilter => {
                if (itemFilter.ItemCode === data.item.ItemCode) {
                    data.quantity = itemFilter.OnHandPrincipal >= 100 ? "100" : itemFilter.OnHandPrincipal;
                }
            });

            showAlert({ type: 'warning', message: 'Se excede el número maximo de artículos ', timeOut: 8000 });
        }

        if (role === ROLES.PUBLIC) {
            this.needToCreateAccount();
            return;
        }

        enableSpinner(true);
        let response = await apiClient.updateBackOrder(data);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 8000 });
            return;
        }

        let exist = response.data.value;
        if (!exist) {
            backOrder.push({ ItemCode: data.item.ItemCode, quantity: data.quantity });
            setBackOrder(backOrder);
        }

        let newItem = undefined;
        items.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.backOrder = true;
                newItem = data.item;
                newItem.backOrder = true;
            }
        });
        this.applyFilters(items);
        //this.changeInfoDetails(newItem);
    };


    deleteBackOrder = async data => {
        const { itemsReducer: { items }, sessionReducer: { role }, shoppingCartReducer: { backOrder }, notificationReducer: { showAlert }, enableSpinner, setBackOrder } = this.props;


        if (role === ROLES.PUBLIC) {
            this.needToCreateAccount();
            return;
        }

        enableSpinner(true);
        let response = await apiClient.deleteBackOrder(data);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 8000 });
            return;
        }

        let newItems = backOrder.filter(itemFilter => {
            return (itemFilter.ItemCode !== data.item.ItemCode)
        });

        if (data.deleteAll) {
            newItems = [];
        }


        setBackOrder(newItems);

        let newItem = undefined;
        items.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.backOrder = false;
                newItem = data.item;
                newItem.backOrder = false;
            }
        });
        this.applyFilters(items);
        //this.changeInfoDetails(newItem);
    };

    openItemDetails = async item => {
        const { sessionReducer: { role }, setItemDetailsSearch, notificationReducer: { showAlert }, configReducer: { history }, enableSpinner } = this.props;

        let localShoppingCart = undefined;
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }

        enableSpinner(true);
        let response = await apiClient.openItemDetails(item.ItemCode, localShoppingCart);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'error', message: response.message, timeOut: 8000 });
            return;
        }

        let images = [];
        // Imagen del comentario en SAP
        // Arreglo de imagenes del campo extra
        if(response.data.PicturName){
            images.push({ path: (response.data.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + response.data.PicturName) : require('../images/noImage.png')) });
        }
        let imagesArray = response.data.U_Handel_ImagesArray || '';
        imagesArray = imagesArray.split('|');
        imagesArray.map(image => {
            if (image) {
                images.push({ path: (image ? (config.BASE_URL + SERVICE_API.getImage + '/' + image) : require('../images/noImage.png')) });
            }
        });

        // Arreglo de fichas tecnicas 
        let files = [];
        let fileArray = response.data.U_Handel_attachment || '';
        fileArray = fileArray.split('|');
        fileArray.map(file => {
            if (file) {
                files.push({ path: (file ? (config.BASE_URL + SERVICE_API.getFile + '/' + file) : ''), name: file });
            }
        });

        response.data.images = images;
        response.data.files = files;

        setItemDetailsSearch(response.data);

        setTimeout(() => {
            history.goItemsDetails(response.data.ItemName, response.data.ItemCode, response.data.ItemCode, response.data.U_FMB_Handel_Marca);
        }, 10)

    };

    changeInfoDetails = item => {
        const { setItemDetailsSearch } = this.props;
        if (item) {
            setItemDetailsSearch(item);
        }
    };

    render() {
        return (
            <div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openItemDetailsReference: value => dispatch({ type: DISPATCH_ID.ITEMS_OPEN_ITEM_DETAILS_REFERENCE, value }),
        updateFavoriteReference: value => dispatch({ type: DISPATCH_ID.ITEMS_UPDATE_FAVORITE_REFERENCE, value }),
        addShoppingCartReference: value => dispatch({ type: DISPATCH_ID.ITEMS_ADD_SHOPPING_CART_REFERENCE, value }),
        addBackOrderReference: value => dispatch({ type: DISPATCH_ID.ITEMS_ADD_BACK_ORDER_REFERENCE, value }),
        deleteBackOrderReference: value => dispatch({ type: DISPATCH_ID.ITEMS_DELETE_BACK_ORDER_REFERENCE, value }),
        deleteShoppingCartReference: value => dispatch({ type: DISPATCH_ID.ITEMS_DELETE_SHOPPING_CART_REFERENCE, value }),
        setSearchItemsByKeyReference: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_KEY_REFERENCE, value }),
        setSearchItemsByCategoriesReference: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_CATEGORIES_REFERENCE, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
        setTotalRows: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_TOTALROWS, value }),
        setItemDetailsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value }),
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        setBackOrder: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_BACK_ORDER, value }),
        setItemsCategories: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_CATEGORIES, value }),
        setItemsFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value }),
        setItemsAutoComplete: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_AUTO_COMPLETE, value }),
        setItemsSearch1: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS1, value }),
        setItemsSearch2: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS2, value }),
        setItemsFilterSearch1: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER1, value }),
        setItemsFilterSearch2: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER2, value }),
        searchByDashOption: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION, value }),
        setItemsViewSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_VIEW, value }),
        setMinPrice: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_MIN_PRICE, value }),
        setMaxPrice: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_MAX_PRICE, value }),
        setLocation: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_LOCATION, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchItemsActions)