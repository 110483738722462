import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import {
    NavBarLogo,
    NavBarShoppingCartIcon,
    NavBarContentSearchItems,
    LoginRegisterModal,
    MenuCategories,
    ProfieldModel,
    Quote,
    WarehouseModal,
    QuotationModal,
    PriceModel,
    SetZipCodeNavBarModal
} from "./index";
import { config, ROLES, DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE } from '../libs/utils/Const';
import $ from "jquery";
import './navBar.css';
import './navbarNew.css'
import { ApiClient } from "../libs/apiClient/ApiClient";
import moment from 'moment';
let apiClient = ApiClient.getInstance();

class NavBar extends Component {


    constructor(props) {
        super(props);

        this.state = {
            marcas: [],
            aparatos: [],
            refacciones: [],
            fabricantes: [],
            materiales: [],
            dataForm: {
                name: '',
                lastName: '',
                email: '',
                phone: '',
                address: '',
                message: ''
            },
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')), // Propiedades del vendedor, se visulaizan en el aplication
            vendor: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'vendor')),
            user: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')),
            requestQuotation: {
                name: '',
                lastName: '',
                email: '',
                phone: '',
                adddress: '',
                message: ''
            },
            enableSetZipCodeModal: false,
            zipCodeUser: '',
            empID: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
            currentUser: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser')),
            locations: [],
            selectedLocation: '',
            isLoaded:false

        };
    }
    //Evento para capturar los valores de los campos
    handelChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value
        });
    };

    async componentDidMount() {
        const {sessionReducer: {role}}=this.props;
        const {currentUser} = this.state;
        if(role === ROLES.PUBLIC && !currentUser){ //Publico y localStorage limpio
            const url = window.location;
            if (url.href.includes("/#/") || url.pathname === '/') { //Validar que sea recargar pagina o primera vez
                this.LaunchSelectZipCode();
            }
        }
    }

    toggleMenuUser = () => {
        $("#menu-user-button").click(function () {
            $(".menu-user").toggle();
            $(".menu-user-responsive").focus();
        });
    }

    handleViews = async (opcion, page = 0) => {
        const { itemsReducer, setIdCategory, setLocation } = this.props;
        setIdCategory(null);
        setLocation(opcion);
        await itemsReducer.searchByKey(0, opcion, true);
    }

    renderName = () => {
        const {  sessionReducer: { user } } = this.props;
        const { seller } = this.state;
        if (seller !== null) {
            return (
                <span>
                    <span className="ml-2" style={{ color: config.navBar.textColor, font: '-webkit-control', fontSize: '9px' }}>{seller.firstName} {seller.lastName} {/*{(seller.firstName != '' && user.CardName) ? ':' : ''}*/}</span>
                    <br />
                    <span className="ml-2" style={{ color: config.navBar.textColor, font: '-webkit-control', fontSize: '9px' }}>{user.CardName}</span>
                    <br />
                </span>
            );
        } else {
            return (
                <span className="ml-2" style={{ color: config.navBar.textColor }}>{user.CardName}</span>
            );
        }
    }

    iconUser = (priceList) => {
        // switch (priceList) {
        //     case 4:
        //         result = config.Avatar.bronze1;
        //         break;
        //     case 5:
        //         result = config.Avatar.silver1;
        //         break;
        //     case 6:
        //         result = config.Avatar.gold1;
        //         break;
        //     case 7:
        //         result = config.Avatar.platinum1;
        //         break;
        //     case 9:
        //         result = config.Avatar.diamond1;
        //         break;
        //     case 13:
        //         result = config.Avatar.mercado1;
        //         break;
        //     default:
        //         result = config.Avatar.red1;
        //         break;
        // }
        // return result
    }

    nivel = (priceList) => {
        let result = '';
        switch (priceList) {
            case 4:
                result = 'Bronze';
                break;
            case 5:
                result = 'Silver';
                break;
            case 6:
                result = 'Gold';
                break;
            case 7:
                result = 'Platinum';
                break;
            case 9:
                result = 'Diamond';
                break;
            case 13:
                result = 'Filial';
                break;
            default:
                result = '';
                break;
        }
        return result
    }

    renderClientOptionFirstNavBar = () => {
        const { configReducer, isShowMarcas } = this.props;
        const { sessionReducer: { user, role } } = this.props;
        const { seller } = this.state;
        return (
            <ul className="navbar-nav miCuenta d-md-flex ml-4" style={{ maxWidth: 200 }}>
                <li className="nav-item">
                    <span className="imgAvatar" style={{ display: "flex", alignItems: "center" }}>
                        {/* <i id="IconUser" className={config.icons.user} aria-hidden="true" style={{ color: config.navBar.iconColor2, fontSize: 25 }} /> */}
                        <img alt='' src={/*this.iconUser(user.listNum)*/ config.navBar.avatar} style={{ color: config.navBar.iconColor2 }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                        {role !== ROLES.PUBLIC ?<a className="nav-link dropdown-toggle profile-text" style={{ display: "flex", alignItems: "center" }} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {/* <span className="ml-2" style={{widh:150, maxWidth: 200, color: "#212E57"}}><strong>Bienvenido </strong><br/>{user.CardName}</span> */}
                            <div className="marq" style={{ whiteSpace: "pre-wrap", font: "-webkit-control", marginBottom: '20px', fontWeight: 'bolder' }}><span className="">{this.renderName()}</span></div><br />
                            {/* <label className="justify-content-left" ><span className="text-left font-weight-bold">Nivel: {this.nivel(user.listNum)}</span></label> */}
                        </a>: <></>}
                        {/* <div className="dropdown"> */}
                        {/* CAMBIOS 117-MARINROCKS */}
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {seller ? seller.U_FMB_Handel_Perfil !== '0' && seller.U_FMB_Handel_Perfil !== 5 && seller.U_FMB_Handel_Perfil !== 3 ?
                                <a className="dropdown-item" onClick={() => { configReducer.history.goSelectClient(); }}>Mis clientes</a>
                                : "" : ""
                            }
                            {seller ? seller.U_FMB_Handel_Perfil !== '0' && seller.U_FMB_Handel_Perfil !== 5 && seller.U_FMB_Handel_Boletin === 1 && seller.U_FMB_Handel_Perfil !== 3 ?
                                <a className="dropdown-item" style={{ color: "#C55930 !important" }} onClick={() => { configReducer.history.goBoletin(); }}>Ver correos del boletín</a>
                                : "" : ""
                            }
                            {/* {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ? 
                                <a className="dropdown-item" onClick={() => { configReducer.history.goAnalytics(); }}>Analytics</a>
                                : "" : ""
                            } */}
                            {seller ? (seller.U_FMB_Handel_Perfil === 5 && user.U_FMB_AutorizarBoni === 1) || (seller.U_FMB_Bonificaciones === 1 && seller.U_FMB_Handel_Perfil !== 3)  ?
                                <a className="dropdown-item" onClick={() => { configReducer.history.goPromocionales(); }}>Bonificaciones</a>
                                : "" : ""
                            }
                            {/* {Object.entries(user).length !== 0 && } */}
                            {config.modules.subirArchivoExcel && seller ? seller.U_FMB_Handel_Perfil !== 5 && Object.entries(user).length !== 0 && seller.U_FMB_Handel_Perfil !== 3 ?
                                <Link to='/subirArchivo' className="dropdown-item" >Subir archivo OC</Link>
                                : "" : ""
                            }
                            {seller && seller.U_FMB_Handel_Perfil === 3 ? (
                                <Link to='/administrador' className="dropdown-item"> Administrador</Link>
                                ) : ''
                            }
                            <span onClick={() => this.goPedidos()} className="dropdown-item">
                                Mis reportes
                            </span>
                            
                            {seller ? seller.U_FMB_Handel_Perfil !== '0' && seller.U_FMB_Handel_Perfil !== 5 && seller.U_FMB_Handel_Perfil !== 3  ?
                                <a className="dropdown-item" onClick={() => { configReducer.history.goCardSaveds(); }}>Carritos guardados</a>
                                : "" : ""
                            }
                            {/* <a className="dropdown-item" onClick={() => { configReducer.history.goOrders(); }}>Mis pedidos</a> */}
                            {user.wishlist === 1 &&
                                <a className="dropdown-item" onClick={() => { configReducer.history.goBackOrder(); }}>Lista de deseos</a>}
                            {/* {user.U_FMB_Handel_Admin === '1' &&
                                <a className="dropdown-item" onClick={() => { configReducer.history.goML(); }}>Admin ML</a>} */}
                            {/* {user.banners === "on" && */}
                            {seller ? seller.U_FMB_Banner === 1 ?
                                <Link to='/adminBanners' className="dropdown-item" >Adm. Banners</Link>
                                : "" : ""
                            }
                            {seller ? seller.U_FMB_Banner === 1 ?
                                <Link to='/adminMarcas' className="dropdown-item" >Adm. Marcas</Link>
                                : "" : ""
                            }
                            {/* seller ? seller.U_FMB_AdmBlogNoticia == 1 ? */}
                            {seller ? seller.U_FMB_AdmBlogNoticia === 1 ?
                                <Link to='/admiNewsBlog' className="dropdown-item" >Adm. Blog de noticias</Link>
                                : "" : ""
                            }
                            {seller ? seller.U_FMB_Handel_Perfil === 5 && seller.U_FMB_Handel_Perfil !== 3 
                                ?
                                <a className="dropdown-item" onClick={() => { configReducer.history.goAutorizaciones(); }}>Autorizaciones</a>
                                : "" : ""
                            }
                            {/* {seller ? seller.U_FMB_Handel_Perfil === 5
                                ? 
                                <a className="dropdown-item" onClick={() => {  this.logOut(); }}>Cerrar Sesión</a>
                                : "" : ""
                            } */}

                            {/* goMyProfile es el perfil de Pete, goProfile es de Missa */}
                            {seller ? seller.U_FMB_Handel_Perfil !== 5 && Object.entries(user).length !== 0 && seller.U_FMB_Handel_Perfil !== 3  ?
                                <a className="dropdown-item" onClick={() => { configReducer.history.goProfile(); }}>Mi Perfil</a>
                                : "" : ""
                            }
                            {/* Información de la cuenta */}
                            {isShowMarcas !== undefined && !isShowMarcas && role !== ROLES.PUBLIC &&
                                <a ></a>
                            }
                            {/* 117 */}
                            {role !== ROLES.PUBLIC ? <a className="dropdown-item" onClick={() => { this.logOut(); }}>Cerrar Sesión</a> : ""}
                            {/* <Link to='/reports' className="dropdown-item"  style={{ color: config.navBar.textColor }}>Mis reportes</Link> */}
                            {user.banners === "on" &&
                                <Link to='/adminBanners' className="dropdown-item" style={{ color: config.navBar.textColor }}>Adm. Banners</Link>
                            }
                            {user.banners === "on" &&
                                <Link to='/adminMarcas' className="dropdown-item" style={{ color: config.navBar.textColor }}>Adm. Marcas</Link>
                            }
                            {user.U_FMB_Handel_Admin === '1' && config.modules.points &&
                                <a className="dropdown-item" onClick={() => { configReducer.history.goResetPoints(); }}>Periodo de puntos</a>}
                            {/* {user.U_FMB_Handel_Admin === '1' &&
                                <a className="dropdown-item m-item" style={{ color: config.navBar.textColor }} onClick={() => { configReducer.history.goSpecialPrices(); }}>Precios especiales</a>} */}
                            {/* <a className="dropdown-item" onClick={() => { configReducer.history.goProfile(); }}>Mi Perfil</a> */}


                            {/* {seller ? seller.U_FMB_Handel_Perfil != 5 && Object.entries(user).length !== 0 ?
                                <a className="dropdown-item" onClick={() => {  this.logOut(); }}>Cerrar Sesión</a>
                                : "" : ""
                            } */}

                        </div>
                    </span>
                    {/* </div> */}
                </li>
            </ul>
        )
    };

    logOut = async () => {
        const {
            configReducer,
            setBusiness,
            setToken,
            setUser,
            setRememberUser,
            setRole,
            sessionReducer: { user },
            itemsReducer: { searchByDashOption }
        } = this.props;
        const { seller } = this.state;
        // ---------------------------------------------------------------------------------------------
        let today = moment().format('YYYY-MM-DD');
        let time = moment().format('h:mm:ss a');
        let data = {};

        if (seller && seller.U_FMB_Handel_Perfil === 2) {
            data = {
                CardCode: seller.salesPrson,
                CardName: seller.lastName + ' ' + seller.firstName,
                Date: today,
                Time: time,
                TypeUser: 'Vendedor',
                Email: seller.email,
                IP: '192.168.0.139',
                Business: 'Standard',
                Session: 0
            }
        } else if (seller && seller.U_FMB_Handel_Perfil === 0) {
            data = {
                CardCode: user.CardCode,
                CardName: user.CardName,
                Date: today,
                Time: time,
                TypeUser: 'Cliente',
                Email: user.U_FMB_Handel_Email,
                Business: 'Standard',
                Session: 0
            }
        }

        localStorage.removeItem(config.general.localStorageNamed + 'Role');
        localStorage.removeItem(config.general.localStorageNamed + 'Token');
        localStorage.removeItem(config.general.localStorageNamed + 'CurrentUser');
        localStorage.removeItem(config.general.localStorageNamed + 'PartnerUser');
        localStorage.removeItem(config.general.localStorageNamed + 'RememberUser');
        localStorage.removeItem(config.general.localStorageNamed + 'vendor');
        localStorage.clear();

        await searchByDashOption();

        setRole(ROLES.PUBLIC);
        setBusiness(config.general.business);
        setToken(undefined);
        setUser({});
        setRememberUser(false);

        configReducer.history.goHome();
        window.location.reload(true)
    };

    renderPublicOptionFirstNavBar = () => {
        const { configReducer: { history } } = this.props;
        return (
            <ul className="navbar-nav d-md-flex">
                <li className="nav-item">
                    <a className="" style={{ color: config.navBar.textColor }}>
                        <h5 className="font-weight-bold mr-sm-2 mr-md-0 mr-lg-4 mr-xl-4 miCuenta" style={{ color: '#000000', fontSize: '16px', display: 'flex', alignItems: 'center' }} onClick={() => { history.goLogin(); }}>
                            <img src={config.navBar.avatar} alt="avatar" style={{ width: "35px", marginRight: '14px', marginLeft: '12px' }} />
                            <span className='just-d'>Inicio de sesión</span>
                        </h5>
                    </a>
                </li>
            </ul>
        );
    };

    ChangeEnableSetZipCodeModal = async () => {
        await this.setState({
            enableSetZipCodeModal: !this.state.enableSetZipCodeModal
        })
    }

    LaunchSelectZipCode = async () => {
        const {setModalEnable} = this.props
        setModalEnable(false)
        await this.ChangeEnableSetZipCodeModal();
        $('#SetZiCodeModalWhs').modal('show');
        setTimeout(() => {
           this.setState({
            isLoaded: true
           })
          }, 500);
    }

    ChangeUserZipCode = async (value) => {
        // if(isNaN(value))return
        await this.setState({
            zipCodeUser: value
        })
    }

    CloseActionsModal = async ()=>{
        const {setModalEnable} = this.props
        setModalEnable(true)
        if(this.state.isLoaded){
            $('#SetZiCodeModalWhs').modal('hide');
            await this.ChangeEnableSetZipCodeModal();
            this.setState({
                isLoaded:false
            })
        }
    }

    renderSetZipCode = () => {
        return (
            <div onClick={() => this.LaunchSelectZipCode()} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
                <div className='imgAvatar p-2'>
                    <img src={config.navBar.ubicacion} alt='Ubicación' width="16" height="16" />
                </div>
            </div>
        )
    }

    renderZipCodeAndWhs = () => {
        const { currentUser } = this.state;
        const { sessionReducer:{ modalZipCodeEnable }} = this.props
        let sucursal = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'WHSCLIENT'))
        return (
            <div className='miCuenta d-md-flex ml-sm-4 ml-md-0 ml-lg-4 ml-xl-4 mr-sm-4 mr-md-0 mr-lg-4 mr-xl-4 mx-4' style={{ maxWidth: 200, top: '0px', cursor: 'pointer' }} onClick={() => modalZipCodeEnable && this.LaunchSelectZipCode()}>
                <div className="icon-ubi" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span className="imgAvatar" title="Elige tu ubicación">
                        <img src={config.navBar.ubicacion} alt='Ubicación' />
                    </span>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <span className="ml-2 mt-2 info-span" style={{ color: config.navBar.textColor, font: '-webkit-control', fontSize: '9px' }}>
                            {currentUser?.ZipCode === '' ? 'Posición actual' : currentUser?.ZipCode ?? ''}
                        </span>
                        {sucursal && sucursal !== '' ?
                            <span className="ml-2 mt-2 info-span" style={{ color: config.navBar.textColor, font: '-webkit-control', fontSize: '9px', fontWeight: 'bolder' }}>
                                Sucursal:{sucursal}
                            </span> : <></>}
                    </div>
                </div>
            </div>
        );
    }

    GetWhsByHandelUser = async (ZipCode = '', CurrentPosition = false, indexAddress = null) => {
        const { notificationReducer: { showAlert }, enableSpinner } = this.props
        const { locations } = this.state
        const data = {
            ZipCode: CurrentPosition === false ? locations[indexAddress] : ZipCode,
            CurrentPosition
        }
        enableSpinner(true);
        const responseApi = await apiClient.GetWhsByHandelUser(data);
        enableSpinner(false);

        if (responseApi.status !== 1) {
            showAlert({ type: 'warning', message: responseApi.message });
            return;
        }

        if (Array.isArray(responseApi.data) && responseApi.data.length > 0) {
            const ZipCodeForUser = CurrentPosition === false ? locations[indexAddress].Address : ZipCode;
            await this.ChangeToNearWhs(responseApi.data[0].whsCode, responseApi.data[0].whsName, ZipCodeForUser,responseApi.data[0].sucursalCode);
            $('#SetZiCodeModalWhs').modal('hide');
        }
    }

    GetLocationsByAddress = async (TextAddress) => {
        const { notificationReducer: { showAlert }, enableSpinner } = this.props
        const data = { TextAddress };

        enableSpinner(true);
        //Validación de Código postal
        let infoCP = {
            CP: data?.TextAddress || '',
            isModal: true
        };
        const responseCP = await apiClient.validateCP(infoCP);
        if (responseCP.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'warning', message: responseCP.message });
            enableSpinner(false);
            return;
        }
        //Fin de la validación
        const responseApi = await apiClient.GetLocationsByAddress(data);
        enableSpinner(false);

        if (responseApi.status !== 1) {
            showAlert({ type: 'warning', message: responseApi.message });
            return;
        }

        if (responseApi.data && Array.isArray(responseApi.data) && responseApi.data.length > 0) {
            showAlert({ type: 'success', message: 'Favor de seleccionar su dirección obtenida o la mas cercana a su dirección para determinar el stock disponible', timeOut: 8000 });
            let auxArray = [];
            for (let x = 0; x < responseApi.data.length; x++) {
                const element = responseApi.data[x];
                auxArray.push(element)
            }

            this.setState({
                locations: auxArray ?? []
            })
        }
    }

    //#region ########## REGION Cambiar almacen de manera general ##########
    ChangeToNearWhs = async (whsGeneralCode, whsGeneralName, ZipCode,sucursalCode) => {
        const { setModalEnable, enableSpinner, setWhsGeneral, setWhsGeneralName, notificationReducer: { showAlert }, itemsReducer: { searchByDashOption }, configReducer: { history }, setToken, setUserSession, setRememberUser } = this.props;
        const { empID, currentUser } = this.state
        // if (items.length > 0) {
        //     showAlert({ type: 'warning', message: "Para cambiar de almacén no debe tener articulos en el carrito de compras." });
        // } else {
        //Validación de Código postal
        let infoCP = {
            CP: ZipCode ? ZipCode : currentUser?.ZipCode || '',
            isModal: true
        };
        if (infoCP.CP) {
            let postalCode = infoCP.CP.match(/^\d{5}/);
            if (postalCode) {
                infoCP.CP = postalCode[0];
            }
        }
        const responseCP = await apiClient.validateCP(infoCP);
        if (responseCP.status === SERVICE_RESPONSE.ERROR) {
            showAlert({ type: 'warning', message: responseCP.message });
            return;
        }
        //Fin de la validación
        setWhsGeneral(whsGeneralCode);
        setWhsGeneralName(whsGeneralName);
        await apiClient.UpdateWOHSBack(whsGeneralCode,sucursalCode);
        const user = {
            email: currentUser? currentUser.U_FMB_Handel_Email : '',
            password: currentUser ? currentUser.U_Handel_Password : '',
            salesPerson: empID ? empID.salesPrson : '',
            whsGeneralCode,
            whsGeneralName,
            ZipCode: ZipCode ? ZipCode : currentUser.ZipCode,
            sucursalCode,
        };
        const response = await apiClient.login(user);
        if (response.status === 1) {
            response.data.user.whsGeneralName = whsGeneralName;
            response.data.user.ZipCode = ZipCode ? ZipCode : currentUser.ZipCode;
            let user = response.data.user;
            let token = response.data.token;
            let remember = true;
            localStorage.setItem(config.general.localStorageNamed + 'Token', JSON.stringify(token));
            localStorage.setItem(config.general.localStorageNamed + 'CurrentUser', JSON.stringify(user));
            localStorage.setItem(config.general.localStorageNamed + 'WHSCLIENT', JSON.stringify(whsGeneralName));

            setToken(token);
            setUserSession(user);
            setRememberUser(remember);

            await this.setState({
                currentUser: user
            })

            setTimeout(() => {
                enableSpinner(true);
                showAlert({ type: 'info', message: 'ESTAMOS CONSULTANDO EL STOCK DE LOS ARTÍCULOS AL ALMACEN MAS CERCANO A TU POSICIÓN' });
                searchByDashOption();
                enableSpinner(false);

                setTimeout(() => {
                    showAlert({ type: 'success', message: 'ARTÍCULOS ACTUALIZADOS' });
                    history.goItems();  // redirecciona a Items e inmediatamente al home al cambiar de almacen (Solucion practica para cuando estas en home)
                    history.goHome();   // redirecciona al home al cambiar de almacen
                    setModalEnable(true)
                }, 3000);

            }, 100);
            this.forceUpdate()

        }
        // }
        //Publico
        localStorage.setItem(config.general.localStorageNamed + 'WHSCLIENT', JSON.stringify(whsGeneralName));
        let publicUser = {
            whsGeneralCode,
            whsGeneralName,
            ZipCode: ZipCode ? ZipCode : currentUser.ZipCode,
            sucursalCode,
            wareHouse: 'Public',
        }
        localStorage.setItem(config.general.localStorageNamed + 'CurrentUser', JSON.stringify(publicUser));

        await this.setState({
            currentUser: publicUser
        })

        setTimeout(() => {
            enableSpinner(true);
            showAlert({ type: 'info', message: 'ESTAMOS CONSULTANDO EL STOCK DE LOS ARTÍCULOS AL ALMACEN MAS CERCANO A TU POSICIÓN' });
            searchByDashOption();
            enableSpinner(false);

            setTimeout(() => {
                showAlert({ type: 'success', message: 'ARTÍCULOS ACTUALIZADOS' });
                history.goItems();  // redirecciona a Items e inmediatamente al home al cambiar de almacen (Solucion practica para cuando estas en home)
                history.goHome();   // redirecciona al home al cambiar de almacen
                setModalEnable(true)
            }, 3000);

        }, 100);
        this.forceUpdate();
    };

    ChangeLocationApi = async (indexAddres) => {
        await this.setState({
            SelectedLocation: indexAddres
        })
        await this.GetWhsByHandelUser('', false, indexAddres)
    };

    warehouseModal = () => {
        setTimeout(() => {
            $('#warehouseModal').modal('show');
        }, 200);
    };

    renderFirstNavBar = () => {
        const { seller, currentUser } = this.state;
        const { sessionReducer } = this.props;
        return (
            <div className='top-nav'>
                <MenuCategories />
                <div className="row p-2 main-top-bar contact-navbar" style={{ fontSize: '9pt', backgroundColor: config.navBar.primaryColor }}>
                    <div className="col-auto uno">
                        <a>
                            <span style={{ color: "white", justifyContent: 'center' }}>Tienes dudas, estamos a tu servicio</span>
                        </a>
                    </div>
                    <div className="col-auto dos">
                        <a href="https://api.whatsapp.com/send?phone=526671180507" target="_blank">
                            <img alt='' className="img-fluid" width='19px' src={config.BarraTelefono.Whats} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                            <span style={{ color: "white" }}>52 667 118 0507</span>
                        </a>
                    </div>
                    <div className="col-auto tres">
                        <a>
                            <img alt='' className="img-fluid" width='19px' src={config.BarraTelefono.IconCel} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                            <span style={{ color: "white" }}> 667 118 0507</span>
                        </a>
                    </div>
                </div>
                <div className='navbar-container'>
                    <nav id="navFirst" className="navbar navbar-expand-sm primerNav" style={{ padding: 0, background: config.navBar.backgroundColor }}>
                        <ul className="navbar-nav elements main-nav mx-auto align-items-center">
                            <li className="nav-link menu-categories-button logo-nav" style={{ color: config.navBar.textColor, cursor: "pointer" }}>
                                <img alt='' id="buttonMenu" src={config.navBar.menu_hamburguesa} className="img-fluid just-d" style={{ color: config.navBar.iconColor }} />
                                <div className="navbar-brand">
                                    <NavBarLogo />
                                </div>
                            </li>
                            <li className="nav-item searchItems d-md-block d-none just-d bar-container" style={{ textAlign: "center", paddingTop: 10 }}>
                                <NavBarContentSearchItems className="nav justify-content-center just-d" icon={config.icons.search} iconColor={config.navBar.iconColor} textColor={config.navBar.backgroundColor} />
                            </li>
                            {currentUser !== null && // solo para cuando esté logeado
                                <>
                                    {currentUser.wareHouse && currentUser.wareHouse !== null ?
                                        <>
                                            {/* aquí mostrará el almacén y el código postal */}
                                            {this.renderZipCodeAndWhs()}
                                        </>
                                        :
                                        <>
                                            {this.renderSetZipCode()}
                                        </>
                                    }
                                </>
                            }
                            <li className="shopp nav-item mr-sm-auto d-md-flex row nav-icons" id="navbarFirst">
                                <div className="d-flex justify-content-center align-items-center"> {/* Contenedor para centrar horizontal y verticalmente */}
                                    <span>
                                        <span>{sessionReducer.role === ROLES.PUBLIC ? this.renderPublicOptionFirstNavBar() : this.renderClientOptionFirstNavBar()}</span>
                                    </span>
                                    <span className="ml-2" style={{ alignSelf: "center" }}>
                                        <NavBarShoppingCartIcon
                                            icon={config.icons.shoppingCart}
                                            iconColor={config.navBar.iconColor2}
                                            textColor={config.navBar.textColor}
                                        />
                                    </span>
                                    {seller ? seller.U_FMB_Handel_Perfil !== '0' && seller.U_FMB_Handel_Perfil !== 5 ?
                                        <span className="ml-2" style={{ alignSelf: "center" }}>
                                            <Quote
                                                icon={config.icons.shoppingCart}
                                                iconColor={config.navBar.iconColor2}
                                                textColor={config.navBar.textColor}
                                            />
                                        </span>
                                        : "" : ""
                                    }
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/* <div className="fixed-top navbar navbar-expand-sm navbar-dark" style={{ border: 'none', padding: 0, height: 45, background: config.navBar.backgroundColor, marginTop: 30 }}>
                    <ul className="navbar-nav " style={{ paddingLeft: 16, paddingBottom: 16 }}>
                        <li className="nav-link menu-categories-button" style={{ color: config.navBar.textColor, cursor: "pointer" }}>
                            <i className={config.icons.menu} style={{ color: config.navBar.iconColor, fontsize: 15 }} />
                        </li>
                    </ul>
                    <NavBarLogo />
                    <ul className="navbar-nav  col-md-4 d-sm-block d-none  " style={{ paddingBottom: 5, marginLeft: 230, textAlign: "center" }}>
                        <li>
                            <NavBarContentSearchItems className="nav justify-content-center" icon={config.icons.search} iconColor={config.navBar.iconColor} textColor={config.navBar.backgroundColor} />
                        </li>
                    </ul>
                    <div className="row" >
                        <div className="col-md-12">
                            <button type="button"
                                className="btn btn-primary btn-sm btn-block"
                                style={{ fontsize: 25, background: config.navBar.iconColor, color: "white" }}
                                onClick={() => {}}>
                                Busqueda avanzada
                            </button>
                        </div>
                    </div>
                    <div className="navbar-nav ml-sm-auto d-md-flex" id="navbarFirst">
                        <span className="mr-2 p-1">
                            <span>{sessionReducer.role === ROLES.PUBLIC ? this.renderPublicOptionFirstNavBar() : this.renderClientOptionFirstNavBar()}</span>
                        </span>
                        <span className="p-1 mr-3">
                            <NavbarSearchIcon
                                icon={config.icons.search}
                                iconColor={config.navBar.iconColor}
                                textColor={config.navBar.textColor}
                            />&nbsp;
                        <NavBarShoppingCartIcon
                                icon={config.icons.shoppingCart}
                                iconColor={config.navBar.iconColor}
                                textColor={config.navBar.textColor}
                            />
                        </span>
                    </div>
                </div> */}
            </div >
        );
    };

    renderPublicOptionMenu = () => {
        const { configReducer: { history } } = this.props;
        return (
            <ul className="navbar-nav mr-auto" >
                <li className="nav-item" >
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <h5 className="font-weigth-bold" style={{ color: config.navBar.textColor2, width: "auto" }} onClick={() => { history.goLogin(); }}><i className="fas fa-user-circle" /> Inicio de sesión </h5>
                    </a>
                </li>
            </ul>
        )
    };

    renderClientOptionMenu = () => {
        const { configReducer, sessionReducer: { user }, isShowMarcas } = this.props;
        const { seller } = this.state;
        return (
            <ul className="navbar-nav mr-auto">
                {/* <li className="nav-item">
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <i className={config.icons.user} style={{ color: config.navBar.iconColor }} aria-hidden="true" />
                        <strong><span style={{color: config.navBar.textColor2}}>{user.CardName}</span></strong>
                    </a>
                </li> */}
                <li className="nav-item" onClick={() => { configReducer.history.goProfile(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <strong><span style={{ color: config.navBar.textColor2 }}>{user.CardName}</span></strong>
                    </a>
                </li>
                {/* Información de la cuenta */}
                {!isShowMarcas &&
                    <li className="nav-item" onClick={() => this.openOrder()}>
                        <a className="nav-link" style={{ color: config.navBar.textColor2 }}>
                            <></>
                        </a>
                    </li>
                }
                {/* <li className="nav-item" onClick={() => { configReducer.history.goProfile(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span style={{color: config.navBar.textColor2}}>Nivel: {this.nivel(user.listNum)}</span>
                    </a>
                </li> */}
                {
                    seller && seller.U_FMB_Handel_Perfil !== 3 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goProfile(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span style={{ color: config.navBar.textColor2 }}>Mi Perfil</span>
                    </a>
                </li>:<></> 
                }
                
                <li className="nav-item" onClick={() => { this.logOut(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span style={{ color: config.navBar.textColor2 }}>Cerrar Sesión</span>
                    </a>
                </li>
                {seller ? seller.U_FMB_Handel_Perfil !== '0' && seller.U_FMB_Handel_Perfil !== 5 && seller.U_FMB_Handel_Perfil !== 3 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goSelectClient(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor2 }}>Mis clientes</span>
                        </a>
                    </li>
                    : "" : ""
                }
                {seller ? seller.U_FMB_Handel_Boletin === 1 && seller.U_FMB_Handel_Perfil !== 3 &&
                    <li className="nav-item" onClick={() => { configReducer.history.goBoletin(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor2 }}>Ver correos del boletín</span>
                        </a>
                    </li>
                    : ""
                }

                {seller ?  (seller.U_FMB_Handel_Perfil === 5 && user.U_FMB_AutorizarBoni === 1) || (seller.U_FMB_Bonificaciones === 1 && seller.U_FMB_Handel_Perfil !== 3 ) ?
                    <li className="nav-item" onClick={() => { configReducer.history.goPromocionales(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor2 }}>Bonificaciones</span>
                        </a>
                    </li>
                    : "" : ""
                }

                {config.modules.subirArchivoExcel && seller ? seller.U_FMB_Handel_Perfil !== 5 && Object.entries(user).length !== 0 && seller.U_FMB_Handel_Perfil !== 3 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goSubirArchivo(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor2 }}>Subir archivo OC</span>
                        </a>
                    </li>
                    : "" : ""
                }

                {seller ? seller.U_FMB_Handel_Perfil !== 5 && Object.entries(user).length !== 0 && seller.U_FMB_Handel_Perfil !== 3 ?
                    <li className="nav-item" onClick={() => { this.goPedidos() }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor2 }}>Mis reportes</span>
                        </a>
                    </li>
                    : "" : ""
                }
                {seller ? seller.U_FMB_Handel_Perfil !== 5 && Object.entries(user).length !== 0 && seller.U_FMB_Handel_Perfil !== 3 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goCardSaveds(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span style={{ color: config.navBar.textColor2 }}>Carritos guardados</span>
                        </a>
                    </li>
                    : "" : ""
                }
                {/* {user.banners === "on" && */}
                {seller ? seller.U_FMB_Banner === 1 ?
                    <li className="nav-item">
                        <a className="nav-link" style={{ color: config.navBar.textColor2 }}>
                            <span>
                                <Link to='/adminBanners' className="dropdown-item" style={{ color: config.navBar.textColor2 }} >Adm. Banners</Link>
                            </span>
                        </a>
                    </li>
                    : "" : ""
                }
                {seller ? seller.U_FMB_Banner === 1 ?
                    <li className="nav-item">
                        <a className="nav-link" style={{ color: config.navBar.textColor2 }}>
                            <span>
                                <Link to='/adminMarcas' className="dropdown-item" style={{ color: config.navBar.textColor2 }} >Adm. Marcas</Link>
                            </span>
                        </a>
                    </li>
                    : "" : ""
                }
                {/* seller ? seller.U_FMB_AdmBlogNoticia == 1 ? */}
                {seller ? seller.U_FMB_AdmBlogNoticia === 1 ?
                    <li className="nav-item">
                        <a className="nav-link" style={{ color: config.navBar.textColor2 }}>
                            <span>
                                <Link to='/admiNewsBlog' className="dropdown-item" style={{ color: config.navBar.textColor2 }} >Adm. Blog de noticias</Link>
                            </span>
                        </a>
                    </li>
                    : "" : ""
                }

                {seller ? seller.U_FMB_Handel_Perfil === 5 && seller.U_FMB_Handel_Perfil !== 3
                    ?
                    <li className="nav-item" onClick={() => { configReducer.history.goAutorizaciones(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor2 }}>
                            <span style={{ color: config.navBar.textColor2 }}>Autorizaciones</span>

                        </a>
                    </li>
                    : "" : ""
                }

                {/* {seller ? seller.U_FMB_Handel_Perfil != 5 ?
                    <li className="nav-item" onClick={() => { configReducer.history.goProfile(); }}>
                        <a className="nav-link" style={{ color: config.navBar.textColor }}>
                            <span  style={{color: config.navBar.textColor2}}>Mi Perfil</span>
                        </a>
                    </li>
                    : "" : ""
                } */}
            </ul>
        )
    };

    renderSecondNavBar = () => {
        const { configReducer: { history } } = this.props;

        return (
            // <div style={{ width: "100%", zIndex: 90, background: "transparent"}}>
            //     <div className="d-block d-lg-none" style={{ width: "100%", height: 30, background: config.navBar.backgroundColor }}>
            //         <nav className="navbar navbar-expand-lg navbar-two" style={{ background: config.navBar.iconColor }}>
            //             <NavBarButton iconColor={config.navBar.iconColor2} icon={config.icons.user} ruta={this.iconUser(user.listNum)} style={{ fontsize: 25}} />  
            //             <div className="collapse navbar-collapse" id="navbarSupportedContent1">
            //                 {sessionReducer.role === ROLES.PUBLIC ? this.renderPublicOptionMenu() : this.renderClientOptionMenu()}
            //                 <ul className="navbar-nav mr-auto">
            //                     <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={() => this.handleRedPolarZone()}>AUSTROMEX </li>
            //                     <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={() => this.handleOutletZone()}>MAKITA</li>
            //                     <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={() => this.handleWhiteZone()}>FANDELI</li>
            //                     <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={() => this.handleMarvaFour()}>BYP</li>
            //                     <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={() => this.handleMarcaFive()}>HENKEL</li>
            //                     <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={() => this.openOrder()}>Información de su cuenta</li>


            //                     {config.modules.redZone && 
            //                         <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={() => history.goRedZone()}>Zona de Recompensas</li>
            //                     }
            //                     {config.modules.redAliado && 
            //                         <li className="nav-link nav-item text-white" style={{ cursor: "pointer", color: config.navBar.iconColor2}} onClick={() => history.goRedAliado()}>Red Aliado</li>
            //                     }
            //                     {config.aboutUs.active &&
            //                         <li className="nav-link nav-item text-white" style={{ cursor: "pointer" }} onClick={() => history.goAboutUs()}>
            //                             <span> ¿Quiénes somos?</span>

            //                         </li>}
            //                     {config.contactUs.active &&
            //                         <li className="nav-link nav-item text-white" style={{  cursor: "pointer" }} onClick={() => history.goContactUs()}>
            //                             <span> Contáctanos </span>
            //                         </li>}
            //                     {config.questions.active &&
            //                         <li className="nav-link" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => history.goQuestions()}>
            //                             <span> Preguntas frecuentes </span>
            //                         </li>}
            //                     {config.claim.active &&
            //                         <li className="nav-link" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => history.goClaim()}>
            //                             <span>Contáctanos</span>
            //                         </li>}
            //                     {config.termPage.active &&
            //                         <li className="nav-link" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => history.goPolitics()}>
            //                             <span> Términos de uso </span>
            //                         </li>}

            //                         {/* <li className="nav-link nav-item text-white" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => { this.logOut(); }}>
            //                             <span>Cerrar Sesión</span>
            //                         </li> */}
            //                 </ul>
            //             </div>
            //         </nav>
            //     </div>
            //     {isShowMarcas == undefined  &&
            //     <div className="d-12 d-none d-lg-flex" style={{ width: "100%", background: config.navBar.backgroundColor, height:50 }}>
            //         <nav className="navbar navbar-expand-md navbar-light navbar-two" style={{
            //             width: "100%",
            //             backgroundColor: "#fff" 
            //         }}>
            //             <div className="d-none d-lg-block col-md-12">
            //                 <ul className="navbar-nav mr-auto justify-content-center menuImages">
            //                     <li className="nav-link text-center col-2 imgCaterogies" style={{ color: config.navBar.textColor, cursor: "pointer"}} onClick={() => this.handleRedPolarZone()}>
            //                         <img src={config.marcasScroll.Seventeen} height='80px' className="Img-fluid"></img>
            //                     </li>
            //                     <li className="nav-link text-center col-2 imgCaterogies" style={{ color: config.navBar.textColor, cursor: "pointer"}} onClick={() => this.handleWhiteZone()}>
            //                         <img src={config.marcasScroll.Fourty_Two} height='80px' className="Img-fluid " ></img>
            //                     </li>
            //                     <li className="nav-link text-center col-2 imgCaterogies" style={{ color: config.navBar.textColor, cursor: "pointer"}} onClick={() => this.handleOutletZone()}>
            //                         <img src={config.marcasScroll.Seven} height='80px' className="Img-fluid" ></img>
            //                     </li>
            //                     <li className="nav-link text-center col-2 imgCaterogies" style={{ color: config.navBar.textColor, cursor: "pointer"}} onClick={() => this.handleMarvaFour()}>
            //                         <img src={config.marcasScroll.Ten} height='80px' className="Img-fluid " ></img>
            //                     </li>
            //                     <li className="nav-link text-center col-2 imgCaterogies" style={{ color: config.navBar.textColor, cursor: "pointer"}} onClick={() => this.handleMarcaFive()}>
            //                         <img src={config.marcasScroll.Thirty_Eight} height='80px' className="Img-fluid" ></img>
            //                     </li>
            //                     {/* Información de la cuenta */}
            //                     {sessionReducer.role != ROLES.PUBLIC && <li className="nav-link text-center col-2 imgCaterogies mb-3" style={{ color: config.navBar.textColor, cursor: "pointer", marginBottom: "11px !important"}} onClick={() => this.openOrder()}>
            //                         <img src={config.NavarIconos.iconFour} height='60px' className="Img-fluid" style={{padding:10}} ></img>
            //                     </li>}
            //                     {config.modules.redZone && 
            //                         <li className="nav-link text-center col-2 imgCaterogies" style={{ color: config.navBar.textColor, cursor: "pointer"}} onClick={() => history.goRedZone()}>
            //                             <img src={config.marcasScroll.Eight} className="Img-fluid" ></img>
            //                         </li>
            //                     }
            //                     {config.modules.redAliado &&
            //                         <li className="nav-link text-center col-2 imgCaterogies" style={{ color: config.navBar.textColor, cursor: "pointer"}} onClick={() => history.goRedAliado()}>
            //                             <img src={config.marcasScroll.Nine} className="Img-fluid" ></img>
            //                         </li>
            //                     }
            //                 </ul>
            //             </div>
            //         </nav>
            //     </div>
            //     }
            // </div>
            <div className='blue-navbar' style={{ background: config.navBar.primaryColor }}>
                <div className='just-m secundary-bar'>
                    <div className="nav-link menu-categories-button logo-menu-resp">
                        <img alt='' id="buttonMenu" src={config.navBar.whiteMenu} className="Img-fluid" style={{ color: config.navBar.iconColor }} />
                    </div>
                    {/* <img src={config.navBar.secundaryNavbar} /> */}
                    <NavBarContentSearchItems className="nav justify-content-center" icon={config.icons.search} iconColor={config.navBar.iconColor} textColor={config.navBar.backgroundColor} />
                    <div className='menu-search-navbar justify-content-end align-items-center' style={{ width: '30px' }} data-target='#offcanvasBlack' onClick={''} data-toggle='modal'>
                        <img alt='' src={config.navBar.secundaryNavbar} style={{ maxWidth: "25px", cursor: "pointer" }} />
                    </div>
                </div>
                <div className='blue-navbar just-d' style={{ width: '100%' }}>
                    {/* <span className='nav-text' onClick={() => { this.openCotizar() }} style={{ cursor: 'pointer' }}>COTIZAR</span> */}
                    <span className='nav-text' onClick={() => { this.handleViews("promocion") }} style={{ cursor: 'pointer' }}>PROMOCIONES</span>
                    <span className='nav-text' onClick={() => history.goSucursales()} style={{ cursor: 'pointer' }}>SUCURSALES</span>
                    <span className='nav-text' onClick={() => { this.state.user ? this.goFacturas() : history.goLogin() }} style={{ cursor: 'pointer' }}>FACTURACIÓN</span>
                    <span className='nav-text' style={{ cursor: 'pointer', color: 'white' }} onClick={() => window.open(`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.catalogo}`, '_blank')}>
                    <img src={config.navBar.catalogo} alt="Descripción de la imagen" style={{ marginLeft: '5px', width: '20px', height: '20px', marginTop: '-6px' }} />CATÁLOGO</span>
                    {/* <span className='nav-text' onClick={() => history.goAboutUs()} style={{ cursor: 'pointer' }}>¿QUIENES SOMOS?</span> */}
                    {/* <span className='nav-text' onClick={() => history.goJob()} style={{cursor:'pointer'}}>BOLSA DE TRABAJO</span> */}
                </div>
            </div>
        );
    };
    openCotizar = async () => {
        const { enableSpinner } = this.props;
        enableSpinner(true);

        // if (user !== null) {
        $('#pricemodel').modal('show');
        // } else {
        // showAlert({ type: 'warning', message: "No hay un cliente seleccionado" })
        // }

        enableSpinner(false);
        return;
    };
    openOrder = async () => {
        const { enableSpinner, notificationReducer: { showAlert } } = this.props;
        const { user } = this.state
        enableSpinner(true);

        if (user !== null) {
            $('#dataProfiel').modal('show');
        } else {
            showAlert({ type: 'warning', message: "No hay un cliente seleccionado" })
        }

        enableSpinner(false);
        return;
    };


    handleOutletZone = async () => {
        const { itemsReducer, setIdCategory, setLocation } = this.props;
        setIdCategory(null);
        setLocation('marcaThree');
        await itemsReducer.searchByKey(0, 'marcaThree', true);
    }

    handleWhiteZone = async () => {
        const { itemsReducer, setIdCategory, setLocation } = this.props;
        setIdCategory(null);
        setLocation('marcaTwo');
        await itemsReducer.searchByKey(0, 'marcaTwo', true);
    }

    handleRedPolarZone = async () => {
        const { itemsReducer, setIdCategory, setLocation } = this.props;
        setIdCategory(null);
        setLocation('marcaOne');
        await itemsReducer.searchByKey(0, 'marcaOne', true);
    }


    handleMarvaFour = async () => {
        const { itemsReducer, setIdCategory, setLocation } = this.props;
        setIdCategory(null);
        setLocation('marcaFour');
        await itemsReducer.searchByKey(0, 'marcaFour', true);
    }

    handleMarcaFive = async () => {
        const { itemsReducer, setIdCategory, setLocation } = this.props;
        setIdCategory(null);
        setLocation('marcaFive');
        await itemsReducer.searchByKey(0, 'marcaFive', true);
    }

    handleViews = async (opcion, page = 0) => {
        const { itemsReducer, setIdCategory, setLocation } = this.props;
        setIdCategory(null);
        setLocation(opcion);
        await itemsReducer.searchByKey(0, opcion, true);
    }

    onChangeRequestQuotation = (event, field) => {
        this.setState({
            requestQuotation: {
                [field]: event.target.value
            }
        })
    }

    onChangePriceForm = (event, field) => {
        let obj = this.state.dataForm
        obj[field] = event.target.value

        this.setState({
            dataForm: obj
        })
    }

    goFacturas = () => {
        const { configReducer: { history } } = this.props
        history.goReports();
        setTimeout(() => {
            const facturacionTab = document.getElementById('factura-tab');
            if (facturacionTab) {
                facturacionTab.click();
            }
        }, 100);
    }

    goPedidos = () => {
        const { configReducer: { history } } = this.props
        history.goReports();
        /*setTimeout(() => {
            const facturacionTab = document.getElementById('pedido-tab');
            if (facturacionTab) {
                facturacionTab.click();
            }
        }, 100);*/
    }

    render() {
        const { configReducer: { history }, sessionReducer} = this.props
        const { zipCodeUser, selectedLocation, locations ,enableSetZipCodeModal} = this.state
        return (
            <div>
                <WarehouseModal />
                <LoginRegisterModal />
                <ProfieldModel />
                <div className="fixed-top navbar-main" style={{ background: config.navBar.backgroundColor, zIndex: 1020 }}>
                    {/* <div  style={{background:"#1b1c1d", height:"80px", verticalAlign:"middle"}}>
                        <div className="row">
                            <div className='col-3 text-center'>
                                <img src={require('../images/standard/footer/logofmbColor.svg')} className="img-fluid mt-2"  width='150px' style={{ maxWidth:'80%'}} />
                            </div>
                            <div className='col-6'>
                                <table>
                                <tr style={{height:"80px", verticalAlign:"middle", color: "white"}}>
                                        <td className='text-center'>
                                            <h4>Esta es una version de pruebas -- Ninguna orden o pedido sera surtido ni reembolsado.</h4>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className='col-3'></div>
                        </div>
                    </div> */}
                    {this.renderFirstNavBar()}
                    {this.renderSecondNavBar()}
                </div>
                <QuotationModal
                    requestQuotation={this.state.requestQuotation}
                    onChangeRequestQuotation={this.onChangeRequestQuotation}
                />
                <PriceModel
                    dataForm={this.state.dataForm}
                    onChangePriceForm={this.onChangePriceForm} />
                <div class={"modal top fade"} id={"offcanvasBlack"} tabindex="-1" role="dialog" aria-labelledby="offcanvas" data-backdrop={"false"}>
                    <div class="modal-dialog black-bar-responsive" role="document" style={{ position: "fixed", margin: "auto", maxWidth: "", height: "100%", right: 0, marginRight: "0px", boxShadow: "0 3px 6px 1px var(--ck-color-shadow-drop-active)", marginTop: "130px", width: "100%" }}>
                        <div class="modal-content" style={{ height: "100%", overflowY: "auto", paddingRight: "15px", borderRadius: 0, }}>
                            <div class="modal-header black-bar-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div class="black-bar-responsive-list">
                                <span data-dismiss="modal" onClick={() => { this.handleViews("promocion") }} style={{ cursor: 'pointer' }}>PROMOCIONES</span>
                                <span data-dismiss="modal" onClick={() => history.goSucursales()} style={{ cursor: 'pointer' }}>SUCURSALES</span>
                                <span data-dismiss="modal" onClick={() => { this.state.user ? this.goFacturas() : history.goLogin() }} style={{ cursor: 'pointer' }}>FACTURACIÓN</span>
                                <span className='nav-text' style={{ cursor: 'pointer' }} onClick={() => window.open(`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.catalogo}`, '_blank')}>
                                CATÁLOGO
                                </span>
                                {!this.state.user && (
                                    <span data-dismiss="modal" onClick={() => history.goLogin()} style={{ cursor: 'pointer' }}>INICIO DE SESIÓN</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {enableSetZipCodeModal &&
                    <SetZipCodeNavBarModal
                        ZipCode={zipCodeUser}
                        SelectedLocation={selectedLocation}
                        Locations={locations}
                        ChangeLocationApi={this.ChangeLocationApi}
                        ChangeZipCode={this.ChangeUserZipCode}
                        GetWhsByHandelUser={this.GetWhsByHandelUser}
                        GetLocationsByAddress={this.GetLocationsByAddress}
                        CloseActionsModal = {this.CloseActionsModal}
                        sessionReducer = {sessionReducer}
                    />
                }
            </div>

        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setWhsGeneral: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_WHSGENERAL, value }),
        setWhsGeneralName: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_WHSGENERAL_NAME, value }),
        setRole: value => dispatch({ type: DISPATCH_ID.SESSION_SET_ROLE, value }),
        setBusiness: value => dispatch({ type: DISPATCH_ID.SESSION_SET_BUSINESS_ID, value }),
        setToken: value => dispatch({ type: DISPATCH_ID.SESSION_SET_TOKEN, value }),
        setUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_USER, value }),
        setRememberUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_REMEMBER_USER, value }),
        setIdCategory: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value }),
        setLocation: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_LOCATION, value }),
        setUserSession: value => dispatch({ type: DISPATCH_ID.SESSION_SET_USER, value }),
        setModalEnable: value => dispatch({ type: DISPATCH_ID.SESSION_SET_MODAL_ENABLED, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBar);
