import React, { Component } from 'react';
import { config, SERVICE_API, DISPATCH_ID, SERVICE_RESPONSE} from "../libs/utils/Const";
import { ApiClient } from "../libs/apiClient/ApiClient";
import { ProgressBar } from "./index";
import $ from 'jquery';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import Swal from 'sweetalert2';
let apiClient = ApiClient.getInstance();

class DeliveryDetailModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shoppingCartItems: [], // Inicialmente vacío
        };
    }

    //Obtener los articulos del carrito
    componentDidMount = async () => {
        const {
            setAddresses,
            enableSpinner,
            setShoppingCart,
            setBackOrder,
            notificationReducer: { showAlert }
        } = this.props;
        let shoppingCart = [];
        let backOrder = [];
        enableSpinner(true);

        let response = await apiClient.getProfile();
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            shoppingCart = response.data.shoppingCart;
            backOrder = response.data.backOrder;
            setAddresses(response.data.addresses);
        }

        if (shoppingCart.length) {
            let shoppingCartResponse = await apiClient.getShoppingCart(shoppingCart);
            if (shoppingCartResponse.status === SERVICE_RESPONSE.ERROR) {
                enableSpinner(false);
                showAlert({ type: 'error', message: "Aviso: " + shoppingCartResponse.message, timeOut: 8000 });
                return;
            }
            await setShoppingCart(shoppingCartResponse.data.shoppingCart);
            await setBackOrder(backOrder);
            // Almacenar los items que se encuentren del shopping cart
            this.setState({ shoppingCartItems: shoppingCartResponse.data.shoppingCart });
        }
        enableSpinner(false);
    }
    
    async addToShopingCart() {
        Swal.fire({
            title: '¿Deseas actualizar o eliminar tu carrito?',
            //text: "¿Deseas actualizar o eliminar tu carrito?",
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Actualizar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                //Los PROPS los consigues de abajo
                const { order, notificationReducer: { showAlert }, configReducer, enableSpinner } = this.props;
                //Lista de los productos del documento

                const delivery = [];

                for (var indice = 0; indice < order.body.length; indice++) {
                    const arreglo = order.body[indice];
                    let esDuplicado = false;
                    for (var i = 0; i < delivery.length; i++) {

                        if (delivery[i].ItemCode === arreglo.ItemCode) {
                            delivery[i].Quantity += parseInt(arreglo.Quantity);
                            esDuplicado = true;
                            break;
                        }
                    }
                    if (!esDuplicado) {
                        delivery.push(arreglo);
                    }
                }

                let items = [];
                //Evadir el Articulo de envio
                delivery.map(item => {
                    if (item.ItemCode !== "MANIOBRAS" && item.ItemCode !== "MANIOBRAS II") {
                        items.push({ ItemCode: item.ItemCode, quantity: parseInt(item.Quantity) });
                    }
                });
                //Lista sin el Envio o Traslado
                //Comineza a pasarse al carro
                enableSpinner(true);
                //agregar al carro directo con sql
                let responsesd = await apiClient.updateShoppingCartLocal(items);
                if (responsesd.status === 1) {
                    $('#deliveryModal').modal('hide');
                    showAlert({ type: 'success', message: 'Nuevos articulos agregados al carrito', timeOut: 8000 });
                    configReducer.history.goShoppingCart();
                }
                enableSpinner(false);
            } else {
                //Los PROPS los consigues de abajo
                const { order, notificationReducer: { showAlert }, configReducer, enableSpinner } = this.props;
                //Lista de los productos del documento

                const delivery = [];

                for (var indice = 0; indice < order.body.length; indice++) {
                    const arreglo = order.body[indice];
                    let esDuplicado = false;
                    for (var i = 0; i < delivery.length; i++) {

                        if (delivery[i].ItemCode === arreglo.ItemCode) {
                            delivery[i].Quantity += parseInt(arreglo.Quantity);
                            esDuplicado = true;
                            break;
                        }
                    }
                    if (!esDuplicado) {
                        delivery.push(arreglo);
                    }
                }

                // Obtén los artículos del carrito del estado
                const { shoppingCartItems } = this.state;

                let items = [];
                //Evadir el Articulo de envio
                delivery.map(item => {
                    if (item.ItemCode !== "MANIOBRAS" && item.ItemCode !== "MANIOBRAS II") {
                        items.push({ ItemCode: item.ItemCode, quantity: parseInt(item.Quantity) });
                    }
                });
                // Concatenar 'shoppingCartItems' con 'items'
                items = items.concat(shoppingCartItems);
                //Lista sin el Envio o Traslado
                //Comineza a pasarse al carro
                enableSpinner(true);
                //agregar al carro directo con sql
                let responsesd = await apiClient.updateShoppingCartLocal(items);
                if (responsesd.status === 1) {
                    $('#deliveryModal').modal('hide');
                    showAlert({ type: 'success', message: 'Se ha actualizado el carrito correctamente ', timeOut: 8000 });
                    configReducer.history.goShoppingCart();
                }
                enableSpinner(false);
            }
        });
    }
    render() {
        const { order, guia } = this.props;
        return (
            <div className="modal fade" id="deliveryModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: 'center' }}>
                <div className="modal-dialog modal-xl" role="document" style={{ margin: '1.75rem auto' }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: config.navBar.primaryColor }}>
                            <h5 className="modal-title" style={{ color: config.navBar.textColor2 }}>Productos de la entrega</h5>
                            <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3" style={{ maxHeight: '70vh', overflow: 'auto', padding: '1.5rem' }}>
                            <ProgressBar guiaStatus={guia} currentDocInfo={Array.isArray(order.header) ? order.header[0] : order.header} type={'OINV'} />
                            <h3 className="text-center font-weight-bold" style={{ fontSize: '1.4rem' }}>Detalle del documento</h3>

                            <div>
                                {!!order.body && order.body.map(item => {
                                    // Arreglo de imagenes del campo extra
                                    let imagesArray = item.U_Handel_ImagesArray || '';
                                    imagesArray = imagesArray.split('|');
                                    let image = imagesArray[0] ? imagesArray[0] : item.PicturName;
                                    // let imagenShow = image ? (config.BASE_URL + SERVICE_API.getImage + '/' + image) : require('../images/noImage.png');
                                    let imagenShow = image ?
                                        // require('../images/standard/NwSerdi/GENERICA.png')
                                        (config.BASE_URL + SERVICE_API.getImage + '/' + image)
                                        : require('../images/noImage.png');
                                    return (
                                        <div key={item.ItemCode} className=' text-left card ' style={{ border: 'none' }}>
                                            <div className='row' style={{ padding: 10, textAlign: 'center' }}>
                                                <div className='col-sm-2' style={{ margin: 0 }}>
                                                    <img className="img-fluid"
                                                        style={{ backgroundColor: 'white', maxHeight: 130 }}
                                                        src={imagenShow}
                                                        alt="Imagen del producto"
                                                    />
                                                </div>
                                                <div className='col-sm-10'>
                                                    <div className="container p-0">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className='text-left' style={{ color: "#808080", fontSize: 14 }}>
                                                                    {item.ItemName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row font-weight-bold">
                                                            <div className="col-md-12 table-responsive ">
                                                                <table className="table">
                                                                    <thead style={{ textAlign: "-webkit-center" }}>
                                                                        <tr style={{ backgroundColor: config.navBar.primaryColor, color: "white", fontSize: "1rem" }}>
                                                                            <th scope="col">Clave</th>
                                                                            <th scope="col">Cantidad</th>
                                                                            <th scope="col">Precio</th>
                                                                            <th scope="col">Precio Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={{ fontSize: "1rem" }}>
                                                                        <tr>
                                                                            <td> {item.ItemCode} </td>
                                                                            <td> {Number(item.Quantity).toFixed(4)} </td>
                                                                            <td>
                                                                                <CurrencyFormat
                                                                                    value={item.Price}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix={'$ '}
                                                                                    suffix={config.general.currency}
                                                                                >
                                                                                </CurrencyFormat>
                                                                            </td>
                                                                            <td>
                                                                                <CurrencyFormat
                                                                                    value={item.LineTotal}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix={'$ '}
                                                                                    suffix={config.general.currency}
                                                                                >
                                                                                </CurrencyFormat>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            {/* {order.body.map(item => {
                                return <div key={item.ItemCode} className="row">
                                    <div className="col-md-4">
                                        <img className="img-fluid" style={{ backgroundColor: 'white', maxHeight: 130 }}
                                            src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="container p-0">
                                            <div className="row text-left">
                                                <div className="col-md-12" >
                                                    {item.ItemName}
                                                </div>
                                            </div>
                                            <div className="row font-weight-bold ">
                                                <div className="col-md-3">
                                                    Clave
                                                </div>
                                                <div className="col-md-3">
                                                    Cantidad
                                                </div>
                                                <div className="col-md-3">
                                                    Precio
                                                </div>
                                                <div className="col-md-3">
                                                    Precio Total
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    {item.ItemCode}
                                                </div>
                                                <div className="col-md-3">
                                                    {parseInt(item.Quantity)}
                                                </div>
                                                <div className="col-md-3">
                                                    <CurrencyFormat 
                                                        value={item.Price} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={'$ '}
                                                        suffix={config.general.currency}
                                                        >
                                                    </CurrencyFormat>                                                    
                                                </div>
                                                <div className="col-md-3">
                                                    <CurrencyFormat 
                                                        value={item.LineTotal}
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={'$ '}
                                                        suffix={config.general.currency}
                                                        >
                                                    </CurrencyFormat>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })} */}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn text-white" style={{ background: "#CE3529" }} onClick={() => this.addToShopingCart()}>
                                <i className="fas fa-cart-plus" />
                                &nbsp; Agregar al carrito
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store,state) => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        sessionReducer: state.SessionReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemDetailsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsGift: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_ITEMS_GIFT, value }),
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        setBackOrder: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_BACK_ORDER, value }),
        setRole: value => dispatch({ type: DISPATCH_ID.SESSION_SET_ROLE, value }),
        setAddresses: value => dispatch({ type: DISPATCH_ID.SESSION_SET_ADDRESSES, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeliveryDetailModel);
