import React, {Component} from 'react';
import {connect} from 'react-redux';
import $ from 'jquery';
import { config, ROLES, DISPATCH_ID, SERVICE_RESPONSE, VIEW_NAME } from '../libs/utils/Const';
import { ApiClient } from '../libs/apiClient/ApiClient.js';

let apiClient = ApiClient.getInstance();

class NavBarShoppingCartIcon extends Component {

    componentDidMount() {
        $('.cont-cart').hide();
    }

    refresh = () => {
        const {shoppingCartReducer} = this.props;

        setTimeout(() => {
            if (shoppingCartReducer.items.length === 0) {
                $('.cont-cart').hide();
            } else {
                $('.cont-cart').show();
            }
        }, 50);
    }
    

    render() {
        const {shoppingCartReducer, configReducer :{history}} = this.props;
        return (
            <span className="shooping">
                <img src={config.navBar.carrito} className="Img-fluid"  onClick={() => history.goShoppingCart() } />
                <span className="cont-cart" style={{color: "#575757", fontFamily: "Helvetica"}}>{shoppingCartReducer.items.length}</span>{this.refresh()}
            </span>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBarShoppingCartIcon)

