import React from "react";
import moment from "moment";

class ReportModal extends React.Component {
  render() {
    const {date,data, user, SubTotal, Total} = this.props;

    if(data.length === 0){
    return (
        <div></div>
    );
    }
    return (
      <div className="p-5 mt-3">
        <div className="row">
          <div className="col-3">
              <img src={require('../images/standard/LOGO COLOR_navbar.svg')} alt="Logo" />
              <h4 className="text-center">ACEROS Y TREFILADOS DEL NORESTE S. DE R.L. DE C.V.<br /></h4>
              <br />
          </div>
          <div className="col-6 text-center">
            <h3 style={{fontWeight:'bold'}}>COTIZACIÓN</h3>
          </div>
          <div className="col-3 text-left">
            <h4 style={{fontWeight:'bold'}}>FECHA: {date}</h4>
            <h4 className="text-left">Av Ejército Nacional 1059-S, Emiliano Zapata CP. 80260<br/>Culiacán Rosales, Sinaloa, Mexico.</h4>
          </div>
        </div>
        

        {/* <table style={{width: '100%'}}>
          <thead >
            <th style={{width:'300px',textAlign:'right', paddingTop:'70px'}}>
              <img style={{width: '100%', height:'auto'}} src={require('../images/standard/LOGO COLOR_navbar.svg')}/>
            </th>
            <th style={{textAlign:'center'}}>
              <h2 style={{fontWeight:'bold'}}>SERDI</h2>
              <h4>Av Ejército Nacional 1059-S, Emiliano Zapata CP. 80260 </h4>
              <h4>Culiacán Rosales, Sinaloa, Mexico.</h4>
            </th>
            <th style={{width:' 50px',visibility:'hidden'}}><p>Oculto</p></th>
          </thead>
        </table> */}
        <br></br>
        <br></br>
        <div className="row">
          <div className="col-3">
            <h4 style={{fontWeight:'bold'}}>CLIENTE: {user}</h4>
          </div>
          <div className="col-9"></div>
        </div>
        <br/>
        <div className="text-center">
          <table style={{width: '100%', borderCollapse: 'collapse'}}>
            <tr style={{backgroundColor:'#002877',paddingTop: '12px', paddingBottom: '12px', textAlign: 'left', color: 'white'}}>
              
              <th style={{textAlign:'center'}}>#</th>
              <th style={{textAlign:'center'}}>Código SAP</th>
              <th style={{textAlign:'left'}}>Descripción</th>
              <th style={{textAlign:'center'}}>Cantidad</th>
              <th style={{textAlign:'center'}}>Precio</th>
              {/* <th style={{textAlign:'center'}}>% Descuento</th> */}
              <th style={{textAlign:'center'}}>Precio total</th>
              
            </tr>
            {!!data && data.map((item, index) => {
              return (
                      <tr>
                          
                          <th style={{textAlign:'center'}}>{index+1}</th>
                          <th style={{textAlign:'center'}}>{item.ItemCode}</th>
                          <th style={{textAlign:'left'}}>{item.ItemName}</th>
                          <th style={{textAlign:'center'}}>{item.Quantity}</th>
                          <th style={{textAlign:'right'}}>$ {Number(item.Price - (item.Price * (item.Discount || 0 / 100) / 100)).toFixed(2)}</th>
                          {/* <th style={{textAlign:'center'}}>{item.Discount} %</th> */}
                          <th style={{textAlign:'right'}}>$ {Number((item.Price - (item.Price * (item.Discount || 0 / 100) / 100)).toFixed(2) * (item.Quantity)).toFixed(2)}</th>
                          
                      </tr> 
                  );
              })
            }     

            <br/>
            <tr>
                <th colspan="4"></th>
                <th  style={{textAlign:'right'}}>Subtotal:</th>
                <th style={{textAlign:'right'}}>$ {parseFloat(SubTotal).toFixed(2)}</th>
            </tr>
            <tr>
                <th colspan="4"></th>
                <th style={{textAlign:'right'}}>I.V.A. (16%):</th>
                <th style={{textAlign:'right'}}>$ {parseFloat(SubTotal*.16).toFixed(2)}</th>
                
            </tr>
            <tr>
                <th colspan="4"></th>
                <th style={{textAlign:'right'}}>Total:</th>
                <th style={{textAlign:'right'}}>$ {parseFloat((SubTotal*.16) + SubTotal).toFixed(2)}</th>
                
            </tr>
          </table>
        </div>    
        <br></br>

        
      </div>
    );
  }
}

export default ReportModal;