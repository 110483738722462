import React, { Component } from 'react';
import { config, SERVICE_API, DISPATCH_ID, SERVICE_RESPONSE } from "../libs/utils/Const";
import { ApiClient } from "../libs/apiClient/ApiClient";
import $ from 'jquery';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';

let apiClient = ApiClient.getInstance();

class ModalPrivacidad extends Component {

    render() {
        const { acceptTerms } = this.props;
        return (
            <div className="modal fade" id="exampleModalLong" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true"  data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle" style={{ fontWeight: 'bold' }}>AVISO DE PRIVACIDAD</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" style={{ fontSize: "30px" }}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-justify" style={{ height: '500px' }}>
                                <ol>
                                    <p>Para ACEROS Y TREFILADOS DEL NOROESTE S DE RL DE CV. (en lo sucesivo “SERDI”), la
                                        protección de sus datos personales, su privacidad, su confianza y su preferencia son parte de
                                        nuestras prioridades, por lo cual, en congruencia con nuestros valores empresariales, nos
                                        aseguramos de proteger su información mediante la continua revisión de nuestros procesos
                                        de protección física y electrónica. Es por esto que, a continuación, compartimos con usted
                                        nuestra política de privacidad y protección de datos personales, en apego a la Ley Federal de
                                        Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo “LA LEY”).
                                    </p>
                                    <p>En cualquier momento, usted puede limitar el uso o divulgación de sus datos personales
                                        mediante el ejercicio de los derechos de Acceso, Rectificación, Cancelación u Oposición (en
                                        lo sucesivo “ARCO”) establecidos en “LA LEY”, presentando o enviando su solicitud por
                                        escrito al domicilio de “SERDI”. Se entiende que usted, como titular de sus datos personales,
                                        consiente tácitamente el tratamiento conforme a lo enunciado en el presente aviso de
                                        privacidad, cuando habiéndolo puesto a su disposición por cualquier medio, no manifieste su
                                        oposición. Así mismo, tendrá permanentemente a su disposición este aviso de privacidad y
                                        sus actualizaciones, en nuestra página de Internet https://serdiaceros.com.mx/legal/avisode-privacidad.</p>
                                    <br />
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>1. IDENTIDAD:</p>
                                    <p>“SERDI” es una sociedad mexicana constituida conforme a las leyes aplicables y es quien tiene la calidad de responsable frente a usted como titular de sus datos personales.</p>
                                    <br />
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>2. DOMICILIO DE “SERDI”:</p>
                                    <p>Para efectos del presente aviso de privacidad, “SERDI” señala su domicilio en Calle Convención de Apatzingán #660, Colonia Emiliano Zapata, C.P. 80260, Culiacan Sinaloa México. </p>
                                    <br />
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>3. QUE DATOS RECABAMOS Y COMO LO HACEMOS:</p>
                                    <p>“SERDI” posee, recaba o recabará de usted, los datos personales estrictamente necesarios,
                                        para la adecuada realización de las operaciones, compras, ventas, prestación de servicios
                                        y/o celebración de los demás actos que puede realizar conforme a la Ley y sus estatutos
                                        sociales. Dichos datos personales pueden haber o ser obtenidos de usted personalmente o,
                                        por cualquier medio electrónico, óptico, sonoro, visual o, a través de cualquier otra
                                        tecnología. Asimismo, podemos obtener datos personales de los que usted es titular, a través
                                        de terceros y de otras fuentes permitidas por la ley. Los datos personales recabados o que se
                                        recaben de usted podrán incluir, entre otros, los siguientes:
                                        <ul>
                                            a) Datos de identificación y de contacto: nombre completo, dirección personal o de negocio,
                                            teléfono de casa o negocio, teléfono celular, estado civil, firma autógrafa, firma electrónica,
                                            correo electrónico, R.F.C., C.U.R.P., lugar y fecha de nacimiento, edad, entre otros.
                                        </ul>
                                        <ul>
                                            b) Datos financieros y patrimoniales: bienes muebles e inmuebles, historial crediticio,
                                            ingresos y egresos, cuentas bancarias, estados de cuenta, estados financieros,
                                            investigaciones de crédito, entre otros.
                                        </ul>
                                    </p>
                                    <br />
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>4. DATOS PERSONALES SENSIBLES:</p>
                                    <p>“SERDI” regularmente no recaba datos personales sensibles, pero si en algún momento
                                        fuese necesario recabar este tipo de datos, les serán aplicables los mismos medios para
                                        ejercer los derechos “ARCO” y/o limitar su uso o divulgación, de conformidad con el presente
                                        Aviso de Privacidad. Se consideran datos personales sensibles, aquellos que afecten a la
                                        esfera más íntima de su titular, o cuya utilización indebida pueda dar origen a discriminación
                                        o conlleve un riesgo grave para éste, tales como origen racial o étnico, estado de salud
                                        presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación
                                        sindical, opiniones políticas, preferencia sexual, etc. En apego a lo dispuesto por “LA LEY”, se
                                        considerará que usted consiente el tratamiento de sus Datos Personales Sensibles, en caso
                                        de haberlos otorgado, al momento de poner a su disposición el presente aviso y que no
                                        manifieste su oposición al tratamiento al que se sujetarán.
                                    </p>
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>5. FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES:</p>
                                    <p>“SERDI” podrá realizar el tratamiento de sus datos personales, incluyendo especialmente
                                        aquellos personales, financieros y/o patrimoniales, para los siguientes fines:
                                        <ul>
                                            a) La realización de todas y cada una de las operaciones, trabajos, prestación de los servicios
                                            y/o celebración de los demás actos y contratos que conforme a la ley y nuestros estatutos
                                            sociales podemos realiza.
                                        </ul>
                                        <ul>
                                            b) La realización, creación e/o implementación de procesos analíticos y/o estadísticos
                                            necesarios o convenientes, relacionados con las operaciones, servicios y actos que
                                            realizamos.
                                        </ul>
                                        <ul>
                                            c) La publicidad y promoción de los productos y servicios ofrecidos por, o relacionados con
                                            “SERDI” o, terceros nacionales o extranjeros con quienes mantengamos relaciones
                                            comerciales.
                                        </ul>
                                        <ul>
                                            d) La atención de requerimientos de cualquier autoridad competente.
                                        </ul>
                                        <ul>
                                            e) La realización de cualquier actividad complementaria o auxiliar, necesaria para la
                                            realización de los fines anteriores.
                                        </ul>
                                        <ul>
                                            f) La realización de consultas, investigaciones y revisiones, en relación a quejas o
                                            incumplimientos en las operaciones y actividades que realizamos.
                                        </ul>
                                        <ul>
                                            g) La puesta en contacto con usted para tratar cualquier tema relacionado con sus datos
                                            personales o con el presente aviso de privacidad.
                                        </ul>
                                    </p>
                                    <br></br>
                                    <p>
                                        El tratamiento de sus datos personales se limitará al cumplimiento de las finalidades
                                        previstas en este aviso de privacidad y, a los fines distintos que resulten compatibles o
                                        análogos a los establecidos en este aviso de privacidad, sin que para ello se requiera obtener
                                        nuevamente su consentimiento. Asimismo, el tratamiento de datos personales será el que
                                        resulte necesario, adecuado y relevante en relación con las finalidades previstas en este
                                        aviso de privacidad, así como con los fines distintos que resulten compatibles o análogo.
                                    </p>
                                    <br />
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>6. TRANSFERENCIA DE DATOS:</p>
                                    <p>Para cumplir con las finalidades previstas en este aviso de privacidad, “SERDI” podría
                                        requerir efectuar la transferencia de sus datos personales dentro e incluso fuera de la
                                        República Mexicana. “SERDI” se compromete a vigilar que se cumplan los principios legales
                                        de protección en torno a la transferencia de sus datos personales y manifiesta su
                                        compromiso para que se respete en todo momento, por nosotros y por nuestros socios o
                                        aliados comerciales, el presente Aviso de Privacidad.</p>
                                    <p>
                                        En virtud de este Aviso de Privacidad usted acepta la transferencia de sus datos, en el
                                        entendido de que “SERDI” pretenda transferir o transfiera, sus datos personales a terceros
                                        nacionales o extranjeros, distintos del responsable, a quienes les comunicaremos este aviso
                                        de privacidad y las finalidades a las que usted sujetó su tratamiento y, el tercero receptor
                                        asumirá las mismas obligaciones que correspondan a “SERDI”, por lo que el tratamiento de
                                        sus datos deberá realizarse conforme a lo convenido en este Aviso de Privacidad.
                                    </p>
                                    <p>
                                        Las transferencias nacionales o internacionales de datos podrán llevarse a cabo sin el
                                        consentimiento del titular, entre otros casos, siempre que la transferencia sea efectuada a
                                        sucursales o afiliadas bajo el control común del responsable o, a cualquier sociedad o
                                        entidad del mismo grupo del responsable que opere bajo los mismos procesos y políticas
                                        internas, o bien, en virtud de que sea necesaria por cualquier acto ejecutado en interés suyo
                                        y, en su caso, por que dicha transferencia sea precisa para el mantenimiento o cumplimiento
                                        de una relación jurídica o comercial entre “SERDI” y usted.
                                    </p>
                                    <p>
                                        “SERDI” también podrá efectuar transferencias de datos personales, a favor de terceros que
                                        operen de forma conjunta algún producto, servicio, software y/o cualquier infraestructura
                                        informática con “SERDI”; terceros prestadores de servicios, necesarios para la operación de
                                        “SERDI”, comisionistas y/o proveedores que realicen operaciones y/o brinden servicios con
                                        “SERDI”; profesionistas, asesores y/o consultores externos, para la administración de las
                                        operaciones, servicios y demás actos que la responsable puede realizar conforme a la ley y
                                        sus estatutos sociales, así como para la defensa de los intereses de “SERDI” ante cualquier
                                        controversia legal derivada de dichas operaciones y servicios, tales como agencias de
                                        cobranza, auditores externos, consultores legales, contables, fiscales, etc.
                                    </p>
                                    <br></br>
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>7. PÁGINA WEB, MEDIOS ELECTRONICOS E INTERNET:</p>
                                    <p>Los datos que usted nos proporcione por medio de la página web, correo electrónico o por
                                        cualquier medio digital o de mensajería oficial de “SERDI”, también se encuentran protegidos
                                        mediante el presente Aviso de Privacidad, por lo cual, “SERDI” implementará las medidas
                                        adecuadas de seguridad para el tratamiento de los datos que nos proporcione por alguno de
                                        estos medios oficiales.</p>
                                    <p>
                                        “SERDI” podrá hacer uso de medios digitales, mensajería y redes sociales para publicidad o
                                        alguna otra finalidad que resulte conveniente para su objeto comercial, por lo cual, es
                                        importante mencionarle que las redes sociales y los medios electrónicos, constituyen redes
                                        de comunicación e interconexión entre plataformas digitales que no se encuentran bajo el
                                        control directo de “SERDI” y, el uso que se le pueda dar a la información compartida
                                        públicamente por estos medios, no serán responsabilidad de “SERDI”. La información que
                                        usted publique o nos comparta a través de las redes sociales, no constituye, ni forma parte
                                        de los datos personales sujetos a la protección de este Aviso de Privacidad.
                                    </p>
                                    <p>
                                        “SERDI” podrá usar determinadas “cookies”, “web beacons” o archivos “access.log” para
                                        obtener cierta información que le permita prestarle un mejor servicio virtual o implementar
                                        estrategias de mercadotecnia, los cuales serán protegidos por el presente Aviso de
                                        Privacidad. Las “cookies” son archivos descargados de forma automática para ser
                                        almacenados en el disco duro del usuario que navega en una página web, estos permiten al
                                        servidor recordar algunos datos del usuario. Los “web beacons” son imágenes visibles u
                                        ocultas dentro de un sitio web que se utiliza para monitorear el comportamiento del usuario
                                        en estos medios y mediante los cuales se puede obtener información del titular. Así mismo,
                                        al visitar la página web, esto queda registrado en un archivo llamado “access.log”, del cual se
                                        pueden obtener algunos datos del usuario.
                                    </p>
                                    <br></br>
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>8. DEPARTAMENTO DE DATOS PERSONALES:</p>
                                    <p>El Representante Legal de “SERDI”, será el encargado de dar atención y trámite a las
                                        solicitudes de los titulares para el ejercicio de los derechos “ARCO” a que se refiere la Ley
                                        Federal de Protección de Datos Personales en Posesión de Particulares y el presente Aviso de
                                        Privacidad.</p>
                                    <br></br>
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>9. MEDIOS PARA EJERCER LOS DERECHOS “ARCO”:</p>
                                    <p>Con apego a lo dispuesto en “LA LEY”, usted puede por sí mismo o mediante representante
                                        legal debidamente acreditado, ejercer sus derechos de Acceso, Rectificación, Cancelación y
                                        Oposición, formulando una solicitud por escrito que podrá presentar en cualquiera de
                                        nuestras sucursales y que deberá estar dirigida al Departamento de Datos Personales de
                                        “SERDI” para su trámite, mencionando su nombre completo, RFC con homoclave y domicilio
                                        actual para notificarle la resolución a su solicitud, así como cuál o cuáles de estos derechos
                                        son los que solicita sean ejercidos y sobre cuales datos personales desea que se apliquen. El
                                        ejercicio de cualquiera de estos derechos no es requisito previo ni impide el ejercicio de otro.</p>
                                    <br></br>
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>10. REVOCACIÓN DEL CONSENTIMIENTO Y LIMITACIÓN AL USO O DIVULGACIÓN:</p>
                                    <p>Usted también puede limitar el uso o divulgación de sus datos personales o, revocar su
                                        consentimiento para el tratamiento de los mismos, en la inteligencia de que, todo tratamiento
                                        de datos personales estará sujeto su consentimiento, salvo las excepciones previstas por la
                                        Ley Federal de Protección de Datos Personales en Posesión de Particulares o las que se
                                        deriven del presente Aviso de Privacidad, para lo cual, puede emplear el mismo medio
                                        establecido en el presente Aviso de Privacidad, para el ejercicio de los derechos ARCO.
                                    </p>
                                    <br></br>
                                    <p style={{ fontWeight: 'bolder', fontSize: '15px' }}>11. PROCEDIMIENTO Y MEDIO PARA COMUNICAR CAMBIOS AL AVISO DE PRIVACIDA:</p>
                                    <p>“SERDI” se reserva el derecho de efectuar en cualquier momento cambios o modificaciones
                                        al presente Aviso de Privacidad, ya sea para la atención de novedades legislativas,
                                        regulatorias, jurisprudenciales, políticas internas de la empresa, prácticas del mercado o por
                                        cualquier otra razón. Cualquier cambio que se realice al presente aviso de privacidad, será
                                        incorporado al mismo por el Departamento de Datos Personales y le será dado a conocer
                                        mediante un comunicado a su ultimo domicilio o correo electrónico que nos haya
                                        proporcionado, o a través de anuncios visibles en nuestros establecimientos o, integrados en
                                        los documentos oficiales de la empresa, así como también será publicado en nuestra página
                                        de Internet https://serdiaceros.com.mx/legal/aviso-de-privacidad.</p>
                                </ol>
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => acceptTerms()}>Aceptar</button>
                        </div>
                    </div>
                
                </div>
            </div>
        );
    }

}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemDetailsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModalPrivacidad);
