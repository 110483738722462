import React, { Component } from 'react';
import { ROLES } from '../libs/utils/Const';

class SetZipCodeNavBarModal extends Component {
    componentDidMount() {
        const {CloseActionsModal} = this.props;
        const modal = document.getElementById('SetZiCodeModalWhs');
        modal.addEventListener('click', (event) => {
            if (event.target === modal) {
                CloseActionsModal();
            }
        });
    }
    render() {
        const { ZipCode, ChangeZipCode, GetWhsByHandelUser, Locations, SelectedLocation, GetLocationsByAddress, ChangeLocationApi, CloseActionsModal, sessionReducer} = this.props;
        return (
            <div className="modal fade" id="SetZiCodeModalWhs" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content p-2 h-100" style={{ float: "left", width: "535px", borderRadius: "10px", }}>
                        <div className="modal-header" style={{ borderRadius: "10px", backgroundColor: "white", borderColor: "white" }}>
                            <div>
                                <img src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEixxO0qZkEEyv7J-WWF0qfxopVAYxz4tOYHLnS4zth6rcq0pZDodde4qfe4jVU6HYGggIFYv6yoGrw1NZZhZeQYTV8o7YNbkQU3vwYFYg9GxU7nOnYFQZWv1c8_ZsiLMk2Auvc_RPifHfWaKb-EK2obbnuG1Azsrmydip6qoVhbimRyV8apmopopaH5tr02/s320/icono_ubicaci%C3%B3n-01.png" alt="Buscar" style={{ float: 'left', marginLeft: "15px", marginTop: "-40px", width: "90px", height: "90px", backgroundColor: "white", borderRadius: "100%" }} />
                            </div>
                            <div className='m-0'>
                                <button type="button" className="btn btn-outline-danger p-2 border-0" data-dismiss="modal" /*data-dismiss="modal" */ onClick={() => CloseActionsModal()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body p-0">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col text-center">
                                        <h4 className="font-weight-bold">Escribe tu dirección </h4>
                                        <p className="p-3">Agrega tu información y verifica la disponibilidad del inventario mas cercano a ti.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9">
                                    {/* ZIPCODE*/}
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text font-weight-bold" id="basic-addon1" style={{ color: '#c03', background: "white" }}>
                                                <i className="fa fa-map-marker" />
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Código postal'
                                            style={{
                                                backgroundColor: 'transparent',
                                                borderColor: '#ced4da',
                                                color: '#000'
                                            }}
                                            onChange={(event) => {
                                                const inputText = event.target.value;
                                                const sanitizedText = inputText.replace(/[^0-9]/g, '');
                                                const truncatedText = sanitizedText.slice(0, 5); 
                                                ChangeZipCode(truncatedText);
                                            }}
                                            value={ZipCode ?? ""}
                                            maxLength={5} 
                                            onKeyPress={(ev) => {
                                                if (ev.key === 'Enter') {
                                                    GetLocationsByAddress(ZipCode);
                                                }
                                            }}
                                        />

                                        <button
                                            type="button"
                                            style={{ backgroundColor: 'transparent', color: '#00245D' }}
                                            className="input-group-text"
                                            data-toggle="modal"
                                            onClick={() => GetLocationsByAddress(ZipCode)}
                                        >
                                            <img
                                                src="https://www.officedepot.com.mx/_ui/responsive/theme-officedepot/images/detail2022/Lupa_search_codeZip.svg"
                                                alt="Buscar"
                                                style={{ width: '20px', height: '20px' }}
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <a className='p-0 text-center text-danger' href='https://www.correosdemexico.gob.mx/SSLServicios/ConsultaCP/Descarga.aspx' target="_blank" rel="noopener noreferrer"> No conozco mi C.P.</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-2">
                                    {/* ZONAS OBTENIDAS */}
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text font-weight-bold" id="basic-addon1">Ubicación</span>
                                        </div>
                                        <select
                                            className="form-control"
                                            style={{ cursor:'pointer', color: "#000", borderRadius: 4, backgroundColor: "transparent", height: 40 }}
                                            // value={sucursalModel.TimeZone || ""}
                                            onChange={(event) => ChangeLocationApi(event.target.value)}
                                            value={SelectedLocation ?? ''}
                                        >
                                            <option value=''>Seleccionar</option>
                                            {Locations.map((location, index) => {
                                                return <option value={index} key={index}>{location.Address}</option>
                                            })}

                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* {sessionReducer.role === ROLES.CLIENT ?
                                <div className="modal-footer">
                                    <button type="button" class="btn btn-danger btn-sm rounded-4" onClick={() => GetWhsByHandelUser('', true)}> Usar mi posición actual</button>
                                </div>
                            :''} */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetZipCodeNavBarModal;
