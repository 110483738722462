import React, { Component } from 'react';
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID } from '../libs/utils/Const';
import { connect } from "react-redux";
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import { BreadCrumb } from './index'
import "./ItemSlider.css";
import { Redirect } from 'react-router';

class SideBarItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            PriceValue:"",
            cantidadMostrada:10,
            showQtyFab:10,
            minPriceSlide: 0,
            maxPriceSlide: 1000,
            showMoreFacilities: 1, // Inicializar en 1
            showMoreBrands: 1,
        }
    }
    componentDidMount(){
        const { itemsReducer:{minPrice,maxPrice}} = this.props
        this.setState({
            minPriceSlide:minPrice,
            maxPriceSlide:maxPrice
        })
    }
    seeAllCategories = ()=>{
        $('#filterCategoriesModal').modal('show');
    }

    uniqueFilter = async (property, value, value2) => {
        const { itemsReducer, setItemsFilters, viewOne, itemsReducer: { viewOneS } } = this.props;
        let data = { property: property, value: value, value2 }
        // Nueva variable de redux para guardar filtro
        setItemsFilters(data);
        setTimeout(async () => {
            // Ejecutar searchByKey
            if (viewOne && viewOne !== '') {
                await itemsReducer.searchByKey(0, viewOne);
            } else {
                await itemsReducer.searchByKey();
            }
        }, 350);
        $('#filterCategoriesModal').modal('hide');
    };

    changeMinPrice = async (price) => {
        const { setMinPrice } = this.props;
        if ((!isNaN(price) && price > 0) || price === '') {
            setMinPrice(price)
        }


    }
    changeMaxPrice = async (price) => {
        const { setMaxPrice } = this.props;
        if ((!isNaN(price) && price > 0) || price === '') {
            setMaxPrice(price)
        }
    }

    filtroMin = (event) => {
        const { value} = event.target;
        const {itemsReducer:{minPrice,maxPrice} } = this.props
        const numericValue =+value;
        if(numericValue < maxPrice){
            this.setState({
                minPrice: numericValue
              });
    
            this.changeMinPrice(numericValue);
        }
      }
      
      filtroMax = (event) => {
        const { value } = event.target;
        const {itemsReducer:{minPrice,maxPrice} } = this.props
        const numericValue = +value;
        if(numericValue > minPrice){
            this.setState({
            maxPrice: numericValue
            });
        
            this.changeMaxPrice(numericValue);
        }
        
    }
    
    deleteFilters = async () => {
        const { itemsReducer, itemsReducer: { viewOneS }, setItemsFilters, viewOne } = this.props;
        // Nueva variable de redux para guardar filtro
        setItemsFilters('');
        setTimeout(async () => {
            // Ejecutar searchByKey
            if (viewOneS && viewOneS !== '') {
                await itemsReducer.searchByKey(0, viewOneS);
            } else {
                await itemsReducer.searchByKey();
            }
        }, 350);
        await this.setState({
            PriceValue: ''
        })
        $('#filterCategoriesModal').modal('hide');
    }

    verMas = async (filter) => {
        if (filter === 'brand') {
            this.setState((prevState) => ({
                cantidadMostrada: prevState.cantidadMostrada + 10
            }));
        }
        else if (filter === 'fab') {
            this.setState((prevState) => ({
                showQtyFab: prevState.showQtyFab + 10
            }));
        }
    };
    verMenos = async (filter) => {
        if (filter === 'brand') {
            this.setState((prevState) => ({
                cantidadMostrada: prevState.cantidadMostrada - 10
            }));
        }
        else if (filter === 'fab') {
            this.setState((prevState) => ({
                showQtyFab: prevState.showQtyFab - 10
            }));
        }
    }

    render() {
        const { totalRows, dataCategories,itemsReducer:{minPrice,maxPrice} } = this.props;
        const {cantidadMostrada,showQtyFab, minPriceSlide,maxPriceSlide} = this.state
        // Variable que limita no. de caracteres de nombre de categorías
        let categoryStringLimiter = 20;
        // Limitante de categorias mostradas en SideBar
        let limitNumber = 10;
        // Modal de categorias
        let twoColumns = dataCategories ? dataCategories.itemsCategories ? dataCategories.itemsCategories.length >= 10 ? Number(((dataCategories.itemsCategories.length + 9) / 2).toFixed(0)) : dataCategories.itemsCategories.length : 0 : 0;
        if (!dataCategories || !dataCategories.itemsCategories) {
            return <Redirect to="/" />;
        } else {
            return (
                <div>
                    <div> <BreadCrumb data={dataCategories.itemsCategories} /></div>
                    <div>{totalRows > 1 ? totalRows + ' resultados' : totalRows + ' resultado'}</div>
                    <div>
                        <button
                            className="btn btn-link text-black border-0"
                            onClick={() => this.deleteFilters()}
                            style={{ textDecoration: 'none', fontWeight: 'bold', color: 'black' }}
                        >Borrar filtros</button>
                    </div>
                    {/* Categorías */}
                    {/* {(dataCategories && dataCategories.itemsCategories.length > 0) && 
                        <h5 className="mt-md-5 font-weight-bold TituloCategoria" >Categorías</h5>
                    } */}
                    {(dataCategories && dataCategories.itemsCategories.length > 0) &&
                        <ul className="mt-2 SubCategorias">
                            <dl>
                                {dataCategories.itemsCategories.map((category, index) => {
                                    if (index < limitNumber) {
                                        if (category.category.includes(',')) {
                                            let categoryNameArray = category.categoryName.split(',');
                                            let length = categoryNameArray.length;
                                            let containerReturn = [];
                                            for (let i = 0; i < categoryNameArray.length; i++) {
                                                let dinamicCategory = categoryNameArray[i].substring(0, categoryStringLimiter);
                                                containerReturn.push(<dd >{(i == 0) && dinamicCategory}
                                                    {((i > 0 && i < (length - 1))) && <ol> {dinamicCategory} </ol>}
                                                    {(i == (length - 1)) && <ol className='linkFilter' onClick={() => this.uniqueFilter('categorias', category.category, '')}>
                                                        {dinamicCategory}
                                                    </ol>}
                                                </dd>);
                                            }
                                            return containerReturn;
                                        } else {
                                            return <dd className='linkFilter' onClick={() => this.uniqueFilter('categorias', category.category, '')}>
                                                {category.categoryName}
                                            </dd>
                                        }
                                    }
                                })}
                                {dataCategories.itemsCategories.length >= 10 &&
                                    <dd className='linkFilter font-weight-bold' onClick={() => this.seeAllCategories()}> Ver todas las categorías </dd>}
                            </dl>
                        </ul>
                    }
                    {/* Líneas */}
                    {dataCategories && dataCategories.itemsBrands.length > 0 && (
                        <div>
                            <h5 className="mt-md-4 font-weight-bold TituloCategoria">Líneas</h5>
                            <ul className="mt-2 SubCategorias">
                                <dl>
                                    {dataCategories.itemsBrands
                                        .slice(0, this.state.showMoreBrands ? this.state.showMoreBrands * 10 : 10)
                                        .map((brand, index) => (
                                            <dd style={{cursor:'pointer'}} className="linkFilter" key={index} onClick={() => this.uniqueFilter('marca', brand.code, '')}>
                                                {brand.brand}
                                            </dd>
                                        ))}
                                </dl>
                            </ul>
                            {dataCategories.itemsBrands.length > this.state.showMoreBrands * 10 && (
                                <span
                                    onClick={() => this.setState({ showMoreBrands: this.state.showMoreBrands + 1 })}
                                    style={{
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        color:config.navBar.primaryColor,
                                        margin: '0 10px'
                                    }}
                                >
                                    Ver Más
                                </span>
                            )}
                            {this.state.showMoreBrands > 1 && (
                                <span
                                    onClick={() => this.setState({ showMoreBrands: this.state.showMoreBrands - 1 })}
                                    style={{
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        color: config.navBar.primaryColor,
                                        margin: '0 10px'
                                    }}
                                >
                                    Ver Menos
                                </span>
                            )}
                        </div>
                    )}
                    {/* Marcas */}
                    {dataCategories && dataCategories.itemsFacilities.length > 0 && (
                        <div>
                            <h5 className="mt-md-4 font-weight-bold TituloCategoria">Marcas</h5>
                            <ul className="mt-2 SubCategorias">
                                <dl>
                                    {dataCategories.itemsFacilities
                                        .slice(0, this.state.showMoreFacilities ? this.state.showMoreFacilities * 10 : 10)
                                        .map((facility, index) => (
                                            <dd style={{cursor:'pointer'}} className="linkFilter" key={index} onClick={() => this.uniqueFilter('fabrica', facility.facility, '')}>
                                                {facility.facility}
                                            </dd>
                                        ))}
                                </dl>
                            </ul>
                            {dataCategories.itemsFacilities.length > this.state.showMoreFacilities * 10 && (
                                <span
                                    onClick={() => this.setState({ showMoreFacilities: this.state.showMoreFacilities + 1 })}
                                    style={{
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        color: config.navBar.primaryColor,
                                        margin: '0 10px'
                                    }}
                                >
                                    Ver Más
                                </span>
                            )}
                            {this.state.showMoreFacilities > 1 && (
                                <span
                                    onClick={() => this.setState({ showMoreFacilities: this.state.showMoreFacilities - 1 })}
                                    style={{
                                        cursor: 'pointer',
                                        fontWeight: 'bold',
                                        color: config.navBar.primaryColor,
                                        margin: '0 10px'
                                    }}
                                >
                                    Ver Menos
                                </span>
                            )}
                        </div>
                    )}
                    {/* Precios */}
                    {(dataCategories && dataCategories.pricesSideBar) &&
                        <h5 className="mt-md-4 font-weight-bold TituloCategoria">Precios</h5>
                    }
                    {
                        (dataCategories && dataCategories.pricesSideBar) &&
                        <div>
                            <div class="range_container">
                                <div class="sliders_control">
                                    <input id="fromSlider" type="range" step={1} min={minPriceSlide} max={maxPriceSlide} value={minPrice} onChange={this.filtroMin} />
                                    <input id="toSlider" type="range" step={1} min={minPriceSlide} max={maxPriceSlide} value={maxPrice} onChange={this.filtroMax} />
                                </div>
                            </div>
                            <div className='container'>
                                <div className='row align-items-center'>
                                    <div className='col-sm pb-1'>
                                        <div className='input-group input-group-sm'>
                                            <div className='input-group-prepend'>
                                                <span className='input-group-text'>$</span>
                                            </div>
                                            <input
                                                id='Min-Price'
                                                placeholder='Minimo'
                                                className='form-control'
                                                value={minPrice}
                                                onChange={(event)=>this.changeMinPrice(event.target.value)}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className='col-sm pb-1'>
                                        <div className='input-group input-group-sm'>
                                            <div className='input-group-prepend'>
                                                <span className='input-group-text'>$</span>
                                            </div>
                                            <input
                                                id='Min-Price'
                                                placeholder='Minimo'
                                                className='form-control'
                                                value={maxPrice}
                                                onChange={(event)=>this.changeMaxPrice(event.target.value)}
                                            ></input>
                                        </div>
                                    </div>
                                    
                                    <div className='row justify-content-center'>
                                        <div className='col'>
                                            <button
                                                className="btn btn-light btn-sm"
                                                onClick={() => this.uniqueFilter('precio', minPrice, maxPrice)}>
                                                <span className="btn-icon">
                                                <i className={`${config.icons.search} text-muted`} aria-hidden="true" />
                                                </span>
                                            </button>    
                                        </div>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="modal fade" id="filterCategoriesModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: 'center' }}>
                        <div className="modal-dialog modal-lg" role="document" style={{ margin: '1.75rem auto' }}>
                            <div className="modal-content">
                                <div className="modal-header" style={{ background: config.navBar.iconColor }}>
                                    <h5 className="modal-title" style={{ color: config.navBar.textColor2 }}>Otras categorías</h5>
                                    <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body bg3">
                                    <div className='row justity-content-center'>
                                        <div className='col-md-6'>
                                            {dataCategories && dataCategories.itemsCategories.length > 0 && dataCategories.itemsCategories.map((category, index) => {
                                                if (index >= limitNumber && index <= twoColumns) {
                                                    if (category.category.includes(',')) {
                                                        let categoryNameArray = category.categoryName.split(',');
                                                        let length = categoryNameArray.length;
                                                        let containerReturn = [];
                                                        for (let i = 0; i < categoryNameArray.length; i++) {
                                                            let dinamicCategory = categoryNameArray[i].substring(0, categoryStringLimiter);
                                                            containerReturn.push(<span>
                                                                {(i == 0) && dinamicCategory}
                                                                {((i > 0 && i < (length - 1))) && '/' + dinamicCategory}
                                                                {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick={() => this.uniqueFilter('categorias', category.category, '')}>/{dinamicCategory}</span>}
                                                                {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick={() => this.uniqueFilter('categorias', category.category, '')}></span>}
                                                            </span>);
                                                        }
                                                        containerReturn.push(<br />);
                                                        containerReturn.push(<br />);
                                                        return containerReturn;
                                                    } else {
                                                        let containerReturn = [];
                                                        containerReturn.push(<span className='linkFilter mr-md-2' onClick={() => this.uniqueFilter('categorias', category.category, '')}>
                                                            {category.categoryName}
                                                        </span>);
                                                        containerReturn.push(<br />);
                                                        containerReturn.push(<br />);
                                                        return containerReturn;
                                                    }
                                                }
                                            })}
                                        </div>
                                        <div className='col-md-6'>
                                            {dataCategories && dataCategories.itemsCategories.length > 0 && dataCategories.itemsCategories.map((category, index) => {
                                                if (index >= limitNumber && index > twoColumns) {
                                                    if (category.category.includes(',')) {
                                                        let categoryNameArray = category.categoryName.split(',');
                                                        let length = categoryNameArray.length;
                                                        let containerReturn = [];
                                                        for (let i = 0; i < categoryNameArray.length; i++) {
                                                            let dinamicCategory = categoryNameArray[i].substring(0, categoryStringLimiter);
                                                            containerReturn.push(<span>
                                                                {(i == 0) && dinamicCategory}
                                                                {((i > 0 && i < (length - 1))) && '/' + dinamicCategory}
                                                                {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick={() => this.uniqueFilter('categorias', category.category, '')}>/{dinamicCategory}</span>}
                                                                {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick={() => this.uniqueFilter('categorias', category.category, '')}></span>}
                                                            </span>);
                                                        }
                                                        containerReturn.push(<br />);
                                                        containerReturn.push(<br />);
                                                        return containerReturn;
                                                    } else {
                                                        let containerReturn = [];
                                                        containerReturn.push(<span className='linkFilter mr-md-2' onClick={() => this.uniqueFilter('categorias', category.category, '')}>
                                                            {category.categoryName}
                                                        </span>);
                                                        containerReturn.push(<br />);
                                                        containerReturn.push(<br />);
                                                        return containerReturn;
                                                    }
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
        setNextPage: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value }),
        setItemsFilters: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value }),
        setMinPrice: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_MIN_PRICE, value }),
        setMaxPrice: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_MAX_PRICE, value }),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideBarItems);