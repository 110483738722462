import React, { Component } from 'react';
import { config, SERVICE_API, DISPATCH_ID } from "../libs/utils/Const";
import { ApiClient } from "../libs/apiClient/ApiClient";
import $ from 'jquery';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
let apiClient = ApiClient.getInstance();

class PreliminarDetailModel extends Component {
    async addToShopingCart() {
        //Los PROPS los consigues de abajo
        const { order, notificationReducer: { showAlert }, configReducer, enableSpinner } = this.props;
        //Lista de los productos del documento
        const preliminary = [];

        for (var indice = 0; indice < order.body.length; indice++) {
            const arreglo = order.body[indice];
            let esDuplicado = false;
            for (var i = 0; i < preliminary.length; i++) {

                if (preliminary[i].ItemCode === arreglo.ItemCode) {
                    preliminary[i].Quantity += parseInt(arreglo.Quantity);
                    esDuplicado = true;
                    break;
                }
            }
            if (!esDuplicado) {
                preliminary.push(arreglo);
            }
        }
        let items = [];
        //Evadir el Articulo de envio
        preliminary.map(item => {
            if (item.ItemCode !== "MANIOBRAS" && item.ItemCode !== "MANIOBRAS II") {
                items.push({ ItemCode: item.ItemCode, quantity: parseInt(item.Quantity) });
            }
        });
        //Lista sin el Envio o Traslado
        //Comineza a pasarse al carro
        enableSpinner(true);
        //agregar al carro directo con sql
        let responsesd = await apiClient.updateShoppingCartLocal(items);

        if (responsesd.status === 1) {
            $('#preliminarModal').modal('hide');
            showAlert({ type: 'success', message: ' Agregado a carrito correctamente ', timeOut: 8000 });
            configReducer.history.goShoppingCart();
        }
        enableSpinner(false);
    }
    render() {
        const { order } = this.props;
        return (
            <div className="modal fade" id="preliminarModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: 'center' }}>
                <div className="modal-dialog modal-xl" role="document" style={{ margin: '1.75rem auto' }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: config.navBar.primaryColor }}>
                            <h5 className="modal-title" style={{ color: config.navBar.textColor2, fontSize: '1.2rem' }}>Productos de la orden preliminar</h5>
                            <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ fontSize: '35px' }} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3" style={{ maxHeight: '70vh', overflow: 'auto', padding: '1.5rem' }}>
                            {order.body.map(item => {
                                let imagesArray = item.U_Handel_ImagesArray || '';
                                imagesArray = imagesArray.split('|');
                                let image = imagesArray[0] ? imagesArray[0] : item.PicturName;
                                let imagenShow = image ? (config.BASE_URL + SERVICE_API.getImage + '/' + image) : require('../images/noImage.png');
                                return <div key={item.ItemCode} className=' text-left card ' style={{ border: 'none' }}>
                                    <div className='row' style={{ padding: 10, textAlign: 'center' }}>
                                        <div className='col-sm-2' style={{ margin: 0 }}>
                                            <img className="img-fluid" style={{ backgroundColor: 'white', maxHeight: 130 }}
                                                src={imagenShow}
                                                alt="Imagen del producto"
                                            />
                                        </div>
                                        <div className='col-sm-10'>
                                            <div className="container p-0">
                                                <div className="row">
                                                    <div className="col-12" style={{}}>
                                                        <div className='text-left' style={{ color: "#808080", fontSize: 14 }} >
                                                            {item.ItemName}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row font-weight-bold">
                                                    <div className="col-md-12 table-responsive ">
                                                        <table className="table">
                                                            <thead style={{ textAlign: "-webkit-center" }}>
                                                                <tr style={{ backgroundColor: config.navBar.primaryColor, color: "white", fontSize: "1rem" }}>
                                                                    <th scope="col">Clave</th>
                                                                    <th scope="col">Cantidad</th>
                                                                    <th scope="col">Precio</th>
                                                                    <th scope="col">Precio con IVA</th>
                                                                    <th scope="col">Precio total</th>
                                                                    <th scope="col">Precio total con IVA</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody style={{ textAlign: "-webkit-center" }}>
                                                                <tr>
                                                                    <td style={{ fontSize: "1rem" }}> {item.ItemCode} </td>
                                                                    <td style={{ fontSize: "1rem" }}> {Number(item.Quantity).tofixed(4)} </td>
                                                                    <td style={{ fontSize: "1rem" }}>
                                                                        <CurrencyFormat
                                                                            value={item.Price}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            prefix={'$ '}
                                                                            suffix={config.general.currency}
                                                                        >
                                                                        </CurrencyFormat>
                                                                    </td>
                                                                    <td style={{ fontSize: "1rem" }}>
                                                                        <CurrencyFormat
                                                                            value={item.Price + (item.Price * .16)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            prefix={'$ '}
                                                                            suffix={config.general.currency}
                                                                        >
                                                                        </CurrencyFormat>
                                                                    </td>
                                                                    <td style={{ fontSize: "1rem" }}>
                                                                        <CurrencyFormat
                                                                            value={item.LineTotal}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            prefix={'$ '}
                                                                            suffix={config.general.currency}
                                                                        >
                                                                        </CurrencyFormat>
                                                                    </td>
                                                                    <td style={{ fontSize: "1rem" }}>
                                                                        <CurrencyFormat
                                                                            value={item.LineTotal + (item.LineTotal * .16)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            prefix={'$ '}
                                                                            suffix={config.general.currency}
                                                                        >
                                                                        </CurrencyFormat>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                // <div key={item.ItemCode} className="row">
                                //     <div className="col-md-4">
                                //         <img className="img-fluid" style={{ backgroundColor: 'white', maxHeight: 130 }}
                                //             src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}
                                //             alt=""
                                //         />
                                //     </div>
                                //     <div className="col-md-8" style={{alignSelf:'center'}}>
                                //         <div className="container p-0" style={{fontSize:'1rem'}}>
                                //             <div className="row text-left">
                                //                 <div className="col-md-12"  >
                                //                     {item.ItemName}
                                //                 </div>
                                //             </div>
                                //             <div className="row font-weight-bold ">
                                //                 <div className="col-md-3">
                                //                     Clave
                                //                 </div>
                                //                 <div className="col-md-3">
                                //                     Cantidad
                                //                 </div>
                                //                 <div className="col-md-3">
                                //                     Precio
                                //                 </div>
                                //                 <div className="col-md-3">
                                //                     Precio total
                                //                 </div>
                                //             </div>
                                //             <div className="row">
                                //                 <div className="col-md-3">
                                //                     {item.ItemCode}
                                //                 </div>
                                //                 <div className="col-md-3">
                                //                     {parseInt(item.Quantity)}
                                //                 </div>
                                //                 <div className="col-md-3">
                                //                     <CurrencyFormat 
                                //                         value={item.Price} 
                                //                         displayType={'text'} 
                                //                         thousandSeparator={true} 
                                //                         fixedDecimalScale={true} 
                                //                         decimalScale={2} 
                                //                         prefix={'$ '}
                                //                         suffix={config.general.currency}
                                //                         >
                                //                     </CurrencyFormat>
                                //                 </div>
                                //                 <div className="col-md-3">
                                //                     <CurrencyFormat 
                                //                         value={item.LineTotal} 
                                //                         displayType={'text'} 
                                //                         thousandSeparator={true} 
                                //                         fixedDecimalScale={true} 
                                //                         decimalScale={2} 
                                //                         prefix={'$ '}
                                //                         suffix={config.general.currency}
                                //                         >
                                //                     </CurrencyFormat>
                                //                 </div>
                                //             </div>
                                //         </div>
                                //     </div>
                                // </div>
                            })}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn text-white" style={{ background: "#CE3529" }} onClick={() => this.addToShopingCart()}>
                                <i className="fas fa-cart-plus" />
                                &nbsp; Agregar al carrito
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemDetailsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PreliminarDetailModel);
