import React, { Component } from 'react';
import { connect } from "react-redux";
import $ from 'jquery';
import moment from "moment";
import './priceModel.css'
import { ApiClient } from "../libs/apiClient/ApiClient";


const apiClient = ApiClient.getInstance();
class NewDistributor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dataForm: {
                name: '',
                lastName: '',
                email: '',
                phone: '',
                address: '',
                message: ''
            },
        };
    }

    onChangePriceForm = (event, field) => {
        let obj = this.state.dataForm
        obj[field] = event.target.value

        this.setState({
            dataForm: obj
        })
    }

    clearForm() {
        this.setState({
            dataForm: {
                name: '',
                lastName: '',
                email: '',
                phone: '',
                address: '',
                message: ''
            },
        })
    }

    validate() {
        const { notificationReducer: { showAlert } } = this.props
        const { dataForm } = this.state
        const alerts = ['Nombre', 'Apellido', 'email', 'Teléfono', 'Direccón', 'Mensaje']
        let index = 0
        for (const key in dataForm) {
            if (dataForm[key] == "") {
                showAlert({ type: 'warning', message: `El campo ${alerts[index]} está vació, por favor ingrese un ${alerts[index]}`, timeOut: 8000 });
                return
            }
            index += 1
        }

        if ((dataForm.name).length < 3) {
            showAlert({ type: 'warning', message: 'El nombre debe tener de 3 a 100 caracteres.', timeOut: 8000 });
            return
        }
        if (!(/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/).test(dataForm.email)) {
            showAlert({ type: 'warning', message: 'El email no es valido', timeOut: 8000 });
            return
        }
        return true
    }

    async sendEmail() {
        const { notificationReducer: { showAlert } } = this.props;
        const { dataForm } = this.state
        let apiResponse = await apiClient.sendQuotation(dataForm);
        if (apiResponse.status === 1) {
            showAlert(
                {
                    type: 'success',
                    message: apiResponse.message,
                    timeOut: 8000
                }
            );
            this.clearForm()
            const close = document.getElementById('closePrice');
            if (close) {
                close.click();
            }
        }
    }

    render() {
        const { dataForm, onChangePriceForm } = this.props
        return (
            <div className="modal fade p-4" id="pricemodel" role="dialog" aria-labelledby="quotationModalLabel" aria-hidden="true" tabindex="-1">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content" style={{ borderRadius: "10px" }}>
                        <div className="price-modal-header">
                            <h5 className="modal-title" id="quotationModalLabel">NUEVA COTIZACIÓN</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id='closePrice'>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <hr />
                        </div>
                        <div className="price-modal-body">
                            <div className='top-text'>
                                <p style={{ fontSize: "25px" }}>
                                    Ponte en contacto, agrega los datos de los artículos a cotizar.
                                </p>
                            </div>
                            <div className='price-form'>
                                <div className='form-inputs-container'>
                                    <div class="form-input-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Nombre:"
                                            value={this.state.dataForm.name}
                                            onChange={(event) => ((/^[A-Z a-z]+$/).test(event.target.value) || event.target.value == '') ? this.onChangePriceForm(event, 'name') : ''}
                                        />
                                    </div>
                                    <div class="form-input-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Apellido"
                                            value={this.state.dataForm.lastName}
                                            maxLength={100}
                                            onChange={(event) => ((/^[A-Z a-z]+$/).test(event.target.value) || event.target.value == '') ? this.onChangePriceForm(event, 'lastName') : ""}
                                        />
                                    </div>
                                </div>
                                <div className='form-inputs-container'>
                                    <div class="form-input-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="E-mail"
                                            value={this.state.dataForm.email}
                                            onChange={(event) => this.onChangePriceForm(event, 'email')}
                                        />
                                    </div>
                                    <div class="form-input-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Teléfono"
                                            value={this.state.dataForm.phone}
                                            maxLength={15}
                                            onChange={(event) => ((/^[0-9]+$/).test(event.target.value) || event.target.value == '') ? this.onChangePriceForm(event, 'phone') : ""}
                                        />
                                    </div>
                                </div>
                                <div className='form-inputs-container'>
                                    <div class="form-input-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Dirección"
                                            value={this.state.dataForm.address}
                                            maxLength={100}
                                            onChange={(event) => this.onChangePriceForm(event, 'address')}
                                        />
                                    </div>
                                </div>
                                <div className='form-message-container'>
                                    <div class="form-input-group">
                                        <textarea
                                            type="text"
                                            class="form-control"
                                            placeholder="Escriba su mensaje aquí:"
                                            value={this.state.dataForm.message}
                                            maxLength={250}
                                            onChange={(event) => this.onChangePriceForm(event, 'message')}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="price-modal-footer">
                            <button type="button" onClick={() => {
                                if (this.validate()) {
                                    this.sendEmail()
                                }
                            }}>Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
        itemsReducer: store.ItemsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewDistributor);