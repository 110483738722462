import React, { Component } from 'react';
import { config, DISPATCH_ID, SERVICE_API } from '../libs/utils/Const';
import { connect } from "react-redux";
import packageJson from '../../package.json';
import './Footer.css'
import { PreguntasModal } from "./index";
import $ from "jquery";
global.appVersion = packageJson.version;

class Footer extends Component {
    getYear = () => {
        let today = new Date();
        return today.getFullYear() || '';
    };

    render() {
        const { configReducer: { history } } = this.props;
        return (
            <footer className="page-footer font-small pt-1" style={{ backgroundColor: config.footer.backgroundColor }}>
                <div className="text-center text-md-left">
                    <div className="row" id='footer-container'style={{ maxWidth: '100%' }}>
                        {/* Logo de FMB */}
                        <div className="col-md-3 text-center p-0">
                            <div className='desk-just'>
                            <a href=" " target="_blank">
                                <img src={config.footer.icon} className="img-fluid" width='40%'/>
                            </a>
                            <p>
                                {config.footer.socialMedia2.map((red, index) => {
                                    return (red.visibility &&
                                        <a class="btn btn-floating m-1" href={red.linkref} target="_black" key={index}>
                                            <img className="img-fluid" width='25px' src={red.icon} />
                                        </a>
                                    )
                                })}
                            </p>
                            <a href="http://fmbsolutions.mx/" target="_blank">
                                <img src={config.footer.power} className="img-fluid" width='120px' style={{ marginRight: '0rem',maxWidth: '25%',marginTop: '23px', minWidth: '95px'}}/>
                            </a>
                        </div>
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        {/* Información de la empresa */}
                        <div className="col-md-2 p-0">
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{ color: 'white' }}>Empresa</h5>
                            {/* <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goAboutUs()}> Nosotros </span>
                                </a>
                            </p> */}
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goQuestions()}> Preguntas frecuentes</span>
                                </a>
                            </p>
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goContactUs()}> Contacto </span>
                                </a>
                            </p>
                            {config.modules.BolsaTrabajo &&
                                <p>
                                    <a>
                                        <span style={{ color: config.footer.textColor }} onClick={() => history.goJob()}> Bolsa de trabajo </span>
                                    </a>
                                </p>
                            }
                            {config.modules.Devoluciones &&
                                <p>
                                    <a>
                                        <span style={{ color: config.footer.textColor }} onClick={() => history.goDevolution()}> Devoluciones </span>
                                    </a>
                                </p>
                            }
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goNewsBlog()}> Blog de noticias </span>
                                </a>
                            </p>
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goAboutRedZone()}> Cliente preferente </span>
                                </a>
                            </p>
                            {/* <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goGetPlantilla()}> Descargar plantilla </span>
                                </a>
                            </p> */}
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goAboutUs()}> ¿Quienes somos? </span>
                                </a>
                            </p>
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goJob()}> Bolsa de trabajo </span>
                                </a>
                            </p>

                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        {/* Logo de la empresa */}
                        <div className="col-md-4 text-center p-0 desk-none">
                            <a href=" " target="_blank">
                                <img src={config.footer.icon} className="img-fluid" width='120px' />
                            </a>
                            <p>
                                {config.footer.socialMedia2.map((red, index) => {
                                    return (red.visibility &&
                                        <a class="btn btn-floating m-1" href={red.linkref} target="_black" key={index}>
                                            <img className="img-fluid" width='25px' src={red.icon} />
                                        </a>
                                    )
                                })}
                            </p>
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        {/* Información legal */}
                        <div className="col-md-2 p-0">
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{ color: 'white' }}>Legales</h5>
                            {config.pdf.VERterminosCondiciones &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.terminosCondiciones}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Términos y condiciones </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasVentas &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasEnviosGarantias}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Políticas de envíos y garantías </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasGarantia &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasGarantia}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}> Políticas de garantía </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasUsoAvisoPrivacidad &&
                                <p>
                                    <a onClick={() => history.goPrivacy()}>
                                        <span style={{ color: config.footer.textColor }} > Políticas de uso y aviso de privacidad </span>
                                    </a>
                                </p>
                            }
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                        {/* Ubicaccion de la empresa */}
                        <div className="col-md-3 p-0 contact-footer-container">
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{ color: 'white' }}>Contacto</h5>
                            <p>
                                <a>
                                    <img className="img-fluid" width='5%' height='5%' src={config.footer.info.hoursIcon} style={{ color: config.footer.iconColor, padding: 2 }}></img>&nbsp;
                                    {
                                        (config.footer.info.hours).split(',').map(horario => (
                                            <span style={{ color: config.footer.textColor }}>{horario}<br/></span>
                                        ))
                                    }

                                </a>
                            </p>
                            <p>
                                <a>
                                    <img className="img-fluid" width='5%' height='5%' src={config.footer.info.phoneIcon} style={{ color: config.footer.iconColor, padding: 2 }}></img>&nbsp;
                                    <span style={{ color: config.footer.textColor }}>{config.footer.info.phone}</span>
                                </a>
                            </p>
                            <p>
                                <a>
                                    <img className="img-fluid" width='5%' height='5%' src={config.footer.info.emailIcon} style={{ color: config.footer.iconColor, padding: 2 }}></img>&nbsp;
                                    <span onClick={() => $('#ClarificationModal').modal('show')} style={{ color: config.footer.textColor }}>{config.footer.info.email}</span>
                                </a>
                            </p>
                            {/* <p>
                                <a href={"mailto:" + config.footer.info.email}>
                                    <img className="img-fluid" width='5%' height='5%' src={config.footer.info.emailIcon} style={{ color: config.footer.iconColor, padding: 2 }}></img>&nbsp;
                                    <span style={{ color: config.footer.textColor }}>{config.footer.info.email2}</span>
                                </a>
                            </p> */}
                        </div>
                        <hr className="clearfix w-100 d-md-none" />
                    </div>
                </div>
                {/* Inofrmacion de la version */}
                <div className="footer-copyright text-center py-4">
                    <div className='row' style={{ maxWidth: '100%' }}>
                        <div></div>
                        <div className='text-center col-10'>
                        <a href="http://fmbsolutions.mx/" target="_blank">
                                <img src={config.footer.power} className="img-fluid icon-responsive" width='120px' style={{ marginRight: '0rem',maxWidth: '25%',marginTop: '23px', minWidth: '95px'}}/>
                            </a>
                            <a href="http://fmbsolutions.mx/" target="_blank">
                                <span style={{ color: config.footer.textColor }}>Todos los derechos reservados FMB Solutions S.A de C.V © {this.getYear()} &middot; Versión &middot; {global.appVersion}</span>
                            </a>
                        </div>
                            <div>
                            {/* <a href="https://donweb.com/es-mx/certificados-ssl" id="comodoTL" title="Certificados SSL México">
                                <img className="img-fluid" style={{maxHeight: 25}} src="https://micuenta.donweb.com/img/sectigo_positive_lg.png" alt="Imagen del producto"/>
                            </a> */}
                        </div> 
                    </div>
                </div>
                <PreguntasModal title={'Contáctanos ante cualquier Duda o Pregunta!'} />
            </footer>
            // <div className="footer col-md-12" 
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);
