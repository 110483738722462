import { DISPATCH_ID } from '../../libs/utils/Const';

export const ShoppingCartReducer = (state = { items: [],  backOrder: [], shoppingCartId: null, itemsGift: []}, action) => {

    switch (action.type) {
        case DISPATCH_ID.SHOPPING_CART_SAVE_CART:
            return Object.assign({}, state, { items: action.value });
        case DISPATCH_ID.SHOPPING_CART_SAVE_BACK_ORDER:
            return Object.assign({}, state, { backOrder: action.value });
            case DISPATCH_ID.SHOPPING_CART_ITEMS_GIFT:
            return Object.assign({}, state, { itemsGift: action.value });
        default:
            return state ;
    }
    
};
