import React, { Component } from 'react';
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID} from '../libs/utils/Const';
import {connect} from "react-redux";
import CurrencyFormat from 'react-currency-format';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ItemSlider.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import moment from 'moment';
import { nodeName } from 'jquery';
import 'semantic-ui-css/semantic.min.css'
import { Header, Button, Popup, Label  } from 'semantic-ui-react'

// definir las variables responsivas para el carrusel
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1920 },
        items: 6,
        partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
    },
    desktop2: {
        breakpoint: { max: 1920, min: 1200 },
        items: 5,
        partialVisibilityGutter: 0 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1200, min: 992 },
      items: 4,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet1: {
      breakpoint: { max: 992, min: 767 },
      items: 3,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet2: {
        breakpoint: { max: 767, min: 464 },
        items: 2,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
      },
    mobile: {
      breakpoint: { max: 463, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
}

class ItemSlider2 extends Component {

    constructor(props){
        super(props);
        this.state = {
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
        };
        this.mouseOverAddCart = this.mouseOverAddCart.bind(this);
        this.mouseOutAddCart = this.mouseOutAddCart.bind(this);
        this.iconMouse = [];     
        this.iconMouseOver = []; 
    }

    changeQuantity = (index, item, event) =>{
        const {changeQuantity, notificationReducer: {showAlert},itemsReducer: {items2: itemsSearch}} = this.props; 
         let newQuantity;
        itemsSearch.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                newQuantity = itemFilter.quantity || '1';
                newQuantity = newQuantity > Number(item.OnHandPrincipal) ? Number(item.OnHandPrincipal) : newQuantity;
            }
        });
        
        // let onHand = item.OnHand;   
        // if(Number(newQuantity) > Number(onHand)) {
        //     newQuantity = onHand;
        // }   
        // if(!newQuantity){
        //     changeQuantity(index,item, newQuantity, false); //delete
        // }else{
            changeQuantity(index,item, newQuantity, true); // add
        //     showAlert({type: 'warning', message: "Se excede el número de articulos disponibles de este producto", timeOut: 2500});
        // }        
    };

    directShopping = async (index, item, event) => {
        const { configReducer,changeQuantity, notificationReducer: { showAlert }, itemsReducer: { items1: itemsSearch } } = this.props;
        let newQuantity;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                newQuantity = itemFilter.quantity || '1';
                newQuantity = newQuantity > Number(item.OnHandPrincipal) ? Number(item.OnHandPrincipal) : newQuantity;
            }
        });
        await changeQuantity(index, item, newQuantity, true); // add
  
        setTimeout(() => {
            configReducer.history.goShoppingCart();
        }, 1300);     
    };

    changLocalQuantity = (index, item, event)=>{
        const {itemsReducer: {items2: itemsSearch}} = this.props;
        let newQuantity =  event.nativeEvent.target.value;
        itemsSearch.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                itemFilter.quantity = newQuantity;
            }
        });
        this.applyFilters(itemsSearch);
    }

  

    // No aplica
    changeBackOrder= (item, addItem) => {
        const {itemsReducer : {deleteBackOrder, addBackOrder}} = this.props;
        if(addItem){
            addBackOrder({item, quantity: 1})
        }else{
            deleteBackOrder({item, deleteAll: false});
        }
    };
    
    applyFilters = data => {
        const {setItemsFilterSearch2} = this.props;
        setItemsFilterSearch2(data);
    };

    mouseOverAddCart = (index, flag, event)=>{
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "none";
            this.iconMouseOver[index].style.display = "block";
        }
    }

    mouseOutAddCart = (index, flag, event)=>{
        if(flag == 'green'){
            event.currentTarget.style.backgroundColor = "#CE3529";
            event.currentTarget.className = "btn btn-block botonAgregar"
        } else if(flag == 'yellow'){
            event.currentTarget.style.backgroundColor = "#efc964";
            event.currentTarget.className = "btn btn-block botonAgregarYellow"
        } else {
            event.currentTarget.style.backgroundColor = "#e27753";
            event.currentTarget.className = "btn btn-block botonAgregarAgotado"
        }
        // Íconos
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "block";
            this.iconMouseOver[index].style.display = "none";
        }
    }

    render() {
        const {itemsReducer : { items2, searchItemsFilter, updateFavorite, deleteShoppingCart, openItemDetails }, dashboard, changeBackOrder } = this.props;
        const { seller } = this.state;
        let valor = seller ? seller.U_FMB_Handel_Perfil : '0';
        let x = window.matchMedia("(max-width: 50px)") 
        return (
            <div>
                <div className="SliderItems">
                    <Carousel autoPlay partialVisible={false} responsive={responsive} autoPlaySpeed={5000} infinite ={true} removeArrowOnDeviceType={["tablet", "mobile","tablet1","tablet2"]} >
                        {items2 && items2.map((item, index) => {
                            item.ItemName = item.ItemName || '';
                            let dashboardForTwoSliders = dashboard ? dashboard : '';
                            let imagesArray = item.PicturName || '';
                            imagesArray = imagesArray.split('|');
                            let image = imagesArray[0] ? imagesArray[0] : item.PicturName; 
                            let imagenShow = image ? 
                            // require('../images/standard/NwSerdi/GENERICA.png')
                            (config.BASE_URL + SERVICE_API.getImage + '/' + image)
                            : require('../images/noImage.png');

                            return (
                                <div id="ItemsSlider" style={{ display:"flex", justifyContent:"center", maxWidth:"18rem",maxHeight:"100%", textAlign:"center",position:"relative",paddingRight:"1rem",paddingLeft:"1rem", paddingBottom:'1rem'}} key={index}>
                                {/* Se le agregan un minimo de altura, un acho fijo y un padding */}
                                    <div className="item card"  style={{ position:"relative",width: "380px", marginTop:"4px", minHeight:"364px", padding:"1rem", backgroundColor: config.itemsList.backgroundColor }}>
                                        <div className="imageCard">
                                            <div style={{display: (!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 ? 'none' : 'table')}}>
                                                <div className="font-weight-bold ribbon" styles={{background: config.navBar.primaryColor,position: "absolute", }} style={{top:"-3px",left:"-5px"}} onClick={() => openItemDetails(item)}>
                                                    <span>
                                                        <blink>PROMOCIÓN</blink>
                                                    </span>
                                                </div>
                                            </div>
                                            {/* <div style={{display: (!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 ? 'none' : 'table')}}> */}
                                            <div className="font-weight-bold ribbonNew" styles={{ position: "absolute" }}  onClick={() => openItemDetails(item)}>
                                                    <span>
                                                        <blink>NUEVO</blink>
                                                    </span>
                                                </div>
                                            {/* </div> */}
                                            
                                            <img
                                                onClick={() => openItemDetails(item)}
                                                className="card-img-top cardImg"
                                                src={imagenShow}
                                                alt="Imagen del articulo"
                                            />
                                            
                                            <div className="card-body" style={{height: "fit-content",margin: 0, padding: "2px",minHeight:"180px", color: config.itemsList.textColor}}>
                                                <div>
                                                     {/* CAmbio en le tamaño de fuente del precio */}
                                                     <p className="card-subtitle text-left" style={{ /*fontSize: 36*/ fontSize:15, color: config.itemsList.textPrice2 }}>
                                                        {item.U_web === 0 || item.U_web === null
                                                            ? "Solicite su cotización"
                                                            
                                                            : (!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 || item.DiscountPercentSpecial == 0 ? 
                                                                <CurrencyFormat
                                                                    value={item.PriceTaxAfterDisc}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={'$'}>
                                                                </CurrencyFormat>
                                                                : <>
                                                                    <div style={{textAlign:'right'}}>
                                                                        <span className="card-subtitle text-left precioPromocion" style={{fontSize:12, marginRight:10 }} >
                                                                            <CurrencyFormat
                                                                                value={item.PriceTaxBeforeDisc}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={'$'}>
                                                                            </CurrencyFormat>
                                                                        </span>
                                                                        { Number(item.DiscountPercentSpecial) !== 0 && 
                                                                                <span style={{fontSize:10 }} className = "text-danger">{parseFloat(item.DiscountPercentSpecial).toFixed(2)}% OFF</span>
                                                                        }
                                                                    </div>
                                                                    <span className="card-subtitle text-left  precioPromocionItemList">
                                                                        <div className="pricePromoItemSlider" style = {{fontSize: 15}}>
                                                                            <CurrencyFormat
                                                                                value={item.PriceTaxAfterDisc}
                                                                                displayType={'text'}
                                                                                thousandSeparator={true}
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix={'$'}>
                                                                            </CurrencyFormat>
                                                                        </div>
                                                                    </span>
                                                                </>
                                                            )
                                                        }
                                                        {item.U_web === 1 &&
                                                            " " //+ item.currency
                                                        }
                                                    </p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className="text-left" style={{marginLeft: -4,fontSize: 11, paddingBottom: 0, marginBottom: 0,}}>
                                                            <span style={{color:"#AFAFAF"}}>{'SKU '}</span>
                                                            <span className="font-weight-bold" style={{color: "#212E57"}}>{item.ItemCode}</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-3" style={{ padding: 0, margin: 0, }}>
                                                        <div onClick={() => updateFavorite(item)} style={{ padding: 0 }}>                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="overflow-auto ItemName">
                                                {/* Se elimino el margen izquierdo, maximo de la anchura y se le agrego el padding */}
                                                    <p className="card-title text-left" style={{ marginLeft: 1,/*maxWidth: "100%", */padding: 1,fontSize: 11, color:"#4e4e4e"}}>
                                                        {/* {item.ItemName ? (item.ItemName).length > 32 ? (item.ItemName).substring(0, 31) + " ... ": item.ItemName : " "} */}
                                                        {item.ItemName ? 
                                                            (item.ItemName).length > 32 ? 
                                                            <div>
                                                                <span >{(item.ItemName).substring(0, 31)}</span>
                                                                <Popup trigger={<Label className='ItemName' style ={{color: '#AFAFAF', backgroundColor: 'white', fontSize: 'small', display:!x.matches ? 'inline-block' : 'none', fontSize: 11, padding:'0px 0px 0px 1px'}}>ver más...</Label >} flowing hoverable position='top center'>
                                                                    {/* <Popup.Header> <div className="card-header text-white" style={{backgroundColor: 'red'}}>Lotes seleccionados</div></Popup.Header> */}
                                                                    <Popup.Content style={{width:150, overflow:'auto'}}>
                                                                        <span className='ItemName'>{item.ItemName}</span>
                                                                    </Popup.Content>
                                                                </Popup>
                                                            </div>
                                                            : item.ItemName 
                                                        : " "}
                                                    </p>



                                                </div>
                                                <div>
                                                    <p className=" card-title text-left" style={{fontSize:13, padding:1 /*marginLeft: 2,fontSize: 18, paddingBottom: 0, marginBottom: 0,*/}}>
                                                        <span style={{color:"#AFAFAF"}}>Stock: </span>
                                                        <span className="font-weight-bold" style={{color: "#212E57"}}>{item.OnHandPrincipal > 0 ? Number(item.OnHandPrincipal).toFixed(2) : 'Sin stock'}</span>
                                                    </p>
                                                </div>
                                                <div style={{ marginTop:'10px' }}>
                                                {item.OnHandPrincipal <= 0 && (
                                                    <div className='m-1 shake-top' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent:'center' }}>
                                                        <button
                                                            style={{ display: 'flex', borderRadius: '50px', border: '1px solid #ccc' }}
                                                            type="button"
                                                            className="btn rounded-pill pl-3 pr-3"
                                                            onClick={() => { window.open("https://api.whatsapp.com/send?phone=526671180507", '_blank') }}
                                                        >
                                                            <img src={config.icons.iconoW} alt="WhatsApp" style={{ marginRight: '5px', maxWidth: '40px', maxHeight: '30px', marginTop:'1px'}} />
                                                            <span style={{ fontSize: '12px' }}>Escríbenos por WhatsApp</span>
                                                        </button>
                                                    </div>
                                                )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Se comento el border top que tenia se le agrgo un padding de 0 se agrego un border   */}
                                        
                                        <div className="card-footer" style={{padding: 0, border:'none'}}>
                                            {/* Icono de entrega solo en tienda */}
                                            <div className={item.U_FMB_Handel_Ent_Tienda==='1' && item.OnHandPrincipal > 0 ? "row text-center just-store-icon":"hide"}>
                                                <img src={config.icons.justStoreIcon} style={{ maxWidth: '100%' }} />
                                            </div>
                                            <div className="row m-1 p-0">
                                            {item.U_web === 0 || item.U_web === null ?
                                                    <div className="col-12" style={{padding:0}}>
                                                        {item.OnHandPrincipal > 0 && ( // Verifica si hay stock disponible
                                                            <label style={{textAlign: "center", fontSize: 13}}>Llámenos o envíe un correo para cotización</label>
                                                        )}
                                                    </div> :
                                                    (item.OnHandPrincipal > 0 && ( // Verifica si hay stock disponible
                                                        <div className="col-3 cantidad p-0">
                                                            {item.Unidades === "Unidades" ? (
                                                                <input
                                                                 // disabled={!(!!item.OnHandPrincipal)}
                                                                id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                type="number"
                                                                min="1"
                                                                max={Number(item.OnHandPrincipal)}
                                                                value={item.quantity ? Number(item.quantity).toFixed(0) : ''}
                                                                className="form-control p-0 text-center"
                                                                name={'quantity' + item.ItemCode}
                                                                placeholder="1"
                                                                onChange={(event) => {this.changLocalQuantity(dashboard.toString() + index, item, event)}}
                                                                //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                            />
                                                            ) : (
                                                                <input
                                                                // disabled={!(!!item.OnHandPrincipal)}
                                                                id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                type="number"
                                                                min="1"
                                                                max={Number(item.OnHandPrincipal)}
                                                                value={item.quantity ? item.quantity : ''}
                                                                className="form-control p-0 text-center"
                                                                name={'quantity' + item.ItemCode}
                                                                placeholder="1"
                                                                onChange={(event) => {this.changLocalQuantity(dashboard.toString() + index, item, event)}}
                                                                //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                            />
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                                {item.U_web === 0 || item.U_web === null || item.OnHandPrincipal <= 0 ? (
                                                    <div><label style={{textAlign: "center", fontSize: 16}}></label></div>
                                                ) : (
                                                    <div className="ml-2">
                                                        <div>
                                                        <button
                                                            type="button" 
                                                            readOnly = {item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false}
                                                            className= {item.flag === 'green' ? "btn btn-warning rounded-lg pl-3 pr-3": "btn btn-block botonAgregarAgotado rounded-lg pl-3 pr-3"}  
                                                            // className= {item.OnHandPrincipal === 0 ? "btn btn-block botonAgregarAgotado" : item.flag === 'green' ? "btn btn-block botonAgregar": "btn btn-block botonAgregarYellow"}  
                                                            value={(item.quantity ? Number(item.quantity): 1)} 
                                                            onClick={(event)=>{this.changeQuantity(index, item, event)}} 
                                                            // onMouseOver={(event)=>{this.mouseOverAddCart(dashboardForTwoSliders.toString() +index, item.flag, event)}} 
                                                            // onMouseOut={(event)=>{this.mouseOutAddCart(dashboardForTwoSliders.toString() +index, item.flag, event)}}
                                                        >
                                                            Agregar
                                                        </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {item.U_web !== 0 && item.U_web !== null && item.OnHandPrincipal > 0 && (
                                                    <div className='m-1' style={{cursor: 'pointer'}} value={(item.quantity ? Number(item.quantity): 1)} onClick={(event) => this.directShopping(index, item, event)}>
                                                        <img title="Comprar ahora" src={config.navBar.carrito_naranja} alt='Ubicación' width="30" height="30"/>
                                                    </div>
                                                )}
                                                {/* {(  (item.U_web !== 0 && item.U_web !== null) 
                                                    && (item.OnHandPrincipal <= 0 || item.OnHandPrincipal == ''))
                                                    &&
                                                        <div className="icoCarrito col-3 text-center align-middle" style={{fontSize: 33,padding:0, textAlign: "right",alignSelf:"center"}}>
                                                            <img 
                                                                // ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() +index] = iconMouse} 
                                                                src={config.shoppingCartIcons.carritoAgotado} 
                                                                style={{display: "block", color: "red", cursor: "pointer"}}
                                                            />
                                                            <img 
                                                                // ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() +index] = iconMouseOver} 
                                                                src={config.shoppingCartIcons.camionAgotado} 
                                                                style={{display: "none", color: "red", cursor: "pointer"}}
                                                            />
                                                        </div>
                                                } */}

                                                {/* {((item.U_web !== 0 && item.U_web !== null) 
                                                    && (item.flag === 'green' || item.flag === 'yellow')) 
                                                    // && item.OnHandPrincipal > 0 
                                                    && <div className="icoCarrito col-3 text-center align-middle" style={{fontSize: 33,padding:0, textAlign: "right",alignSelf:"center"}}>
                                                            <img 
                                                                // ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() +index] = iconMouse} 
                                                                src={item.flag === 'green' ? config.shoppingCartIcons.carritoVerde : config.shoppingCartIcons.carritoAmarillo} 
                                                                style={{display: "block", cursor: "pointer"}}
                                                                readOnly={item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false}
                                                                value={(item.quantity ? Number(item.quantity) : 1)}
                                                                onClick={(event) => { this.directShopping(index, item, event) }}
                                                                onMouseOver={(event)=>{this.mouseOverAddCart(dashboardForTwoSliders.toString() +index, item.flag, event)}} 
                                                                onMouseOut={(event)=>{this.mouseOutAddCart(dashboardForTwoSliders.toString() +index, item.flag, event)}}/>
                                                            <img 
                                                                // ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() +index] = iconMouseOver} 
                                                                src={item.flag === 'green' ? config.shoppingCartIcons.camionVerde : config.shoppingCartIcons.camionAmarillo} 
                                                                style={{display: "none", cursor: "pointer"}}
                                                                readOnly={item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false}
                                                                value={(item.quantity ? Number(item.quantity) : 1)}
                                                                onClick={(event) => { this.directShopping(index, item, event) }}
                                                                onMouseOver={(event)=>{this.mouseOverAddCart(dashboardForTwoSliders.toString() +index, item.flag, event)}} 
                                                                onMouseOut={(event)=>{this.mouseOutAddCart(dashboardForTwoSliders.toString() +index, item.flag, event)}}
                                                            />
                                                        </div>
                                                } */}
                                            </div>
                                            <div className="row text-center" style={{ margin: 0, padding: 0 }}>
                                                {item.U_web === 0 || item.U_web === null 
                                                    ? item.wishlist === 1 &&
                                                        <div className="col-12" style={{ padding: 0 }}>
                                                            <label style={{ textAlign: "center", fontSize: 16 }}></label>
                                                        </div>
                                                    : item.wishlist === 1 &&
                                                        <div className="col-10">
                                                            <label style={{ textAlign: "center", marginTop: 12, fontSize: 14 }}>Lista de deseos</label>
                                                        </div>
                                                }
                                                {item.U_web === 1 &&
                                                    item.wishlist === 1 &&
                                                        <div className="col-2" style={{ color: config.navBar.textColor2, fontSize: 20, padding: 0 }}>
                                                            {item.backOrder
                                                                ? <i className={config.icons.backOrderFalse} style={{ cursor: "pointer", marginLeft: 7, marginTop: 15 }} onClick={() => changeBackOrder(item, false)} />
                                                                : <i className={config.icons.backOrderTrue} style={{ cursor: "pointer", marginLeft: 7, marginTop: 15 }} onClick={() => changeBackOrder(item, true)} />
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
        configReducer: store.ConfigReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: value => dispatch({type: DISPATCH_ID.LOGIN_SET_USER, value}),
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsFilterSearch2: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER2, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemSlider2);