import React, { Component } from 'react';
import { config, SERVICE_API, DISPATCH_ID } from '../libs/utils/Const';
import { connect } from "react-redux";
import { Pagination, SideBarItems } from "./index";
import CurrencyFormat from 'react-currency-format';
import { animateScroll as scroll } from 'react-scroll';
import { Popup, Label } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import "./ItemSlider.css";

class ItemsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: null,
            card: true,
            list: false,
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
        };
        this.mouseOverAddCart = this.mouseOverAddCart.bind(this);
        this.mouseOutAddCart = this.mouseOutAddCart.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.iconMouse = [];
        this.iconMouseOver = [];
    }

    componentDidMount = () => {
        this.scrollToBottom();
    }

    scrollToBottom() {
        scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: 'easeOutQuart',
            isDynamic: true
        })
    }

    barraSide = () => {
        const linkFiltro = document.querySelectorAll(".linkFilter");
        const btnToggle = document.querySelector(".toggle-btn");

        btnToggle.addEventListener("click", (function () {
            document.getElementById("sidebar").classList.toggle("active");
            document.querySelector(".toggle-btn").classList.toggle("active");
        }()));

        linkFiltro.forEach((el) => el.addEventListener("click", function () {
            document.getElementById("sidebar").classList.remove("active");
            document.querySelector(".toggle-btn").classList.remove("active");
        }));
    }

    changeQuantity = (index, item, event) => {
        const { changeQuantity, notificationReducer: { showAlert }, itemsReducer: { items: itemsSearch } } = this.props;

        let newQuantity;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                newQuantity = itemFilter.quantity || '1';
                newQuantity = newQuantity > Number(item.OnHandPrincipal) ? Number(item.OnHandPrincipal) : newQuantity;
            }
        });

        let onHand = item.OnHandPrincipal;

        changeQuantity(index, item, newQuantity, true);
    };

    directShopping = async (index, item, event) => {
        const { goCart,configReducer :{history},changeQuantity, notificationReducer: { showAlert }, itemsReducer: { items: itemsSearch } } = this.props;
        let newQuantity;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                newQuantity = itemFilter.quantity || '1';
                newQuantity = newQuantity > Number(item.OnHandPrincipal) ? Number(item.OnHandPrincipal) : newQuantity;
            }
        });
        await changeQuantity(index, item, newQuantity, true); // add
  
        await goCart()  
    };

    changLocalQuantity = (index, item, event) => {
        const { shoppingCartReducer: { items }, itemsReducer: { items: itemsSearch } } = this.props;
        let newQuantity = event.nativeEvent.target.value;

        if (newQuantity % 1 == 0) {
            itemsSearch.map(itemFilter => {
                if (itemFilter.ItemCode === item.ItemCode) {
                    itemFilter.quantity = newQuantity;
                }
            });
            this.applyFilters(itemsSearch);
        } else {
            return;
        }
    }

    applyFilters = data => {
        const { setItemsFilterSearch } = this.props;
        setItemsFilterSearch(data);
    };

    mouseOverAddCart = (index, flag, event) => {
        if (this.iconMouse && this.iconMouse.length > 0) {
            this.iconMouse[index].style.display = "none";
            this.iconMouseOver[index].style.display = "block";
        }
    }

    mouseOutAddCart = (index, flag, event) => {
        if (flag == 'green') {
            event.currentTarget.style.backgroundColor = "#CE3529";
            event.currentTarget.className = "btn btn-block botonAgregar"
        } else if (flag == 'yellow') {
            event.currentTarget.style.backgroundColor = "#efc964";
            event.currentTarget.className = "btn btn-block botonAgregarYellow"
        }
        if (this.iconMouse && this.iconMouse.length > 0) {
            this.iconMouse[index].style.display = "block";
            this.iconMouseOver[index].style.display = "none";
        }
    }

    changeFilter = (type) => {
        this.setState({
            card: type === 'card' ? true : false,
            list: type === 'list' ? true : false
        });
    }

    searchPage = async (page = 0) => {
        const { setNextPage, itemsReducer: { searchByCategories, searchByKey, category, idCategory, location }, items } = this.props;
        page = page === 1 ? 0 : ((page - 1) * 30);
        this.scrollToBottom();
        if (location === 'menuCategories') {
            setNextPage(page);
            await searchByCategories(idCategory, page);
        } else if (location === 'navBar') {
            await searchByKey(page);
        } else if (location === 'marcaOne') {
            await searchByKey(page, 'marcaOne');
        } else if (location === 'marcaTwo') {
            await searchByKey(page, 'marcaTwo');
        } else if (location === 'marcaThree') {
            await searchByKey(page, 'marcaThree');
        } else if (location === 'marcaFour') {
            await searchByKey(page, 'marcaFour');
        } else if (location === 'marcaFive') {
            await searchByKey(page, 'marcaFive');
        } else if (location === 'promocion') {
            await searchByKey(page, 'promocion');
        } else if (location === 'masvendidos') {
            await searchByKey(page, 'masvendidos');
        } else if (location === 'nuevosproductos') {
            await searchByKey(page, 'nuevosproductos');
        } else if (location === 'remates') {
            await searchByKey(page, 'remates');
        } else if (location === 'vitrinaView') {
            await searchByKey(page, 'vitrinaView');
        }
    }

    render() {
        const { items, type, changeBackOrder, updateFavorite, deleteShoppingCart, openDetails, itemsReducer: { location, TotalPage, itemsCategories }, viewOne } = this.props;
        const { card, list, seller } = this.state;
        let isSeller = seller ? seller.U_FMB_Handel_Perfil : '0';
        let x = window.matchMedia("(max-width: 50px)")
        const storedPage = localStorage.getItem('currentPage');
        const currentPage = storedPage ? parseInt(storedPage) : 0;
        return (
            <div id="scrollDownPlease" >

                <div className="row justify-content-center">
                    <div className="col-md-10" style={{marginLeft:'45px'}}>
                        <div className="row justify-content-end">
                            <div className="col-md-auto bg-white p-2 justify-content-center text-center mr-md-2 mb-2">
                                <i onClick={() => { this.changeFilter('card') }} className={"fas fa-th-large mr-3"} style={{ color: card === true ? 'black' : 'grey', cursor: 'pointer' }} />
                                <i onClick={() => { this.changeFilter('list') }} className={"fas fa-th-list mr-3"} style={{ color: list === true ? 'black' : 'grey', cursor: 'pointer' }} />
                                <span className="font-weight-bold"> {TotalPage} Productos </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid row" style={{ margin: 0, display: "flex", justifyContent: "center", fontFamily: "Helvetica" }}>
                    {(!type || type !== 'RedCompensasView') &&
                        /* Lista categorias */
                        <div className="col-md-3 colmd" style={{ height: "auto", marginBottom: "-2.5rem", top: "-1rem" }}>
                            <div className="toggle-btn" onClick={() => { this.barraSide() }}>
                                <span className="fa fa-filter"> Filtros</span>
                            </div>

                            <div id="sidebar" style={{ OverflowY: "scroll", marginTop: "28px" }}>

                                <div style={{ padding: "1rem 1rem 1rem 1rem" }}>
                                    <SideBarItems totalRows={TotalPage} dataCategories={itemsCategories} viewOne={viewOne ? viewOne : ''} />
                                </div>
                            </div>
                        </div>
                    }
                    <div className={(!type || type !== 'RedCompensasView') ? "col-md-9 colmdd" : "col-md-12"}>
                        <div className='row'>
                            {items && items.map((item, index) => {
                                item.ItemName = item.ItemName || '';

                                let imagesArray = item.PicturName || '';
                                imagesArray = imagesArray.split('|');
                                let image = imagesArray[0] ? imagesArray[0] : item.PicturName;
                                let imagenShow = image ?
                                    // require('../images/standard/NwSerdi/GENERICA.png')
                                    (config.BASE_URL + SERVICE_API.getImage + '/' + image)
                                    : require('../images/noImage.png');

                                return (
                                    /*See agregaron pading de top y dee bottom*/
                                    <div
                                        id="CardItem"
                                        className={
                                            card === true
                                                ? "imageCard"
                                                : "row listCards justify-content-center align-items-center"
                                        }
                                        style={{
                                            margin: card === true ? 12 : "15px  15px  ",
                                            borderBottom: card === true ? "" : "1px solid #FFF",
                                            position: "relative",
                                            textAlign: "center"
                                        }}
                                        key={item.ItemCode + item.ItemName + item.Price}
                                    >
                                        <div
                                            className={
                                                card === true ? "item card" : "row justify-content-center align-items-center"
                                            }
                                            style={{
                                                width: card === true ? "279.41px" : "100%",
                                                minHeight: card === true ? "390px" : "100%",
                                                padding: card ? "1rem" : "0.5rem",
                                                borderRadius: card === true ? "" : "15px",
                                                border: card === true ? "" : "1px solid rgb(210, 210, 210)"
                                            }}
                                        >
                                            <div style={{
                                                display: (!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 ? 'none' : 'table'), position: "relative",
                                            }}>
                                                <div className="font-weight-bold ribbon" style={{ top: list === true ? "-72px" : "", left: list === true ? "-8px" : "" }} onClick={() => openDetails(item)}><span><blink> PROMOCIÓN </blink></span></div>
                                            </div>
                                            {item.U_FMB_Handel_Nuevo === '1' || item.U_FMB_Handel_Nuevo === 1 ? 
                                            <div className="font-weight-bold ribbonNew" styles={{ position: "absolute" }}  onClick={() => openDetails(item)}>
                                                    <span>
                                                        <blink>NUEVO</blink>
                                                    </span>
                                                </div>:<></>
                                            }
                                            
                                            <img
                                                onClick={() => openDetails(item)}
                                                className={card === true ? "card-img-top" : "card-img-top"}
                                                style={{
                                                    height: card === true ? "130px" : "100px",
                                                    width: card === true ? "130px" : "100px", // Ajusta el ancho deseado para el modo lista
                                                    backgroundColor: "transparent",
                                                    borderTopRightRadius: "10px",
                                                    cursor: "pointer",
                                                    marginRight: "auto",
                                                    marginLeft: "auto",
                                                    padding: 0,
                                                    objectFit: "cover",
                                                }}
                                                // src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}
                                                src={imagenShow}
                                                alt="Imagen del articulo"
                                            />
                                            <div className={card === true ? "col-12 " : " col-8 detailsItemList"} style={{/*paddingLeft: 10*/ }}>
                                                <div className="card-body" style={{ height: "fit-content", margin: 0, padding: "2px", minHeight: card === true ? "215px" : "auto", color: config.itemsList.textColor }}>
                                                    <div>
                                                        <p className="card-subtitle font-weight-bold text-left" style={{ fontSize: 15, color: config.itemsList.textPrice2 }}>
                                                            {item.U_web === 0 || item.U_web === null ?
                                                                <span style={{ fontSize: 17 }}>Solicite su cotización</span> :
                                                                (type || type === 'RedCompensasView') ?
                                                                    <>
                                                                        <CurrencyFormat
                                                                            value={Number(item.PriceAfterDiscount * 10).toFixed(0)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true} >
                                                                        </CurrencyFormat>
                                                                        {` puntos`}

                                                                    </>
                                                                    : (isSeller !== '0' || !item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo === 0 || item.DiscountPercentSpecial === 0 ?
                                                                        <CurrencyFormat
                                                                            value={item.PriceTaxBeforeDisc}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            prefix={"$"}>
                                                                        </CurrencyFormat>
                                                                        :
                                                                        <>

                                                                            {Number(item.DiscountPercentSpecial) !== 0  ?
                                                                            <div style={{ textAlign: 'right' }}>
                                                                                <span className="card-subtitle text-left precioPromocion" style={{ fontSize: 12, marginRight: 10 }} >
                                                                                    <CurrencyFormat
                                                                                        value={item.PriceTaxBeforeDisc}
                                                                                        displayType={'text'}
                                                                                        thousandSeparator={true}
                                                                                        fixedDecimalScale={true}
                                                                                        decimalScale={2}
                                                                                        prefix={'$'}>
                                                                                    </CurrencyFormat>
                                                                                </span>
                                                                                <div className="descuento">
                                                                                    (<span style={{ fontSize: 10 }} className="text-danger">{parseFloat(item.DiscountPercentSpecial).toFixed(2)}% OFF</span>)
                                                                                </div>
                                                                            </div>
                                                                        : <></>}

                                                                            <span className="precioCard">
                                                                                <div className=" precios">
                                                                                    <div className="pricePromo col-md-auto" style={{ padding: 0, fontSize: 15 }}>
                                                                                        <CurrencyFormat
                                                                                            value={item.PriceTaxAfterDisc}
                                                                                            displayType={'text'}
                                                                                            thousandSeparator={true}
                                                                                            fixedDecimalScale={true}
                                                                                            decimalScale={2}
                                                                                            prefix={'$'}>
                                                                                        </CurrencyFormat>
                                                                                    </div>
                                                                                </div>
                                                                            </span>
                                                                        </>
                                                                    )
                                                            }
                                                        </p>
                                                    </div>
                                                    <span className="nameItemCardList">
                                                        <div>
                                                            <div>
                                                                <p className="card-subtitle text-left" style={{ fontSize: 14, marginLeft: card === true ? 0 : 0 }}><span style={{ color: "#AFAFAF" }}>{'SKU'}</span> <span className="font-weight-bold nameItemList" style={{ color: config.loginView.secondaryColor }}>{item.ItemCode}</span></p>
                                                                {/* <h6 className="card-subtitle font-weight-bold text-left" style={{fontSize: 16, margin: 2, color: config.itemsList.textPrice}}>
                                                                {item.U_web === 0 || item.U_web === null ? 
                                                                    "Solicite su cotización" : 
                                                                    <CurrencyFormat 
                                                                        value={item.Price} 
                                                                        displayType={'text'} 
                                                                        thousandSeparator={true} 
                                                                        fixedDecimalScale={true} 
                                                                        decimalScale={2} 
                                                                        prefix={config.general.currency}>
                                                                    </CurrencyFormat>
                                                                }
                                                                {item.U_web === 1 && 
                                                                " más I.G.V."
                                                                }
                                                            </h6> */}
                                                            </div>
                                                            <div className="col-3" style={{ padding: 0, margin: 0, }}>
                                                                <div onClick={() => updateFavorite(item)} style={{ padding: 0 }}>
                                                                    {/*<i
                                                                    className={config.icons.favorite}
                                                                    aria-hidden="true"
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        padding: 0,
                                                                        margin: 0,
                                                                        marginTop: 9,
                                                                        fontSize: 30,
                                                                        zIndex: 10,
                                                                        color: item.favorite ? config.itemsList.iconColor : 'rgba(124, 124, 125, 0.5)',
                                                                    }}
                                                                />*/}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                    <div className="overflow-auto">
                                                        <p className="card-title text-left ItemName" style={{ padding: 1, fontSize: 13, color: "#4e4e4e" }}>
                                                            {/*item.ItemName.length > 43 ? item.ItemName.substring(0, 42 ) + "..." : item.ItemName*/}
                                                            {/* {item.ItemName ? (item.ItemName).length > 32 ? (item.ItemName).substring(0, 31) + " ... ": item.ItemName : " "} */}
                                                            {item.ItemName ?
                                                                (item.ItemName).length > 32 ?
                                                                    <div>
                                                                        <span >{(item.ItemName).substring(0, 31)}</span>
                                                                        <Popup trigger={<Label className='ItemName' style={{ color: '#AFAFAF', backgroundColor: 'white', fontSize: 'small', display: !x.matches ? 'inline-block' : 'none', fontSize: 11, padding: '0px 0px 0px 1px' }}>ver más...</Label >} flowing hoverable position='top center'>
                                                                            {/* <Popup.Header> <div className="card-header text-white" style={{backgroundColor: 'red'}}>Lotes seleccionados</div></Popup.Header> */}
                                                                            <Popup.Content className='ItemName' style={{ width: 150, overflow: 'auto' }}>
                                                                                <span>{item.ItemName}</span>
                                                                            </Popup.Content>
                                                                        </Popup>
                                                                    </div>
                                                                    : item.ItemName
                                                                : " "}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className=" card-title text-left" style={{ fontSize: 15, padding: 1 /*marginLeft: 2,fontSize: 18, paddingBottom: 0, marginBottom: 0,*/ }}>
                                                            <span style={{ color: "#AFAFAF" }}>Stock: </span>
                                                            <span className="font-weight-bold" style={{ color: config.loginView.secondaryColor }}>{item.OnHandPrincipal <= 0 ? 'Sin stock' : Number(item.OnHandPrincipal).toFixed(2)}</span>
                                                        </p>
                                                    </div>
                                                    <div style={{ marginTop: '10px' }}>
                                                        {item.OnHandPrincipal <= 0 && (
                                                            <div className='m-1 shake-top' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent:'center' }}>
                                                                <button
                                                                    style={{ display: 'flex', borderRadius: '50px', border: '1px solid #ccc' }}
                                                                    type="button"
                                                                    className="btn rounded-pill pl-3 pr-3"
                                                                    onClick={() => { window.open("https://api.whatsapp.com/send?phone=526671180507", '_blank') }}
                                                                >
                                                                    <img src={config.icons.iconoW} alt="WhatsApp" style={{ marginRight: '5px', maxWidth: '40px', maxHeight: '30px', marginTop: '1px' }} />
                                                                    <span style={{ fontSize: '12px' }}>Escríbenos por WhatsApp</span>
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="card-footer" style={{ border: 'none', backgroundColor: "#fff", paddingTop: "1rem", padding: '0px', position: 'relative', minHeight: card === true ? "43px" : "40px" }}>
                                                    {/* Icono de entrega solo en tienda */}
                                                    {item.U_FMB_Handel_Ent_Tienda === '1' && item.OnHandPrincipal > 0 && (
                                                        <div className="row text-center just-store-icon">
                                                            <img src={config.icons.justStoreIcon} style={{ maxWidth: '100%' }} />
                                                        </div>
                                                    )}
                                                    {
                                                    item.U_web === 0 || item.U_web === null ? (
                                                        <div className="ItemName" style={{
                                                        color: "#000000", fontSize: 11, margin: 0, backgroundColor:
                                                        "efefef"
                                                        }}>
                                                                {item.OnHandPrincipal > 0 && ( // Verifica si hay stock disponible
                                                                    <p style={{ textAlign: "center", fontSize: 13 }}>Llámenos o envíe un correo para cotización</p>
                                                                )}

                                                        </div>
                                                    ) : (
                                                        item.OnHandPrincipal > 0 && ( // Verifica si hay stock disponible
                                                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div style={{ width: "5rem", marginRight: card === true && 0 }}>
                                                                    {item.Unidades === "Unidades" ? (
                                                                        <input
                                                                            disabled={!item.OnHandPrincipal}
                                                                            id={'input-quantity-' + item.ItemCode + index}
                                                                            type="number"
                                                                            min="1"
                                                                            max={Number(item.OnHandPrincipal)}
                                                                            value={item.quantity ? Number(item.quantity).toFixed(0) : ''}
                                                                            className="form-control"
                                                                            name={'quantity' + item.ItemCode}
                                                                            placeholder="1"
                                                                            style={{ textAlign: "left", background: '#fff' }}
                                                                            onChange={(event) => { this.changLocalQuantity(index, item, event) }}
                                                                        />
                                                                    ) : (
                                                                        <input
                                                                            // disabled={!(!!item.OnHandPrincipal)}
                                                                            id={'input-quantity-' + item.ItemCode + index}
                                                                            type="number"
                                                                            min="1"
                                                                            max={Number(item.OnHandPrincipal)}
                                                                            value={item.quantity ? item.quantity : ''}
                                                                            className="form-control"
                                                                            name={'quantity' + item.ItemCode}
                                                                            placeholder="1"
                                                                            style={{ textAlign: "left", background: '#efefef' }}
                                                                            onChange={(event) => { this.changLocalQuantity(index, item, event) }}
                                                                        //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    <button
                                                                        type="button"
                                                                        readOnly={item.OnHandPrincipal === 0}
                                                                        style={{marginLeft:'12px'}}
                                                                        className={item.flag === 'green' ? "btn btn-warning rounded-lg" : "btn btn-block botonAgregarAgotado rounded-lg"}
                                                                        // item.OnHandPrincipal === 0 ? "btn btn-block botonAgregarAgotado" : item.flag === 'green' ? "btn btn-block botonAgregar": "btn btn-block botonAgregarYellow"
                                                                        value={(item.quantity ? Number(item.quantity) : 1)}
                                                                        onClick={(event) => { this.changeQuantity(index, item, event) }}
                                                                    >
                                                                        Agregar
                                                                    </button>
                                                                </div>
                                                                {item.OnHandPrincipal > 0 && (
                                                                    <div className='ml-3' style={{cursor: 'pointer'}} readOnly={item.OnHandPrincipal === 0} value={(item.quantity ? Number(item.quantity) : 1)} onClick={(event) => { this.directShopping(index, item, event)}}>
                                                                        <img title="Comprar ahora" src={config.navBar.carrito_naranja} alt='Ubicación' width="30" height="30"/>
                                                                    </div>
                                                                )}
                                                                {/* {(  (item.U_web !== 0 && item.U_web !== null) 
                                                            && (item.OnHandPrincipal <= 0 || item.OnHandPrincipal == ''))
                                                            &&
                                                                <div className="icoCarrito col-3 text-center align-middle" style={{fontSize: 33,padding:0, textAlign: "right",alignSelf:"center"}}>
                                                                    <img 
                                                                        // ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() +index] = iconMouse} 
                                                                        src={config.shoppingCartIcons.carritoAgotado} 
                                                                        style={{display: "block", color: "red", cursor: "pointer"}}
                                                                    />
                                                                    <img 
                                                                        // ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() +index] = iconMouseOver} 
                                                                        src={config.shoppingCartIcons.camionAgotado} 
                                                                        style={{display: "none", color: "red", cursor: "pointer"}}
                                                                    />
                                                                </div>
                                                        } */}

                                                                {/* {((item.U_web !== 0 && item.U_web !== null)
                                                                    && (item.flag === 'green' || item.flag === 'yellow'))
                                                                    // && item.OnHandPrincipal > 0 
                                                                    && <div className="icoCarrito col-3 text-center align-middle" style={{ fontSize: 33, padding: 0, textAlign: "right", alignSelf: "center" }}>
                                                                        <img
                                                                            // ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() +index] = iconMouse} 
                                                                            src={item.flag === 'green' ? config.shoppingCartIcons.carritoVerde : config.shoppingCartIcons.carritoAmarillo}
                                                                            style={{ display: "block", cursor: "pointer" }} 
                                                                            readOnly={item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false}
                                                                            value={(item.quantity ? Number(item.quantity) : 1)}
                                                                            onClick={(event) => { this.directShopping(index, item, event) }}
                                                                            onMouseOver={(event) => { this.mouseOverAddCart(index, item.flag, event) }}
                                                                            onMouseOut={(event) => { this.mouseOutAddCart(index, item.flag, event) }}/>
                                                                        <img
                                                                            // ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() +index] = iconMouseOver} 
                                                                            src={item.flag === 'green' ? config.shoppingCartIcons.camionVerde : config.shoppingCartIcons.camionAmarillo}
                                                                            style={{ display: "none", cursor: "pointer" }}
                                                                            readOnly={item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false}
                                                                            value={(item.quantity ? Number(item.quantity) : 1)}
                                                                            onClick={(event) => { this.directShopping(index, item, event) }}
                                                                            onMouseOver={(event) => { this.mouseOverAddCart(index, item.flag, event) }}
                                                                            onMouseOut={(event) => { this.mouseOutAddCart(index, item.flag, event) }}
                                                                        />
                                                                    </div>
                                                                } */}
                                                                {
                                                                    /* {item.U_web === 1 &&
                                                                        <div className="col-3" style={{color: "red", fontSize: 33,padding:0, textAlign: "right"}}>
                                                                            {item.quantity && <i className={config.icons.trash} style={{cursor: "pointer"}} onClick={() =>  deleteShoppingCart({item, deleteAll: false})}/>}
                                                                        </div>
                                                                    } */
                                                                }
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <div className="cotizacion row text-center" style={{ margin: 0, padding: 0 }}>
                                                    {item.U_web === 0 || item.U_web === null ?
                                                        item.wishlist === 1 &&
                                                        <div className="col-12" style={{ padding: 0 }}>
                                                            <label style={{ textAlign: "center", fontSize: 16 }}></label>
                                                        </div> :
                                                        item.wishlist === 1 &&
                                                        <div className="col-10">
                                                            <label style={{ textAlign: "center", marginTop: 12, fontSize: 14 }}>Lista de deseos</label>
                                                        </div>
                                                    }
                                                    {item.U_web === 1 &&
                                                        item.wishlist === 1 &&
                                                        <div className="col-2" style={{ color: config.navBar.backgroundColor, fontSize: 20, padding: 0 }}>
                                                            {item.backOrder ?
                                                                <i className={config.icons.backOrderFalse} style={{ cursor: "pointer", marginLeft: 7, marginTop: 15 }} onClick={() => changeBackOrder(item, false)} /> :
                                                                <i className={config.icons.backOrderTrue} style={{ cursor: "pointer", marginLeft: 7, marginTop: 15 }} onClick={() => changeBackOrder(item, true)} />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );

                            })}
                        </div>
                    </div>

                </div>

                {TotalPage > 0 &&
                    <div className="row">
                        <Pagination
                            currentPage={currentPage}
                            refresh={this.searchPage}
                            totalRowsRefresh={TotalPage} />
                    </div>}

            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
        configReducer: store.ConfigReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
        setNextPage: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemsList);