import React, { Component, lazy, Suspense } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import PostBanner from './views/banners/postbanner';
import PostMarcas from './views/marcas/postmarcas';
import { connect } from 'react-redux';
import './App.css';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import AWN from "awesome-notifications";
import { DISPATCH_ID, config, ROLES } from './libs/utils/Const';
import { SearchItemsActions, Footer } from './components';
import CacheBuster from './CacheBuster';
import { Link } from 'react-scroll';
const DashboardView = lazy(() => import ('./views/dashboard/DashboardView'));
const ItemsView = lazy(() => import ('./views/items/ItemsView'));
const ItemsDetailsView  = lazy(() => import ('./views/items/ItemsDetailsView'));
const LoginView = lazy(() => import ('./views/login/LoginView'));
const RedCompensasZoneView = lazy(() => import ('./views/redZone/RedCompensasZoneView'));
const ResetPointsView = lazy(() => import ('./views/resetPoints/ResetPointsView'));
const ShoppingCartView = lazy(() => import ('./views/shoppingCart/ShoppingCartView'));
const QuoteView = lazy(() => import ('./views/quote/QuoteView'));
const BackOrderView = lazy(() => import ('./views/backOrder/BackOrderView'));
const ProfileView = lazy(() => import ('./views/profile/ProfileView'));
const OrdersView = lazy(() => import ('./views/orders/OrdersView'));
const BillView  = lazy(() => import ('./views/bill/billingViwes'));
const AboutUsView = lazy(() => import ('./views/aboutUs/AboutUsView'));
const ContactUsView = lazy(() => import ('./views/contactUs/ContactUsView'));
const MercadoLibreView = lazy(() => import ('./views/mercadoLibre/MercadoLibreView'));
const SelectAddressView = lazy(() => import ('./views/selectAddress/SelectAddressView'));
const ValidateOrderView = lazy(() => import ('./views/validateOrder/ValidateOrderView'));
const CreteOrderView = lazy(() => import ('./views/createOrder/CreteOrderView'));
const BannerAdminIndex = lazy(() => import ('./views/banners/index'));
const Selector = lazy(() => import ('./views/selector/Selector'));
const reportsView = lazy(() => import ('./views/reports/reportsView'));
const Quatiton = lazy(() => import ('./views/quotation/quotationViews'));
const Delivery = lazy(() => import ('./views/delivery/deliveryViews'));
const Saveds = lazy(() => import ('./views/saved/savedViews'));
const Preliminary = lazy(() => import ('./views/preliminary/preliminaryViews'));
const Collection = lazy(() => import ('./views/colletion/collectionViews'));
const Overdue = lazy(() => import ('./views/overdue/overdueViews'));
const MyProfile = lazy(() => import ('./views/MyProfile/MyProfile'));
const AutorizacionesView = lazy(() => import ('./views/autorizaciones/AutorizacionesView'));
const MiddlewareView = lazy(() => import ('./views/middleware/MiddlewareView'));
const SubirArchivo = lazy(() => import ('./views/SubirArchivo/SubirArchivo'));
const Reclamo = lazy(() => import ('./views/claim/Reclamo'));
const Politicas = lazy(() => import ('./views/politics/PoliticsView'));
const Preguntas = lazy(() => import ('./views/Questions/Preguntas'));
const Transbank = lazy(() => import ('./views'));
const MercadoPago = lazy(() => import ('./views/webpay/mercadoPago'));
const ItemsPolarView = lazy(() => import ('./views/items/ItemsPolarView'));
const ItemsBlancaView = lazy(() => import ('./views/items/ItemsBlancaView'));
const ItemsRoutletView = lazy(() => import ('./views/items/ItemsRoutletView'));
const TermsView = lazy(() => import ('./views/terms/Terms'));
const AddAddressView = lazy(() => import ('./views/addAddress/AddAddressView'));
const AccountDataView = lazy(() => import ('./views/aboutUs/AccountDataView'));
const PoliticsView = lazy(() => import ('./views/politics/PoliticsView'));
const RedAliadoView = lazy(() => import ('./views/redAliado/RedAliado'));
const PrivacyView = lazy(() => import ('./views/privacy/Privacy'));
const EditAddressView = lazy(() => import ('./views/selectAddress/EditAddressView'));
const JobView = lazy(() => import ('./views/job/JobView'));
const SucursalView = lazy(() => import ('./views/sucursales/SucursalView'));
const AboutRedCompensasZoneView = lazy(() => import ('./views/redZone/AboutRedCompensasZoneView'));
const SafeShoppingView = lazy(() => import ('./views/safeShopping/SafeShoppingView'));
const PaymentMethodView = lazy(() => import ('./views/safeShopping/PaymentMethodView'));
const DevolutionView = lazy(() => import ('./views/devolution/DevolutionView'));
const adminNewsBlogsView = lazy(() => import ('./views/newsBlog/adminNewsBlogsView'));
const NewsBlogView = lazy(() => import ('./views/newsBlog/NewsBlogView'));
const SpecialPricesView = lazy(() => import ('./views/specialPrices/SpecialPricesView'));
const PromocionalesView = lazy(() => import ('./views/promocionales/PromocionalesView'));
const PlantillaView = lazy(() => import ('./views/plantilla/PlantillaView'));
const ItemsNumberFive = lazy(() => import ('./views/items/ItemsNumberFive'));
const ItemsNumberFour = lazy(() => import ('./views/items/ItemsNumberFour'));
const AsesoriaView  = lazy(() => import ('./views/AsesoriaView/AsesoriaView'));
const ClientAllItems  = lazy(() => import ('./views/boletin/boletinView'));
const AboutUsView1 = lazy(() => import ('./views/boletin/AboutUsView'));
const CardSavedsView = lazy(() => import ('./views'));
const TestPermalinksView = lazy(() => import ('./views'));
const MarcasAdminIndex = lazy(() => import ('./views/marcas/index'));
const AdminView = lazy(() => import ('./views/administrador/AdminView'));


// const Webpay = require('./controllers/WebpayNormalController');
class App extends Component {

    UNSAFE_componentWillMount() {
        const { setBusiness, setToken, setUser, setRememberUser, setRole, setLogOutReference } = this.props;

        //users
        let token = localStorage.getItem(config.general.localStorageNamed + 'Token');
        let role = localStorage.getItem(config.general.localStorageNamed + 'Role');
        let user = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        let rememberUser = localStorage.getItem(config.general.localStorageNamed + 'RememberUser');

      

        user = JSON.parse(user) || {};

        setRole(role || ROLES.PUBLIC);
        setBusiness(config.general.business);
        setToken(token);
        setUser(user);
        setRememberUser(rememberUser);
        //Comentar para pruebas para que NO se cierre la sesión
        // setLogOutReference(this.logOut);
    }

    logOut = () => {
        const { configReducer, setBusiness, setToken, setUser, setRememberUser, setRole} = this.props;
        localStorage.removeItem(config.general.localStorageNamed + 'Role');
        localStorage.removeItem(config.general.localStorageNamed + 'Token');
        localStorage.removeItem(config.general.localStorageNamed + 'CurrentUser');
        localStorage.removeItem(config.general.localStorageNamed + 'PartnerUser');
        localStorage.removeItem(config.general.localStorageNamed + 'RememberUser');
        localStorage.removeItem(config.general.localStorageNamed + 'vendor');
        localStorage.clear();
        configReducer.history.goHome();

        setRole(ROLES.PUBLIC);
        setBusiness(config.general.business);
        setToken(undefined);
        setUser({});
        setRememberUser(false);


    };

    componentDidMount() {
        const { addNotificationReference } = this.props;
        addNotificationReference(this.createNotification);
    }

    createNotification = action => {
        let notifierOptions = {
            position: 'top-right',
            durations: {
                global: action.timeOut ? action.timeOut + 1000 : 8000
            },
            labels: {
                info: "Información",
                success: "Exitoso",
                warning: "Advertencia",
                alert: "ALERTA",
            }
        }
        let notifier = new AWN(notifierOptions);
        switch (action.type) {
            case 'info':
                notifier.info(
                    action.message ? action.message : ''
                );
                // NotificationManager.info(
                //     action.message ? action.message : '',
                //     action.title ? action.title : '',
                //     action.timeOut ? action.timeOut : 3000,
                //     action.callback ? action.callback : null
                // );
                break;
            case 'success':
                notifier.success(
                    action.message ? action.message : ''
                );
                // NotificationManager.success(
                //     action.message ? action.message : '',
                //     action.title ? action.title : '',
                //     action.timeOut ? action.timeOut : 3000,
                //     action.callback ? action.callback : null
                // );
                break;
            case 'warning':
                notifier.warning(
                    action.message ? action.message : ''
                );
                // NotificationManager.warning(
                //     action.message ? action.message : '',
                //     action.title ? action.title : '',
                //     action.timeOut ? action.timeOut : 3000,
                //     action.callback ? action.callback : null
                // );
                break;
            case 'error':
                notifier.alert(
                    action.message ? action.message : ''
                );
                // NotificationManager.error(
                //     action.message ? action.message : '',
                //     action.title ? action.title : '',
                //     action.timeOut ? action.timeOut : 3000,
                //     action.callback ? action.callback : null
                // );
                break;
            default:
                return;
        }
    };

    render() {
        const { spinner } = this.props.configReducer;
        const { addNotificationReference, itemsReducer: { location } } = this.props;

        addNotificationReference(this.createNotification);
        return (
            <CacheBuster>
                {
                    ({loading, isLatestVersion, refreshCacheAndReload }) => {
                        if (!location === 'itemsDetailsRoute'){
                            if (loading) return null;
                            if (!loading && !isLatestVersion) {
                                refreshCacheAndReload();
                            }
                        }
                        return(
                        <div id="idRegresar" className="scroll-tip" style={{width: "100%"}}>
                            {spinner && (
                                <div className="loader" style={{ backgroundColor: config.general.loaderBackground }}>
                                    <div className="text-center">
                                        <div className={"spinner-border " + config.general.loaderColor} style={{ width: 45, height: 45 }} role="status">
                                            <span className="sr-only">{config.general.loaderImage}</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <NotificationContainer />
                            <SearchItemsActions />
                            {/* <HashRouter>
                                <Switch>
                                    <Route path="/permalink/test/01" component={TestPermalinksView} /> 
                                </Switch>
                            </HashRouter>   */}
                            <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <Route exact path="/" component={DashboardView} />                                
                                <Route exact path="/login" component={LoginView} />
                                <Route exact path="/permalink/:id/:slug" component={TestPermalinksView} /> 
                                <Route exact path="/zonaRecompensa" component={RedCompensasZoneView} />
                                <Route exact path="/resetPoints" component={ResetPointsView} />
                                <Route exact path="/items" component={ItemsView} />
                                <Route exact path="/itemDetail/:params" component={ItemsDetailsView} />

                                <Route exact path="/terms" component={TermsView} />
                                <Route exact path="/aliado" component={RedAliadoView} />
                                <Route exact path="/privacy" component={PrivacyView} />

                                <Route exact path="/shoppingCart" component={ShoppingCartView} />
                                <Route exact path="/quote" component={QuoteView} />
                                <Route exact path="/backOrder" component={BackOrderView} />
                                <Route exact path="/profile" component={ProfileView} />
                                <Route exact path="/orders" component={OrdersView} />
                                <Route exact path="/bill" component={BillView} />
                                <Route exact path="/aboutUs" component={AboutUsView} />
                                <Route exact path="/contactUs" component={ContactUsView} />
                                {/* <Route exact path="/mercadoLibre" component={MercadoLibreView} /> */}
                                <Route exact path="/selectAddress" component={SelectAddressView} />
                                <Route exact path="/validateOrder" component={ValidateOrderView} />
                                <Route exact path="/createOrder" component={CreteOrderView} />
                                <Route exact path="/adminBanners" component={BannerAdminIndex} />
                                <Route exact path="/postbanner/:id/:slug" component={PostBanner} />
                                <Route exact path="/selector" component={Selector} />
                                <Route exact path="/reports" component={reportsView} />
                                <Route exact path="/quotations" component={Quatiton} />
                                <Route exact path="/delivery" component={Delivery} />
                                <Route exact path="/saveds" component={Saveds} />
                                <Route exact path="/prelimnarys" component={Preliminary} />
                                <Route exact path="/collections" component={Collection} />
                                <Route exact path="/overs" component={Overdue} />
                                <Route exact path="/myprofile" component={MyProfile} />
                                <Route exact path="/autorizaciones" component={AutorizacionesView} />
                                <Route exact path='/canalModerno' component={MiddlewareView}/>
                                <Route exact path="/subirArchivo" component={SubirArchivo} />
                                {/* <Route exact path="/tranbank/init" component={Webpay.init}/> */}
                                <Route exact path="/claim" component={Reclamo}/>
                                <Route exact path="/politics" component={Politicas}/>
                                <Route exact path="/questions" component={Preguntas}/>
                                {/* <Route exact path="/transbank/:action/:name/:token" component={Transbank}/>
                                <Route exact path="/mercadoPago" component={MercadoPago}/>                     */}
                                <Route exact path="/itemsM1" component={ItemsPolarView} />
                                <Route exact path="/itemsM2" component={ItemsBlancaView} />
                                <Route exact path="/itemsM3" component={ItemsRoutletView} />
                                <Route exact path="/itemsM4" component={ItemsNumberFour} />
                                <Route exact path="/itemsM5" component={ItemsNumberFive} />

                                <Route exact path="/terms" component={TermsView} />
                                <Route exact path="/addAddress" component={AddAddressView}/>
                                <Route exact path="/accountData" component={AccountDataView}/>
                                <Route exact path="/sellingPolices" component={PoliticsView}/>
                                <Route exact path="/editAddress" component={EditAddressView}/>
                                <Route exact path="/job" component={JobView}/>
                                <Route exact path="/privacy" component={PrivacyView} />
                                <Route exact path="/sucursales" component={SucursalView} />
                                <Route exact path="/clientePreferente" component={AboutRedCompensasZoneView}/>
                                <Route exact path="/safeShoppig" component={SafeShoppingView}/>
                                <Route exact path="/paymentMethodView" component={PaymentMethodView}/>
                                <Route exact path="/devolutionView" component={DevolutionView}/>
                    
                                <Route exact path="/admiNewsBlog" component={adminNewsBlogsView} />
                                <Route exact path="/newsBlog" component={NewsBlogView} />

                                <Route exact path="/specialPrices" component={SpecialPricesView} />    

                                <Route exact path="/promocionales" component={PromocionalesView} />
                                <Route exact path="/platilla" component={PlantillaView} />
                                <Route exact path="/asesoria" component={AsesoriaView} />
                                <Route exact path="/boletin" component={AboutUsView1} />
                                <Route exact path="/cardsaved" component={CardSavedsView} />

                                <Route exact path="/adminMarcas" component={MarcasAdminIndex} />
                                <Route exact path="/postmarcas/:id/:slug" component={PostMarcas} />
                                <Route exact path="/administrador" component={AdminView}/>

                                {/* Siempre al final */}
                                <Route exact path="*" component={DashboardView} />
                 
                            </Switch>
                            </Suspense>                           
                            {/* <div className=''>
                                <button className='floating-button'><img src={config.icons.iconochat} width={'20px'} height={'20px'}></img> Chat </button>
                            </div> */}
                            {/* <Footer /> */}
                        </div>
                        );
                    }
                }
            </CacheBuster>
        );
    }
}

const mapStateToProps = store => {
    return { 
        configReducer: store.ConfigReducer, 
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer};
};

const mapDispatchToProps = dispatch => {
    return {
        setRole: value => dispatch({ type: DISPATCH_ID.SESSION_SET_ROLE, value }),
        addNotificationReference: value => dispatch({ type: DISPATCH_ID.NOTIFICATION_SET_ALERT_REFERENCE, value }),
        setBusiness: value => dispatch({ type: DISPATCH_ID.SESSION_SET_BUSINESS_ID, value }),
        setToken: value => dispatch({ type: DISPATCH_ID.SESSION_SET_TOKEN, value }),
        setUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_USER, value }),
        setRememberUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_REMEMBER_USER, value }),
        setLogOutReference: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_LOG_OUT_REFERENCE, value }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
