import React, {Component} from 'react';
import {connect} from "react-redux";
import $ from 'jquery';
import moment from "moment";



class QuotationModal extends Component {

    validate(){
        const { requestQuotation, notificationReducer: {showAlert} } = this.props
        if (requestQuotation.name == "" || requestQuotation.name.length < 3) {
            showAlert({type: 'warning', message: 'El nombre no puede ir vació, y debe tener de 3 a 100 caracteres.' , timeOut: 8000});
            return
        }
        if (requestQuotation.lastName == "" || requestQuotation.lastName.length < 3) {
            showAlert({type: 'warning', message: 'El apellido no puede ir vació, y debe tener de 3 a 100 caracteres.' , timeOut: 8000});
            return
        }
        if (requestQuotation.email == "") {
            showAlert({type: 'warning', message: 'El email no puede ir vació' , timeOut: 8000});
            return
        }
        if (!(/^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/).test(requestQuotation.email)) {
            showAlert({type: 'warning', message: 'El email no es valido' , timeOut: 8000});
            return
        }
    }
    
    render() {
        const { requestQuotation, onChangeRequestQuotation } = this.props
        return (
            <div className="modal fade"  id="quotationModal" role="dialog" aria-labelledby="quotationModalLabel" aria-hidden="true"  tabindex="-1">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header text-white text-center" style={{background: '#212e57', borderRadius: '0' }}>
                            <h5 className="modal-title" id="quotationModalLabel">NUEVA COTIZACIÓN</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body ml-auto mr-auto">
                            <div className='row'>
                                <p style={{fontSize: "25px"}}>
                                Ponte en contacto, agrega los datos de los artículos a cotizar.
                                </p><br></br>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Nombre</span>
                                        </div>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            placeholder=""
                                            aria-label="Username" 
                                            aria-describedby="basic-addon1"
                                            value={requestQuotation.name}
                                            onChange={(event)=>onChangeRequestQuotation(event,'name')}
                                        />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">E-mail</span>
                                        </div>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            placeholder="" 
                                            aria-label="Username" 
                                            aria-describedby="basic-addon1"
                                            value={requestQuotation.email}
                                            maxLength={100}
                                            onChange={(event)=>onChangeRequestQuotation(event,'email')}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Apellido</span>
                                        </div>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            placeholder="" 
                                            aria-label="Username" 
                                            aria-describedby="basic-addon1"
                                            value={requestQuotation.lastName}
                                            maxLength={100}
                                            onChange={(event)=>onChangeRequestQuotation(event,'lastName')}
                                        />
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Teléfono</span>
                                        </div>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            placeholder="" 
                                            aria-label="Username" 
                                            aria-describedby="basic-addon1"
                                            value={requestQuotation.phone}
                                            maxLength={15}
                                            onChange={(event)=>((/^[0-9]+$/).test(event.target.value) || event.target.value == '') ? onChangeRequestQuotation(event,'phone'):""}
                                        />
                                    </div>
                                </div>                                
                                <div className='col-md-12'>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1">Dirección</span>
                                        </div>
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            placeholder="" 
                                            aria-label="Username" 
                                            aria-describedby="basic-addon1"
                                            value={requestQuotation.address}
                                            onChange={(event)=>onChangeRequestQuotation(event,'address')}
                                        />
                                    </div>
                                    <div class="input-group mb-3">                                            
                                        <textarea 
                                            type="text" 
                                            class="form-control" 
                                            placeholder="Escriba su mensaje aquí:" 
                                            aria-label="Username" 
                                            aria-describedby="basic-addon1"
                                            value={requestQuotation.message}
                                            maxLength={250}
                                            onChange={(event)=>onChangeRequestQuotation(event,'message')}
                                        />
                                    </div>
                                </div>
                            </div>
                                
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-dismiss="modal" className="btn btn-secondary">Cerrar</button>
                            <button type="button"  className="btn btn-primary" onClick={()=>this.validate()}>Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
        itemsReducer: store.ItemsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuotationModal);