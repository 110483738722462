import React, { Component } from 'react';
import Async from 'react-async';
import { config } from "../libs/utils/Const";
import Carousel from 'react-bootstrap/Carousel'

class CarouselDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            banners: [],
        };
    }

    async componentDidMount() {

        await fetch(`${config.BASE_URL}/getbanners`, { method: 'GET', mode: 'cors', cache: 'default' })
            .then(response => response.json())
            .then(response => {
                this.setState({
                    banners: response

                });

            })
            .catch((err) => {
                this.setState({
                    banners: []

                });
            });


    };

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.banners !== this.state.banners) {
            return true;
        } else {
            return false;
        }
    }


    render() {
        const { banners } = this.state;
        return (
            <div>
                <div>
                    <Carousel>
                        {banners.map((rows, index) => (
                            <Carousel.Item interval={4000}>
                                <img className="d-block w-100" src={config.ASSETS + 'banners/' + rows.image} alt={rows.image} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
        );
    }
}

export default CarouselDashboard;