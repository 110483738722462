import React, { Component } from 'react';
import { SERVICE_RESPONSE, DISPATCH_ID, config, ROLES } from "../libs/utils/Const";
import { ApiClient } from "../libs/apiClient/ApiClient";
import { connect } from "react-redux";
let apiClient = ApiClient.getInstance();

class WarehouseModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            whsGeneralCode: '',
            whsGeneralName: '',
            // whsGeneralCode: '01',
            // whsGeneralName: 'Por Defecto',
            wshList: [],

        };
    };

    componentDidMount = async () => {
        this.fillOWHS();
    }

    fillOWHS = async () => {
        let response = await apiClient.getOWHSuUser();
        this.setState({
            wshList: response.data,
        })
    }

    //#region ########## REGION Cambiar almacen localmente (Visualmente) ##########
    changeLocalWhs = async (event) => {
        let newWsh = event.nativeEvent.target.value.split(",");
        
        this.setState({
            whsGeneralCode: newWsh[0],
            whsGeneralName: newWsh[1],
        })
    };
    //#endregion  ########## FIN REGION ##########

    //#region ########## REGION Cambiar almacen de manera general ##########
    changeWhs = async (event) => {
        const { setWhsGeneral, setWhsGeneralName, configReducer: { history }, setToken, setUserSession, setRememberUser,itemsReducer : { searchByDashOption } } = this.props;
        const { whsGeneralCode, whsGeneralName } = this.state;

        // if (items.length > 0) {
        //     showAlert({ type: 'warning', message: "Para cambiar de almacén no debe tener articulos en el carrito de compras." });
        // } else {
            setWhsGeneral(whsGeneralCode);
            setWhsGeneralName(whsGeneralName);
            await apiClient.UpdateWOHSBack(whsGeneralCode);
            let empID  = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
            let currentUser = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser'));
            let user = {
                email: currentUser.CardCode,
                password: currentUser.U_Handel_Password,
                salesPerson : empID ? empID.salesPrson : '',
                whsGeneralCode
            };
            let response = await apiClient.login(user);
            
            if (response.status === SERVICE_RESPONSE.SUCCESS) {
                let user = response.data.user;
                let token = response.data.token;
                let remember = true;
                
                localStorage.setItem(config.general.localStorageNamed + 'Token', JSON.stringify(token));
                localStorage.setItem(config.general.localStorageNamed + 'CurrentUser', JSON.stringify(user));
    
                setToken(token);
                setUserSession(user);
                setRememberUser(remember);
    
    
                // setTimeout(()=> {
                //     if(responsesd.data.value > 0){
                //         configReducer.history.goShoppingCart();
                //     }else{
                //         configReducer.history.goHome();
                //     }
                // },50);
                
                // return;
                setTimeout(() => {
                    history.goItems();  // redirecciona a Items e inmediatamente al home al cambiar de almacen (Solucion practica para cuando estas en home)
                    history.goHome();   // redirecciona al home al cambiar de almacen
                    searchByDashOption();
                }, 250);
            }

            
        // }
    };
    //endregion  ########## FIN REGION ##########

    render() {
        const { wshList, whsGeneralCode, whsGeneralName } = this.state;
        const { itemsReducer } = this.props;
        return (
            <div className="modal fade" id="warehouseModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ minWidth: 300, maxWidth: 600, margin: '1.75rem auto', borderRadius: 15 }}>
                    <div className="modal-content">

                        <div className="modal-header" style={{ background: config.navBar.iconColor, color: '#FFFFFF' }}>
                            <h5 className="modal-title">SELECCIÓN DE ALMACÉN</h5>
                            <button type="button" style={{ color: "#FFFFFF", opacity: 1, margin: 0, padding: 0 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"style={{fontSize: '28px'}}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label style={{ fontWeight: "bold" }}>ELIJA EL ALMACÉN DEL CUAL SE CONSULTARÁN LOS ARTÍCULOS</label>
                                </div>
                                <div className="form-row" style={{ padding: "0px 50px 0px 50px" }}>
                                    <div className="form-group col-md-12" style={{ display: "flex" }}>
                                        <label htmlFor="articulo" style={{ alignSelf: "end", marginRight: 15 }}>ALMACENES</label>
                                        <select
                                            name="WareHouse"
                                            className="form-control text-left"
                                            onChange={(event) => { this.changeLocalWhs(event) }}
                                            value={[whsGeneralCode, whsGeneralName]}
                                            style={{ textAlign: 'center', height: 30, padding: 0 }}>
                                            {wshList !== undefined && wshList.length > 0 &&
                                                wshList.map((whs) => {
                                                    return <option value={[whs.WhsCode, whs.WhsName]} >{whs.WhsName}</option>
                                                })
                                            }
                                            <option value={['', '']} >MATRIZ</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <label htmlFor="cantidad" style={{ marginRight: "auto" }}>ALMACEN SELECCIONADO: {itemsReducer.whsGeneralName || 'MATRIZ'}</label>
                            <button type="button" className="btn" style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFF' }} onClick={this.changeWhs}> Seleccionar </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        sessionReducer: store.SessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setWhsGeneral: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_WHSGENERAL, value }),
        setWhsGeneralName: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_WHSGENERAL_NAME, value }),
        setToken: value => dispatch({type: DISPATCH_ID.SESSION_SET_TOKEN, value}),
        setUserSession: value => dispatch({type: DISPATCH_ID.SESSION_SET_USER, value}),
        setRememberUser: value =>  dispatch({type: DISPATCH_ID.SESSION_SET_REMEMBER_USER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WarehouseModal);