import React, { Component } from 'react';
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID, SERVICE_RESPONSE, ROLES } from '../libs/utils/Const';
import { connect } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ItemSlider.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ApiClient } from '../libs/apiClient/ApiClient';
import moment from 'moment';
import 'semantic-ui-css/semantic.min.css'
import { Header, Button, Popup, Label } from 'semantic-ui-react'

let settings;
let apiClient = ApiClient.getInstance();

// esto es para el nuevo carrousel que contendrá los items
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1920 },
        items: 5,
        partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
    },
    desktop2: {
        breakpoint: { max: 1920, min: 1200 },
        items: 4,
        partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
        breakpoint: { max: 1200, min: 992 },
        items: 3,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet1: {
        breakpoint: { max: 992, min: 767 },
        items: 2,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet2: {
        breakpoint: { max: 767, min: 464 },
        items: 1,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
        breakpoint: { max: 463, min: 0 },
        items: 1,
        //items: 0.502,
        partialVisibilityGutter: 5 // this is needed to tell the amount of px that should be visible.
    }
}

class ItemSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
        };
        this.mouseOverAddCart = this.mouseOverAddCart.bind(this);
        this.mouseOutAddCart = this.mouseOutAddCart.bind(this);
        this.iconMouse = [];
        this.iconMouseOver = [];
    }

    changeQuantity = (index, item, event) => {
        const { changeQuantity, notificationReducer: { showAlert }, itemsReducer: { items: itemsSearch } } = this.props;

        let newQuantity;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                newQuantity = itemFilter.quantity || '1';
            }
        });

        let onHand = item.OnHandPrincipal;
        // if(Number(newQuantity) > Number(onHand)) {
        //     if (Number(onHand) !== 0 ) {
        //         showAlert({type: 'warning', message: "Se excede el número de articulos disponibles de este producto", timeOut: 2500});
        //     }
        //     newQuantity = onHand;
        // } 
        // if(Number(item.quantity) > Number(item.OnHand)) {
        //     if (Number(onHand) !== 0 ) {
        //         showAlert({type: 'warning', message: "No se cuenta con stock disponible, se surtirá el resto en cuanto se tenga el stock.", timeOut: 8000});
        //     }
        // } 

        // if(!newQuantity){
        //         changeQuantity(index,item, newQuantity, false); //delete
        //     }else{
        changeQuantity(index, item, newQuantity, true); // add
        // }
    };



    changLocalQuantity = (index, item, event) => {
        const { shoppingCartReducer: { items }, itemsReducer: { items: itemsSearch } } = this.props;
        let newQuantity = event.nativeEvent.target.value;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                itemFilter.quantity = newQuantity;
            }
        });
        this.applyFilters(itemsSearch);
    }

    applyFilters = data => {
        const { setItemsFilterSearch } = this.props;
        // enableSpinner(true);
        setItemsFilterSearch(data);
        // enableSpinner(false);

        // $("#menuCategories").removeClass('open-menu-categories');
    };

    mouseOverAddCart = (index, flag, event) => {
        if (this.iconMouse && this.iconMouse.length > 0) {
            this.iconMouse[index].style.display = "none";
            this.iconMouseOver[index].style.display = "block";
        }
    }

    mouseOutAddCart = (index, flag, event) => {
        if (flag == 'green') {
            event.currentTarget.style.backgroundColor = "#CE3529";
            event.currentTarget.className = "btn btn-block botonAgregar"
        } else if (flag == 'yellow') {
            event.currentTarget.style.backgroundColor = "#efc964";
            event.currentTarget.className = "btn btn-block botonAgregarYellow"
        } else {
            event.currentTarget.style.backgroundColor = "#e27753";
            event.currentTarget.className = "btn btn-block botonAgregarAgotado"
        }
        // Íconos
        if (this.iconMouse && this.iconMouse.length > 0) {
            this.iconMouse[index].style.display = "block";
            this.iconMouseOver[index].style.display = "none";
        }
    }

    render() {
        const { items, changeBackOrder, updateFavorite, deleteShoppingCart, openDetails, dashboard } = this.props;
        const { addCartMessageOver, seller } = this.state;
        let valor = seller ? seller.U_FMB_Handel_Perfil : '0';
        let h1 = items.length;
        let x = window.matchMedia("(max-width: 50px)")
        return (
            <div>
                <div className="SliderItems">
                    <Carousel autoPlay itemClass="carousel-item-padding-20-px" partialVisible={false} responsive={responsive} infinite={true} autoPlaySpeed={5000} removeArrowOnDeviceType={["tablet", "mobile"]} >
                        {items && items.map((item, index) => {

                            item.ItemName = item.ItemName || '';
                            let dashboardForTwoSliders = dashboard ? dashboard : '';

                            // Arreglo de imagenes del campo extra
                            let imagesArray = item.PicturName || '';
                            imagesArray = imagesArray.split('|');
                            let image = imagesArray[0] ? imagesArray[0] : item.PicturName;
                            let imagenShow = image ? (config.BASE_URL + SERVICE_API.getImage + '/' + image) : require('../images/noImage.png');

                            return (
                                <div id="ItemsSlider" style={{ position: "relative", textAlign: "center", maxWidth: "18rem", maxHeight: "45rem", paddingLeft: "1rem", paddingRight: "1rem"/* marginTop:"4px" textAlign: "center"*/ }} key={(dashboard || '') + item.ItemCode + item.ItemName + item.Price}>
                                    <div className="item card" style={{/*width: "305px", height: "500px",*/ width: "245.41px", minHeight: "370px", padding: "1rem", borderRadius: ".8rem", backgroundColor: config.itemsList.backgroundColor, border: "solid " }}>
                                        <div className="imageCard ">
                                            <div style={{
                                                display: (!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 ? 'none' : 'table'),
                                            }}>
                                                <div className="font-weight-bold ribbon" style={{ top: "-2px", left: "11px" }} onClick={() => openDetails(item)}><span><blink> PROMOCIÓN </blink></span></div>
                                            </div>
                                            <a href="#idRegresar">
                                                <img
                                                    onClick={() => openDetails(item)}
                                                    className="card-img-top cardImg"
                                                    // style={{
                                                    //     width: "270px",
                                                    //     height: "270px",
                                                    //     backgroundColor: "white",
                                                    //     cursor: "pointer",
                                                    //     marginRight: "auto",
                                                    //     marginLeft: "auto",
                                                    // }}
                                                    src={imagenShow}
                                                    alt="Imagen del articulo"
                                                />
                                            </a>
                                            <div className="card-body" style={{ height: "fit-content", margin: 0, padding: "10px", color: config.itemsList.textColor, minHeight: "135px" }}>
                                                <div>
                                                    <p className="card-subtitle text-left" style={{ fontSize: 13, marginLeft: -2, color: config.itemsList.textPrice2 }}>
                                                        {item.U_web === 0 || item.U_web === null
                                                            ? "Solicite su cotización"
                                                            : (valor == '0' || !item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 || item.DiscountPercentSpecial == 0 || item.Disc===0 ?
                                                                <CurrencyFormat
                                                                    value={item.Price}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={'$'}>
                                                                </CurrencyFormat>
                                                                : <>
                                                                    <span className="precioPromocion row justify-content-start ml-md-2 pl-2">
                                                                        <CurrencyFormat
                                                                            value={item.PriceBeforeDiscount}
                                                                            displayType={'text'}
                                                                            thousandSeparator={true}
                                                                            fixedDecimalScale={true}
                                                                            decimalScale={2}
                                                                            prefix={'$'}>
                                                                        </CurrencyFormat>
                                                                    </span>
                                                                    <span className="precioPromocionItemList">
                                                                        <div className="preciosItemSlider row justify-content-start">
                                                                            <div className="pricePromoItemSlider col-md-auto ml-md-1" style={{ fontSize: 'xx-large' }}>
                                                                                <CurrencyFormat
                                                                                    value={item.Price}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix={'$'}>
                                                                                </CurrencyFormat>
                                                                            </div>
                                                                            {seller ? seller.U_FMB_Handel_Perfil != '0' && Number(item.DiscountPercentSpecial) !== 0 ?
                                                                                <div className="descuento col-md-4 align-middle text-right">
                                                                                    <span className="text-danger">{parseFloat(item.DiscountPercentSpecial).toFixed(2)}% OFF</span>
                                                                                </div>
                                                                                : "" : ""
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                </>
                                                            )
                                                        }
                                                        {item.U_web === 1 &&
                                                            " " //+ item.currency
                                                        }
                                                    </p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p className="text-left" style={{ marginLeft: -4, fontSize: 13, paddingBottom: 0, marginBottom: 0, }}><span style={{ color: "#AFAFAF" }}>{'SKU'}</span> <span className="font-weight-bold" style={{ color: "#212E57" }}>{item.ItemCode}</span></p>
                                                        {/* <h6 className="card-subtitle font-weight-bold text-left" style={{ fontSize: 16, margin: 2, color: config.itemsList.textPrice }}>
                                                            {item.U_web === 0 || item.U_web === null
                                                                ? "Solicite su cotización"
                                                                : <CurrencyFormat
                                                                    value={item.Price}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={'$ '}>
                                                                </CurrencyFormat>
                                                            }
                                                            {item.U_web === 1 &&
                                                                " " + item.currency
                                                            }
                                                        </h6> */}
                                                    </div>
                                                    <div className="col-3" style={{ padding: 0, margin: 0, }}>
                                                        <div onClick={() => updateFavorite(item)} style={{ padding: 0 }}>
                                                            {/*<i
                                                            className={config.icons.favorite}
                                                            aria-hidden="true"
                                                            style={{
                                                                cursor: 'pointer',
                                                                padding: 0,
                                                                margin: 0,
                                                                marginTop: 9,
                                                                fontSize: 30,
                                                                zIndex: 10,
                                                                color: item.favorite ? config.itemsList.iconColor : 'rgba(124, 124, 125, 0.5)',
                                                            }}
                                                        />*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="overflow-auto ItemName">
                                                    <p className="card-title text-left" style={{ padding: 1, fontSize: 13, color: "#4e4e4e" /*marginLeft: 3,maxWidth: "100%", fontSize: 16, color:"#AFAFAF"*/ }}>
                                                        {/*item.ItemName.length > 43 ? item.ItemName.substring(0, 42 ) + "..." : item.ItemName*/}
                                                        {/* {item.ItemName ? (item.ItemName).length > 32 ? (item.ItemName).substring(0, 31) + " ... ": item.ItemName : " "} */}
                                                        {item.ItemName ?
                                                            (item.ItemName).length > 32 ?
                                                                <div>
                                                                    <span >{(item.ItemName).substring(0, 31)}</span>
                                                                    <Popup trigger={<Label className='ItemName' style={{ color: '#AFAFAF', backgroundColor: 'white', fontSize: 'small', display: !x.matches ? 'inline-block' : 'none', fontSize: 11, padding: '0px 0px 0px 1px' }}>ver más...</Label >} flowing hoverable position='top center'>
                                                                        {/* <Popup.Header> <div className="card-header text-white" style={{backgroundColor: 'red'}}>Lotes seleccionados</div></Popup.Header> */}
                                                                        <Popup.Content style={{ width: 150, overflow: 'auto' }}>
                                                                            <span className='ItemName'>{item.ItemName}</span>
                                                                        </Popup.Content>
                                                                    </Popup>
                                                                </div>
                                                                : item.ItemName
                                                            : " "}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className=" card-title text-left" style={{ fontSize: 15, padding: 1 /*marginLeft: 2,fontSize: 18, paddingBottom: 0, marginBottom: 0,*/ }}>
                                                        <span style={{ color: "#AFAFAF" }}>Stock: </span>
                                                        <span className="font-weight-bold" style={{ color: "#1B2340" }}>{item.OnHand != null ? Number(item.OnHand).toFixed(2) : 'Sin stock'}</span>
                                                    </p>
                                                </div>
                                                <div style={{ marginTop:'10px' }}>
                                                {item.OnHandPrincipal <= 0 && (
                                                    <div className='m-1 shake-top' style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                                                        <button
                                                            style={{ display: 'flex', borderRadius: '50px', border: '1px solid #ccc' }}
                                                            type="button"
                                                            className="btn rounded-pill pl-3 pr-3"
                                                            onClick={() => { window.open("https://api.whatsapp.com/send?phone=526671180507", '_blank') }}
                                                        >
                                                            <img src={config.icons.iconoW} alt="WhatsApp" style={{ marginRight: '5px', maxWidth: '40px', maxHeight: '30px', marginTop:'1px'}} />
                                                            <span style={{ fontSize: '12px' }}>Escríbenos por WhatsApp</span>
                                                        </button>
                                                    </div>
                                                )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{ padding: 0, border: 'none',position:'relative'/* marginTop: "21px",borderTop: "transparent", marginTop:"14px"*/ }}>
                                            <div className={item.U_FMB_Handel_Ent_Tienda === '1' && item.OnHandPrincipal > 0 ? "row text-center just-store-icon" : "row text-center just-store-icon hide"}>
                                                <img src={config.icons.justStoreIcon} style={{ maxWidth: '50px' }} />
                                            </div>
                                            <div className="row text-center" style={{ margin: 0, padding: 0 }}>
                                                {item.U_web === 0 || item.U_web === null ?
                                                    <div className="col-12" style={{ padding: 0 }}>
                                                        <label style={{ textAlign: "center", fontSize: 13 }}>Llámenos o envíe un correo para cotización</label>
                                                    </div> :
                                                    (item.OnHandPrincipal > 0 && ( // Verifica si hay stock disponible
                                                        <div className="col-3 cantidad" style={{ padding: 0 }}>
                                                            {item.Unidades === "Unidades" ? (
                                                                <input
                                                                // disabled={!(!!item.OnHandPrincipal)}
                                                                id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                type="number"
                                                                min="1"
                                                                value={item.quantity ? Number(item.quantity).toFixed(0) : ''}
                                                                className="form-control"
                                                                name={'quantity' + item.ItemCode}
                                                                placeholder="1"
                                                                style={{ textAlign: "left", marginTop: 1, height: 38.5, width: "57px" }}
                                                                onChange={(event) => { this.changLocalQuantity(dashboard.toString() + index, item, event) }}
                                                            //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                            />
                                                            ) : (
                                                                <input
                                                                // disabled={!(!!item.OnHandPrincipal)}
                                                                id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                type="number"
                                                                min="1"
                                                                value={item.quantity ? item.quantity : ''}
                                                                className="form-control"
                                                                name={'quantity' + item.ItemCode}
                                                                placeholder="1"
                                                                style={{ textAlign: "left", height: 38.5 }}
                                                                onChange={(event) => { this.changLocalQuantity(dashboard.toString() + index, item, event) }}
                                                            //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                            />
                                                            )}
                                                        </div>
                                                    ))
                                                }
                                                {item.U_web === 0 || item.U_web === null || item.OnHandPrincipal <= 0 ? (
                                                    <div><label style={{ textAlign: "center", fontSize: 16 }}></label></div>
                                                ) : (
                                                    <div className="col-5 botonAgregar">
                                                        <div>
                                                            <button style={{ textAlign: "center", marginLeft: "-4px" }}
                                                                type="button"
                                                                readOnly={item.OnHandPrincipal === 0 || item.OnHandPrincipal === ''}
                                                                className={item.OnHandPrincipal === 0 ? "btn btn-block botonAgregarAgotado" : item.flag === 'green' ? "btn btn-block botonAgregar" : "btn btn-block botonAgregarAgotado"}
                                                            value={(item.quantity ? Number(item.quantity) : '1')}
                                                            onClick={(event) => { this.changeQuantity(index, item, event) }}
                                                            onMouseOver={(event) => { this.mouseOverAddCart(dashboardForTwoSliders.toString() + index, item.flag, event) }}
                                                            onMouseOut={(event) => { this.mouseOutAddCart(dashboardForTwoSliders.toString() + index, item.flag, event) }}
                                                        >
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                                {item.U_web !== 0 && item.U_web !== null && item.OnHandPrincipal > 0 && (
                                                    <div className="icoCarrito col-3 text-center align-middle" style={{ fontSize: 33, padding: 0, textAlign: "right", alignSelf: "center", alignSelf: "center" }}>
                                                    <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() + index] = iconMouse} src={config.shoppingCartIcons.carritoAgotado} style={{ display: "block", color: "red", cursor: "pointer" }} />
                                                    <img ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() + index] = iconMouseOver} src={config.shoppingCartIcons.camionAgotado} style={{ display: "none", color: "red", cursor: "pointer" }} />
                                                </div>
                                                )}
                                                {item.U_web !== 0 && item.U_web !== null && item.OnHandPrincipal > 0 && (
                                                    <div className="icoCarrito col-3 text-center align-middle" style={{ fontSize: 33, padding: 0, textAlign: "right", alignSelf: "center", alignSelf: "center" }}>
                                                    <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() + index] = iconMouse} src={item.flag === 'green' ? config.shoppingCartIcons.carritoVerde : config.shoppingCartIcons.carritoAmarillo} style={{ display: "block", cursor: "pointer" }} />
                                                    <img ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() + index] = iconMouseOver} src={item.flag === 'green' ? config.shoppingCartIcons.camionVerde : config.shoppingCartIcons.camionAmarillo} style={{ display: "none", cursor: "pointer" }} />
                                                    </div>
                                                )}
                                                {/* {(  (item.U_web !== 0 && item.U_web !== null) 
                                                    && (item.OnHandPrincipal <= 0 || item.OnHandPrincipal == ''))
                                                    &&
                                                        <div className="icoCarrito col-3 text-center align-middle" style={{fontSize: 33,padding:0, textAlign: "right",alignSelf:"center"}}>
                                                            <img 
                                                                // ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() +index] = iconMouse} 
                                                                src={config.shoppingCartIcons.carritoAgotado} 
                                                                style={{display: "block", color: "red", cursor: "pointer"}}
                                                            />
                                                            <img 
                                                                // ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() +index] = iconMouseOver} 
                                                                src={config.shoppingCartIcons.camionAgotado} 
                                                                style={{display: "none", color: "red", cursor: "pointer"}}
                                                            />
                                                        </div>
                                                } */}

                                                {/* {((item.U_web !== 0 && item.U_web !== null)
                                                    && (item.flag === 'green' || item.flag === 'yellow'))
                                                    // && item.OnHandPrincipal > 0 
                                                    && <div className="icoCarrito col-3 text-center align-middle" style={{ fontSize: 33, padding: 0, textAlign: "right", alignSelf: "center" }}>
                                                        <img
                                                            // ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() +index] = iconMouse} 
                                                            src={item.flag === 'green' ? config.shoppingCartIcons.carritoVerde : config.shoppingCartIcons.carritoAmarillo}
                                                            style={{ display: "block", cursor: "pointer" }}
                                                            readOnly={item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false}
                                                            value={(item.quantity ? Number(item.quantity) : 1)}
                                                            onClick={(event) => { this.directShopping(index, item, event) }} />
                                                        <img
                                                            // ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() +index] = iconMouseOver} 
                                                            src={item.flag === 'green' ? config.shoppingCartIcons.camionVerde : config.shoppingCartIcons.camionAmarillo}
                                                            style={{ display: "none", cursor: "pointer" }}
                                                            readOnly={item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false}
                                                            value={(item.quantity ? Number(item.quantity) : 1)}
                                                            onClick={(event) => { this.directShopping(index, item, event) }}
                                                        />
                                                    </div>
                                                } */}
                                            </div>
                                            <div className="row text-center" style={{ margin: 0, padding: 0 }}>
                                                {item.U_web === 0 || item.U_web === null
                                                    ? item.wishlist === 1 &&
                                                    <div className="col-12" style={{ padding: 0 }}>
                                                        <label style={{ textAlign: "center", fontSize: 16 }}></label>
                                                    </div>
                                                    : item.wishlist === 1 &&
                                                    <div className="col-10">
                                                        <label style={{ textAlign: "center", marginTop: 12, fontSize: 14 }}>Lista de deseos</label>
                                                    </div>
                                                }
                                                {item.U_web === 1 &&
                                                    item.wishlist === 1 &&
                                                    <div className="col-2" style={{ color: config.navBar.textColor2, fontSize: 20, padding: 0 }}>
                                                        {item.backOrder
                                                            ? <i className={config.icons.backOrderFalse} style={{ cursor: "pointer", marginLeft: 7, marginTop: 15 }} onClick={() => changeBackOrder(item, false)} />
                                                            : <i className={config.icons.backOrderTrue} style={{ cursor: "pointer", marginLeft: 7, marginTop: 15 }} onClick={() => changeBackOrder(item, true)} />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
        setNextPage: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemSlider);

