import React, { Component } from 'react';
import {DISPATCH_ID, SERVICE_RESPONSE, config, ROLES,VIEW_NAME, SERVICE_API} from '../libs/utils/Const';
import {connect} from 'react-redux';
import {ApiClient} from "../libs/apiClient/ApiClient";
import $ from 'jquery';
import moment from 'moment';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

let apiClient = ApiClient.getInstance();
const responsiveMarcas = {
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 7,
      partialVisibilityGutter: 0 // this is needed to tell the amount of px that should be visible.
    },
    desktop1: {
        breakpoint: { max: 1500, min: 1024 },
        items: 5,
        partialVisibilityGutter: 0 // this is needed to tell the amount of px that should be visible.
      },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
      partialVisibilityGutter: 0 // this is needed to tell the amount of px that should be visible.
    },
    tablet1: {
        breakpoint: { max: 768, min: 576 },
        items: 3,
        partialVisibilityGutter: 0 // this is needed to tell the amount of px that should be visible.
      },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      partialVisibilityGutter: 0 // this is needed to tell the amount of px that should be visible.
    }
  }

  class CarouselTrademarks extends Component {
    getBrand = async (brand) =>{
        const {itemsReducer, setItemsFilters,setLocation } = this.props;
        let data = {property : 'fabrica', value : brand, value2:''}
        setLocation('menuMarcas')
        setItemsFilters(data);
        setTimeout(async() => {
            await itemsReducer.searchByKey();
        }, 250); 
    }

    render() {
        const { itemsReducer: {brandBanner}} = this.props
        return (
            
            <Carousel className="carouselMarcas" style={{ justifyContent: "center", alignItems: "center"}} autoPlay infinite={true} draggable partialVisible={true} responsive={responsiveMarcas} autoPlaySpeed={5000} removeArrowOnDeviceType={["desktop","tablet", "mobile"]}>   
                {brandBanner.map((brand, index) => {
                    return (
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto",cursor:'pointer'}}>
                        <img className="img-fluid"
                            src={config.ASSETS + 'marcas/' + brand.image}
                            alt={brand.image}
                            onClick={()=> this.getBrand(brand.brand)}
                        />
                    </div>
                    );
                })}
                
            </Carousel>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setItemsFilters: value => dispatch({type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value}), 
        setLocation: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_LOCATION, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CarouselTrademarks);