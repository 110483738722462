let local = "Standard";

export default {
    general: {
        titleWindowsExplorer: 'SERDI - La Fuerza del Acero',
        iconWindowsExplorer: './images/favicon.png',
        loaderImage: 'Cargando...', //require('./images/loader/831.gif'),
        loaderBackground: 'rgba(215,160,0, 0.1)',
        loaderColor: 'text-dark',
        business: 1,
        imagesPath: 'redhogar',
        currency: ' MXN ',
        localStorageNamed: local + 'E-commerce',
    },

    icons: {
        add: "fa fa-plus",
        //menu: 'fas fa-bars fa-2x',
        user: 'fas fa-user',
        password: 'fa fa-unlock-alt',
        search: 'fas fa-search fa-2x',
        shoppingCart: 'fas fa-shopping-cart fa-7x',
        signature: 'fa fa-signature',
        building: 'fa fa-building',
        phone: 'fa fa-phone',
        pin: 'fa fa-map-pin',
        road: 'fa fa-road',
        map: 'fa fa-map',
        city: 'fa fa-city',
        envelope: 'fa fa-envelope-open-text',
        globalAmericas: 'fa fa-globe-americas',
        shield: 'fa fa-shield',
        favorite: "fas fa-heart",
        trash: "fa fa-trash",
        backOrderTrue: 'fa fa-plus',
        backOrderFalse: 'fa fa-minus',
        arrowLeft: 'fas fa-caret-left',
        arrowRight: 'fas fa-caret-right',
        datasheet: 'far fa-file-pdf fa-2x',
        word: 'fas fa-file-word fa-2x',
        cfdi: 'fas fa-file-invoice-dollar',
        report: 'fas fa-file-pdf',
        xml: 'fas fa-file-alt',
        detail: 'fas fa-info-circle',
        suscription: 'fas fa-chevron-circle-right',
        select: 'fas fa-check',
        whatsapp: 'fab fa-whatsapp',
        justStoreIcon: require('../../images/standard/iconos/entrega_en_tienda.svg'),
        iconochat: require('../../images/standard/iconos/chat.svg'),
        iconoW: require ('../../images/standard/NwSerdi/whatsappico-01.svg')
    },

    trademarks: {
        marcasRegistradas: require('../../images/standard/trademarks.png'),
        mejoresMarcas: require('../../images/standard/icono_Marcas.svg'),
        enviosRepublica: require('../../images/standard/iconos_Envios.svg'),
        comprasSeguras: require('../../images/standard/iconos_compra segura.svg'),
        prmoRed: '',
        novedades: '',
        buscados: '',
        disponible: '',
    },

    Valores: {
        compromiso: require('../../images/standard/NwSerdi/compromiso_Uno.svg'),
        perseverancia: require('../../images/standard/NwSerdi/perseverancia_Dos.svg'),
        responsabilidad: require('../../images/standard/NwSerdi/responsabilidad_Tres.svg'),
        respeto: require('../../images/standard/NwSerdi/respeto_Cuatro.svg'),
        honestidad: require('../../images/standard/NwSerdi/honestidad_Cinco.svg'),
    },

    loginView: {
        primaryColor: '#00245D',
        secondaryColor: '#1B2340',
        backgroundColor: '#fff',
        specialTextColor: '#aba9a9',
        textSecondColor: '#fff'
    },

    itemDetailsView: {
        primaryColor: '#002877',
        secondaryColor: '#212E57',
        backgroundColor: '#fff',

    },
    reportView: {
        primaryColor: '#1B2340',
        secondaryColor: '#1B2340',
        backgroundColor: '#fff',
        textColor: '#000',
        textSecondColor: '#fff',
    },
    shoppingCartView: {
        primaryColor: '#1B2340',
        secondaryColor: '#1B2340',
        backgroundColor: '#fff',
        textColor: '#000',
        textSecondColor: '#fff',
    },

    navBar: {
        icon: require('../../images/standard/LOGO COLOR_navbar.svg'),
        icono2Login: require('../../images/standard/logotipo2Login.svg'),
        primaryColor: '#00245D',
        secondaryColor: '#1B2340',
        backgroundColor: '#fff',
        iconColor: '#212E57',
        iconColor2: '#FFFFFF',
        iconColor3: '#666666',
        iconColorPaginacion: "#E3E3E3",
        textColorPaginacion: "#666666",
        textColor: 'black',
        textColor2: 'white',
        textColor3:'#7C7D7D',
        textColor4:'#0D62A8',
        textColor5:'#222F54',
        textColor6:'#7C7C7D',
        menuCategoriesBackground: '#00245D',
        textColorCategorie: '#022635',
        menuCategoriesBackgroundHover: '#1B2340',
        textColorCategorieHover: 'black',
        iconModal: 'white',
        iconBackground: '#1B2340',
        carrito: require('../../images/standard/icon_Carrito.svg'),
        avatar: require('../../images/standard/navBar/avatar.svg'),
        avatar2: require('../../images/standard/navBar/avatar2.svg'),
        quote: require('../../images/standard/busqueda-01.svg'),
        secundaryNavbar: require('../../images/standard/navbarIcons/secundaryNavBar.svg'),
        whiteMenu: require('../../images/standard/navbarIcons/menu_blanco.svg'),
        ubicacion: require('../../images/standard/No utilizados/ubicacion-01.svg'),
        carrito_naranja: require('../../images/standard/iconos/carrito_naranja.svg'),
        iconoSerdi: require('../../images/standard/iconos/iconoSerdi.svg'),
        menu_hamburguesa: require('../../images/standard/iconos/menu_hamburguesa.svg'),
        catalogo: require('../../images/standard/iconos/catalogo-01.svg')
    },

    shoppingCartIcons: {
        camionAgotado: require('../../images/standard/iconosCarrito/carrito-01.png'),
        camionVerde: require('../../images/standard/iconosCarrito/carrito-02.png'),
        camionAmarillo: require('../../images/standard/iconosCarrito/carrito-03.png'),
        carritoVerde: require('../../images/standard/iconosCarrito/carrito-04.png'),
        carritoAgotado: require('../../images/standard/iconosCarrito/carrito-05.png'),
        carritoAmarillo: require('../../images/standard/iconosCarrito/carrito-06.png')
    },

    

    openPay: {
        credit: require('../../views/validateOrder/cards1.png'),
        debit: require('../../views/validateOrder/cards2.png'),
        logo: require('../../views/validateOrder/openpay.png'),
        security: require('../../views/validateOrder/security.png'),
    },
    selectAddress:{
        paypalImage: require('../../images/standard/pasarelasPago/paypal.png'),
        mercadoPagoImage: require('../../images/standard/pasarelasPago/mercadoPago.png'), 
        mitPayment: require('../../images/standard/pasarelasPago/MIT tipografia.svg'),
    },
   
    dataSucursal: [
        // {
        //     imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
        //     name: 'Sucursal Matriz',
        //     address: 'Convención de Apatzingán 660, Emiliano Zapata, 80260 Culiacán Rosales, Sinaloa, Mexico.',
        //     zipcode: '80260',
        //     block: 'Sinaloa',
        //     phones: ['667 718 4568'],
        //     direccion:'Serdi Aceros Corporativo, Convención de Apatzingán 660, Emiliano Zapata, 80260 Culiacán Rosales, Sin.'
        // },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Sucursal Matriz',
            address: 'Av Ejército Nacional 1059-S, Emiliano Zapata, 80260 Culiacán Rosales, Sin.',
            zipcode: '80260',
            block: 'Sinaloa',
            phones: ['667 118 0507'],
            direccion:'SERDI ACEROS, Av Ejército Nacional 1059-S, Emiliano Zapata, 80260 Culiacán Rosales, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. KZ4',
            address: 'Venustiano Carranza #7-A, Col. Centro, 80010 Culiacán Rosales, Sinaloa, Mexico.',
            zipcode: '80010',
            block: 'Sinaloa',
            phones: ['667 283 3812'],
            direccion:'Serdi Aceros Culiacán kz4, Venustiano Carranza #7-A, Col. Centro, 80010 Culiacán Rosales, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Aeropuerto',
            address: 'Calzada Aeropuerto # 5500, Col. Centro, Culiacán Rosales, Sinaloa, Mexico.',
            zipcode: '80150',
            block: 'Sinaloa',
            phones: ['667 760 3150'],
            direccion:'SERDI ACEROS - AEROPUERTO CULIACAN, Calz. Aeropuerto #5500, Gasolinera del Valle, San Rafael, 80178 Culiacán Rosales, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. 6 de Enero',
            address: 'Av Alvaro obregon Norte # 3334, Col. 6 de Enero, Culiacán Rosales, Sinaloa, Mexico.',
            zipcode: '80010 ',
            block: 'Sinaloa',
            phones: ['667 753 6990'],
            direccion:'Serdi 6 de Enero, Prol. Álvaro Obregón 3334, 6 de Enero, 80010 Culiacán Rosales, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Buenos Aires',
            address: 'BLVD. MANUEL J. CLOUTIER # 3780 SUR COL. BUENOS AIRES, Culiacán Rosales, Sinaloa, Mexico.',
            zipcode: '80199',
            block: 'Sinaloa',
            phones: ['667 744 6999'],
            direccion:'Serdi Aceros Culiacán buenos aires, Av. Manuel J. Clouthier 3780, Buenos Aires, 80199 Culiacán Rosales, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Agricultores',
            address: 'FRAY DE TORQUEMADA # 550 SUR COL. GUADALUPE VICTORIA, Culiacán Rosales, Sinaloa, Mexico.',
            zipcode: '80220',
            block: 'Sinaloa',
            phones: ['667 762 5060'],
            direccion:'Serdi Aceros Culiacán Agricultores, Fray Juan de Torquemada #550 sur, San Juan, Guadalupe Victoria, 80220 Culiacán Rosales, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Barrancos',
            address: 'ALBERTO TERRONES # 4595 COL. AQUILES SERDAN, Culiacán Rosales, Sinaloa, Mexico.',
            zipcode: '80190',
            block: 'Sinaloa',
            phones: ['667 745 1900'],
            direccion:'Serdi Aceros Culiacán barrancos, Constituyente Alberto Terrones B. 4595, Díaz Ordaz, Aquiles Serdán, 80190 Culiacán Rosales, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Patria',
            address: 'Av. Patria # 4931-1, Antonio Toledo Corro, 80296 Culiacán Rosales, Sinaloa, Mexico.',
            zipcode: '80296',
            block: 'Sinaloa',
            phones: ['667 718 7862'],
            direccion:'Serdi Aceros Culiacán patria, Av. Patria # 4931-1, Antonio Toledo Corro, 80296 Culiacán Rosales, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Ranchito',
            address: 'Av. Álvaro Obregón #6 31 Ejido El Ranchito, Culiacán Rosales, Sinaloa, Mexico.',
            zipcode: '80139',
            block: 'Sinaloa',
            phones: ['667 257 4009'],
            direccion:'Serdi Aceros Culiacán ranchito, Av Alvaro Obregon # 631 Norte, El ranchito, 80139 Culiacán Rosales, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Sendero',
            address: 'CARRETERA INTERNACIONAL S/N LOTE 12 MANZANA 11 COL. LOS LAURELES, Culiacán Rosales, Sinaloa, Mexico.',
            zipcode: '80027',
            block: 'Sinaloa',
            phones: ['667 754 8254'],
            direccion:'Serdi Aceros sendero, Carretera int, S/N, lote 12, manzana 11, Los Laureles, 80027 Culiacán Rosales, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Guamuchil',
            address: 'BLVD. FRANCISCO LABASTIDA O. # 1638 CARRETERA A ANGOSTURA MAGISTERIO GUAM, Culiacán Rosales, Sinaloa, Mexico.',
            zipcode: '81470',
            block: 'Sinaloa',
            phones: ['673 141 3199'],
            direccion:'Serdi Aceros Guamúchil, Blvd. Francisco Labastida O. # 1638 Carr, A Angostura, Magisterio, 81470 Guamúchil, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Navolato',
            address: 'JESUS ALMADA # 935 PTE. COL. HERIBERTO ZAZUETA NAVOLATO, Sinaloa, Mexico.',
            zipcode: '80327',
            block: 'Sinaloa',
            phones: ['672 727 3232'],
            direccion:'Serdi Aceros Navolato, Jesus Almada # 935 pte, Heriberto Zazueta, 80327 Navolato, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Guasave',
            address: 'Blvd. Juan S. millan # 716 PTE, Las Palmillas, 81048 Guasave, Sinaloa, Mexico.',
            zipcode: '81048',
            block: 'Sinaloa',
            phones: ['687 883 6660'],
            direccion:'Serdi Aceros Guasave, Blvd. Juan S. millan # 716 PTE, Las Palmillas, 81048 Guasave, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Mazatlan',
            address: 'CARRETERA INTERNACIONAL KM.2 MAZATLAN, Sinaloa, Mexico.',
            zipcode: '82150',
            block: 'Sinaloa',
            phones: ['669 984 6084'],
            direccion:'SERDI ACEROS MAZATLAN, Internacional KM 2, Plaza San Ignacio, 82150 Mazatlán, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Benito Juarez',
            address: 'BLVD. GABRIEL LEYVA # 25 ESQ. CON REVOLUCION COL. JUAREZ MAZATLAN, Sinaloa, Mexico.',
            zipcode: '82180',
            block: 'Sinaloa',
            phones: ['669 112 5354'],
            direccion:'Serdi Aceros Mazatlán Juárez, Esq. Con Revolución, Av. Gabriel Leyva 25, Loma de Juárez, Benito Juárez, 82180 Mazatlán, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Jaripillo',
            address: 'Av Óscar Pérez Escobosa 2314, Jaripillo, 82136 Mazatlán, Sinaloa, Mexico',
            zipcode: '82136',
            block: 'Sinaloa',
            phones: [''],
            direccion:'Serdi Jaripillo, Av Óscar Pérez Escobosa 2314, Jaripillo, 82136 Mazatlán, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Los Mochis',
            address: 'AV. 10 DE MAYO # 550 PTE., COL. EJIDO JIQUILPAN LOS MOCHIS, Sinaloa, Mexico.',
            zipcode: '81248',
            block: 'Sinaloa',
            phones: ['668 812 1240'],
            direccion:'Serdi Aceros los Mochis 10 de mayo, Av. 10 de mayo # 550 Pte. Entre Angel Flores y Vicente guerrero Ejido, Jiquilpan, 81248 Los Mochis, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Alamos',
            address: 'BLVD. MACARIO GAXIOLA # 1395 SUR. FRACC. ALAMOS 1 LOS MOCHIS, Sinaloa, Mexico.',
            zipcode: '81285',
            block: 'Sinaloa',
            phones: ['668 818 0590'],
            direccion:'Serdi Aceros los Mochis álamos, Blvd. Mario Gaxiola # 1395 Sur, Fracc. Alamos, 81285 Los Mochis, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. La 35 Independencia',
            address: 'AV. INDEPENDENCIA # 1692 PTE. LOS MOCHIS, Sinaloa, Mexico.',
            zipcode: '81248',
            block: 'Sinaloa',
            phones: ['668 812 2775'],
            direccion:'Serdi Aceros los Mochis independencia, Independencia 1692 pte. Entre palma y dren juarez, Olivos Fracc, 81248 Los Mochis, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Juan José Ríos',
            address: 'Calle 9, Esquina Batequis Colonia Juan José Ríos, 81110 Los Mochis, Sinaloa, Mexico.',
            zipcode: '81110',
            block: 'Sinaloa',
            phones: [''],
            direccion:'Serdi Aceros Juan José Ríos, Calle 9, Esquina Batequis Colonia Juan José Ríos, 81110 Los Mochis, Sin.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. La Cruz',
            address: 'Centro, Aquiles Serdán y o Av. Poniente 0 16, Centro, 82700 La Cruz, Sin.',
            zipcode: '82700',
            block: 'Sinaloa',
            phones: ['696 961 0555'],
            direccion:'Serdi Aceros La Cruz, Centro, Aquiles Serdán y o Av. Poniente 0 16, Centro, 82700 La Cruz, Sin.'
        },




        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Hermosillo',
            address: 'BLVD. GARCIA MORALES # 935 PTE. COL. EL LLANO HERMOSILLO, Sonora, Mexico.',
            zipcode: '85210',
            block: 'Sonora',
            phones: ['662 261 0850'],
            direccion:'Serdi Acero Hermosillo, Blvd. Garcia morales # 935 Pte, El llano, 85210 Hermosillo, Son.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Obregón',
            address: 'DR. NORMAN E. BORLAUG # 3317 SUR COL.MUNICIPIO LIBRE OBREGON, Sonora, Mexico.',
            zipcode: '85080',
            block: 'Sonora',
            phones: ['644 416 7725'],
            direccion:'Serdi, Blvd. Dr. Norman E. Borlaug 3317, Las Campanas, 85080 Cdad. Obregón, Son.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Guaymas',
            address: 'BLVD. AGUSTIN GARCIA LOPEZ # 875 COL. DELICIAS GUAYMAS, Sonora, Mexico.',
            zipcode: '85456',
            block: 'Sonora',
            phones: ['622 222 2011'],
            direccion:'Serdi Aceros Guaymas, Carr. Internacional km 1982, Petrolera, 85456 Guaymas, Son.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Solidaridad',
            address: 'Blvd. Solidaridad 805, Solidaridad, 83116 Hermosillo, Son.',
            zipcode: '83116',
            block: 'Sonora',
            phones: ['662 118 6767 / 662 118 6768'],
            direccion:'SERDI ACEROS SOLIDARIDAD, Blvd. Solidaridad 805, Solidaridad, 83116 Hermosillo, Son..'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Agua Prieta',
            address: 'CALLE #27 ESQUINA CON AV. 6 COL. EJIDAL # 651 AGUAPRIETA, Sonora, Mexico.',
            zipcode: '84200',
            block: 'Sonora',
            phones: ['633 126 8570'],
            direccion:'SERDI AGUA PRIETA, CARRETERA Y, Av 6 2, Ejidal, 84200 Agua Prieta, Son.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Nogales',
            address: 'AV. TECNOLOGICO # 675 COL. LA GRANJA NOGALES, SONORA, Sonora, Mexico.',
            zipcode: '84065',
            block: 'Sonora',
            phones: ['631 319 2020'],
            direccion:'Serdi Aceros Nogales, Av. Tecnológico 675, Las Huertas, Granja, 84065 Heroica Nogales, Son.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Puerto Peñasco',
            address: 'JOSEFINA ORTIZ DE DOMINGUEZ # 252 Y LOPEZ MATEOS, Sonora, Mexico.',
            zipcode: '83556',
            block: 'Sonora',
            phones: ['638 388 7793'],
            direccion:'Serdi Aceros Puerto Peñasco, Josefina Ortiz de Dominguez # 252 y lopez mateos Centro Sur, 83556 Puerto Peñasco, Son.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Navojoa',
            address: 'Lázaro Cárdenas del Río 808, Francisco Villa, 85880 Navojoa, Son.',
            zipcode: '85880',
            block: 'Sonora',
            phones: ['642 439 4448'],
            direccion:'Serdi Aceros Navojoa, Lázaro Cárdenas del Río 808, Francisco Villa, 85880 Navojoa, Son.'
        },




        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Mexicali',
            address: 'CALZADA LAZARO CARDENAS # 4199 COL. ISLAS AGRARIAS "A" MEXICALI, Baja california, Mexico.',
            zipcode: '21370',
            block: 'Baja california Norte',
            phones: ['686 580 7521'],
            direccion:'Serdi Aceros Mexicali, Blvd. Lázaro Cárdenas #4199, Hacienda del Sol, 21370 Mexicali, B.C.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Villa Fontana',
            address: 'BLVD. LAZARO CARDENAS # 1101 FRACC. VILLA FONTANA MEXICALI, Baja california, Mexico.',
            zipcode: '21370',
            block: 'Baja california Norte',
            phones: ['686 557 7222'],
            direccion:'Serdi Aceros Villa fontana, Blvd Lázaro Cárdenas #1101, Villafontana, 21370, Villafontana, 21180 Mexicali, B.C.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Independencia',
            address: 'BLVD. LAZARO CARDENAS # 1123 COL. INDEPENDENCIA MEXICALI, Baja california, Mexico.',
            zipcode: '21600',
            block: 'Baja california Norte',
            phones: ['686 565 5503'],
            direccion:'Serdi Aceros independencia, Blvd. Lázaro Cárdenas # 1123, Independencia, 21600 Mexicali, B.C.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Ensenada',
            address: 'AV. REFORMA NO.130 FRACC. LOMA LINDA, Baja california, Mexico.',
            zipcode: '22890',
            block: 'Baja california Norte',
            phones: ['646 182 9260'],
            direccion:'Serdi Aceros Ensenada, Fracc. Loma linda, avenida reforma, número exterior 130, 22890 Ensenada, B.C.'
        },

        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Tijuana',
            address: 'Blvd. Cucapah 21847, entre LAS TORRES Y BUGAMBILIAS, Villafontana, Lomas del Matamoros, 22206 Tijuana, B.C.',
            zipcode: '22206',
            block: 'Baja california Norte',
            phones: ['664 454 1556'],
            direccion:'Serdi Aceros Tijuana, Blvd. Cucapah 21847, entre LAS TORRES Y BUGAMBILIAS, Villafontana, Lomas del Matamoros, 22206 Tijuana, B.C.'
        },



        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. La paz',
            address: 'BLVD. AGUSTIN OLACHEA ENTRE JALISCO Y COLIMA S/N LOTE # 006 LA PAZ, Baja california Sur, Mexico.',
            zipcode: '23060',
            block: 'Baja california Sur',
            phones: ['612 122 5050'],
            direccion:'Serdi Aceros, Prof. Marcelo Rubio Ruiz 3735, Pueblo Nuevo, 23060 La Paz, B.C.S.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Cabo San Lucas',
            address: 'CALLE. LEONA VICARIO COL. LAS LAGUNITAS CABO SAN LUCAS, Baja california Sur, Mexico.',
            zipcode: '23462',
            block: 'Baja california Sur',
            phones: ['624 146 6400'],
            direccion:'Serdi Aceros cabo san lucas, Av. Leona Vicario Esq. Con calle rio jamapa, entre rio bravo y rio papaloapan, Lagunitas, 23462 B.C.S.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. San Jose del Cabo',
            address: 'CARRETERA TRANSPENINSULAR S/N CALLE JALISCO COL. SAN JOSÉ VIEJO, SAN JOSÉ DEL CABO, Baja california Sur, Mexico.',
            zipcode: '23443',
            block: 'Baja california Sur',
            phones: ['624 146 0202'],
            direccion:'Serdi Aceros san José, Carr. Transpeninsular S/N, Entre call alisco y nayarit, San José Viejo, 23443 San José del Cabo, B.C.S.'
        },
        {
            imagen: require('../../images/standard/sucursales/sucursales-icono.png'),
            name: 'Suc. Constitución',
            address: 'BLVD. A. OLACHEA ESQ. CON GUADALUPE VICTORIA S/N COL. CENTRO, Baja california Sur, Mexico.',
            zipcode: '23600',
            block: 'Baja california Sur',
            phones: ['613 132 0068'],
            direccion:'Serdi Aceros cd. Constitución, Blvd. A. Olachea Esq. Con Guadalupe Victoria s/n, Centro, 23600 B.C.S.'
        },
    ],

    Back: {
        backgroundColor: "#fff"
    },

    BarraTelefono: {
        Whats: require('../../images/standard/barraTelefono/whatsapp-verde.svg'),
        IconCel: require('../../images/standard/barraTelefono/Telefono.png'),
    },

    dashboard: 'Carousel',

    slider: {
    },

    carousel: {
        images: []
    },

    aboutUs: {
        active: true,
        description: `Somos el grupo distribuidor de acero y materiales para la construcción, con el más rápido crecimiento en el Noroeste de México. Con oficinas Corporativas, tienda Matriz y 9 sucursales en Culiacán, contamos con presencia en Mazatlán, Navolato, Guasave, Guamúchil, Los Mochis, La Cruz de Elota, y sucursales ubicadas en los estados de  Sonora, Baja California y Baja California Sur.
Serdi es un ejemplo de esfuerzo, dedicación, preparación y  permanente interacción humana entre directivos, personal operativo y administrativo de la empresa. 
Nuestro éxito comercial se basa 100% en el servicio al cliente, apoyándonos con infraestructura y un equipo de expertos, que día a día trabajan para alcanzar las metas y objetivos que la fortalecen, y la posicionan en la preferencia de los mercados.`,
        mission: 'Lograr la satisfacción del cliente, mediante la optimización de recursos  materiales y capital humano, ofreciendo el mejor servicio y calidad en nuestros productos, creando una mejor expectativa de desarrollo y crecimiento de nuestros colaboradores dentro de la empresa.',
        vision: 'Ser la empresa líder en el noroeste del País, al contar con 50 unidades de negocio, en distribución y comercialización de aceros, materiales para construcción y ferretería.',
        values: ["Honestidad", "Responsabilidad", "Responsabilidad", "Perseverancia", "Respeto"],
        images: require('../../images/standard/about/quienes-somos.png'),
    },

    contactUs: {
        active: true,
        iconColor: '#1B2340',
        locations: [
            {
                name: 'Oficina Centrales',
                street: 'Av Ejército Nacional 1059-S, Emiliano Zapata, 80260 Culiacán Rosales, Sinaloa, Mexico.',
                country: 'Culiacán Rosales, Sinaloa, Mexico.',
                phone: '800 737 3469',//Whatsapp: 3313207614
                email: 'atencion.aclientes@serdi.com.mx',
                maps: 'https://www.google.com/maps/dir/20.0291157,-98.7811645/24.78821,-107.36953/@22.2467232,-107.6136901,6z/data=!4m4!4m3!1m1!4e1!1m0',
                whatsappLink: 'https://api.whatsapp.com/send?phone=526671180507',
                whatsapp: '667 118 0507',
            }
        ]
    },

    claim: {
        active: false,
    },

    termPage: {
        active: false,
    },

    questions: {
        active: false,
        bannerImage: require('../../images/standard/preguntasFrecuentes/BANNER_PREGUNTAS FRECUENTES.png'),
    },

    itemsList: {
        backgroundColor: 'rgba(255,255,255, 0.5)',
        textColor: 'rgba(0,0,0, 0.8)',
        textColor2: '#212E57',
        iconColor: '#a91c17',
        textPrice: '#000000',
        textPrice2: '#666666',
    },

    shoppingList: {
        productList: '#1B2340',
        textProductList: 'white',
        summaryList: '#1B2340',
        textsummaryList: 'white'
    },

    pdf: {
        VERterminosCondiciones: true,
        terminosCondiciones: 'terminosCondiciones.pdf',
        VERpoliticasVentas: false,
        politicasVentas: 'politicasVentas.pdf',
        VERpoliticasEnviosGarantias: false,
        politicasEnviosGarantias: 'politicasEnviosGarantias.pdf',
        VERpoliticasGarantia: false,
        politicasGarantia: 'politicasGarantia.pdf',
        VERpoliticasUsoAvisoPrivacidad: true,
        politicasUsoAvisoPrivacidad: 'politicasPrivacidad.pdf',
        politicasRecompensas: 'politicasRecompensa.pdf',
        politicasFooter: 'politicasFooter.pdf',
        nosotros: 'nosotros.pdf',
        garantiasDevoluciones:'garantiasDevoluciones.pdf',
        catalogo:'Catalogo 2024.pdf'
    },

    footer: {
        icon: require('../../images/standard/DIASA footer.svg'),
        power: require('../../images/standard/footer/logofmb.svg'),
        backgroundColor: '#00245D',
        iconColor: '#666666',
        textColor: '#FFFFFF',   

        info: {
            hoursIcon: require('../../images/standard/footer/horario.png'),
            hours: 'Lunes a Viernes 8:00 am a 6:00 pm,  Sábado 8:00 am a 13:00 pm',
            calendar: "Lunes a Sabado",
            emailIcon: require('../../images/standard/footer/correo.png'),
            email: 'ecommerce@serdi.com.mx',
            // email2: 'ventas.digital@serdi.com.mx ',
            phoneIcon: require('../../images/standard/footer/telefono.png'),
            phone: '667 118 0507, 800 737 3469',
            addressIcon: 'fas fa-2x fa-location-arrow',
            address: 'Av Ejército Nacional 1059-S, Emiliano Zapata, 80260 Culiacán Rosales, Sinaloa, Mexico',
        },
        socialMedia2: [
            {
                visibility: true,
                icon: require('../../images/standard/footer/youtube.png'),
                linkref: 'https://www.youtube.com/channel/UC4XP4ue7vnL3ONazlY7R7jA',
            },
            {
                visibility: true,
                icon: require('../../images/standard/footer/instagram.png'),
                linkref: 'https://www.instagram.com/fuerzaserdi/',
            },
            {
                visibility: true,
                icon: require('../../images/standard/footer/facebook.png'),
                linkref: 'https://www.facebook.com/Serdi',
            },
            // {
            //     visibility: false,
            //     icon: 'fab fa-4x fa-twitter',
            //     linkref: 'https://twitter.com/gruporedhogar',
            // },
            {
                visibility: true,
                icon: require('../../images/standard/footer/Whatsapp.png'),
                linkref: 'https://api.whatsapp.com/send?phone=526671180507',
            },
            // {
            //     visibility: false,
            //     icon: require("../../images/standard/footer/linkedin.png"),
            //     linkref: 'https://www.linkedin.com/company/fmb-solutions',
            // },
            {
                visibility: true,
                icon: require("../../images/standard/footer/tiktok.png"),
                linkref: 'https://www.tiktok.com/@fuerzaserdi',
            },
        ],
    },

    paymentMethod: {
        paypal: {
            enable: false,
            clienId: 'AcgfSWjvFifar4P_3alUSPkhf0Z5g21gs1ieaFCF0HoFojz5GnYxYFE5L-uIH4tZEqWffcTzN_jnqf6l',
            //clienId: 'Aa2jAA2wV-jR9wXcDcaBnAatmI5nbANCx0TaQks_UWDAEKWAkuRwEvCap3krt7my-8FciPFo73rbOCJH',
        },
        transbank: {
            enable: false,
        },
        mercadoPago: {
            enable: false,
        },
        openPay: {
            enable: false,
        },
        mitPayment: {
            enable: true,
        },
        // Activar y desactivar pago sin pasarela
        unpaid: {
            enable: false,
        }
    },

    recompensas: {
        card: require('../../images/standard/card.png'),
        vitrina1: require('../../images/standard/vitrinaRedCompensas/icon-10.png'),
        vitrina2: require('../../images/standard/vitrinaRedCompensas/icon-11.png'),
        vitrina3: require('../../images/standard/vitrinaRedCompensas/icon-12.png'),
        vitrina4: require('../../images/standard/vitrinaRedCompensas/icon-13.png'),
        // vitrina5 : require('../../images/standard/vitrinaRedCompensas/icon-14.png'),
        // vitrina6 : require('../../images/standard/vitrinaRedCompensas/icon-15.png'),
    },
    Avatar: {
        silver1: '',
        gold1: '',
        platinum1: '',
        diamond1: '',
        mercado1: '',
        red1: '',

        bronze2: '',
        silver2: '',
        gold: '',
        platinum2: '',
        diamond2: '',
        mercado2: '',
        red2: '',
    },

    gif: {
        gif: '',
    },

    platilla: {
        arhivoPlantila: require('../../images/plantilla HANDEL.xlsx'),
        iconoExcel: require('../../images/standard/footer/excel.svg'),
        color: 'green'
    },

    banners: {
        newsBlogView: require('../../images/standard/bannerPaginas/BLOG_DE_NOTICIAS.png'),
        aboutUsView: require('../../images/standard/bannerPaginas/NOSOTROS.png'),
        asesoriaView: require('../../images/standard/bannerPaginas/ASESORIAS.png'),
        platillaView: require('../../images/standard/bannerPaginas/PLANTILLA.png'),
        aboutRedZoneView: require('../../images/standard/bannerPaginas/CLIENTE_PREFERENTE.png'),
        devolutionView: require('../../images/standard/bannerPaginas/DEVOLUCIONES.png'),
        itemsPolarView: require('../../images/standard/redPolar/Banner1.png'),
        itemsRoutletView: require('../../images/standard/ROutlet/Banner2.png'),
        itemsBlancaView: require('../../images/standard/lineaBlanca/Banner3.png'),
        itemsNumberFourView: require('../../images/standard/marcaCuatro/Banner4.png'),
        itemsNumberFiveView: require('../../images/standard/marcaCinco/Banner5.png'),
        jobView: require('../../images/standard/bolsaTrabajo/icon-01.png'),
        politicsView: require('../../images/standard/politicasVentas/icon-01.png'),
        privacyView: require('../../images/standard/avisoPrivacidad/AVISO-DE-PRIVACIDAD.png'),
        redCompensasZoneView: require('../../images/standard/AboutRecompensas/cliente preferente.png'),
        resetPointsView: require('../../images/standard/navBar/temporal.png'),
        paymentView: require('../../images/standard/metodosPago/BANNER_FORMAS DE PAGO.png'),
        safeShoppingView: require('../../images/standard/compraSegura/BANNER_PAGOS SEGUROS.png'),
        termsView: require('../../images/standard/avisoPrivacidad/icon-10.png'),
        redAliadoView: require('../../images/standard/bannerPaginas/ALIADOS.png'),
    },
    mailPDF: {
        agregar: require('../../images/standard/bolsaTrabajo/AgregarImagen.png'),
    },

    devoluciones: {
        imagen1: require('../../images/standard/devoluciones/Devoluciones-01.png'),
        imagen2: require('../../images/standard/devoluciones/Devoluciones-02.png'),
        imagen3: require('../../images/standard/devoluciones/Devoluciones-03.png'),
        imagen4: require('../../images/standard/devoluciones/Devoluciones-04.png'),
        imagen5: require('../../images/standard/devoluciones/Devoluciones-05.png'),
        imagen6: require('../../images/standard/devoluciones/Devoluciones-06.png'),
        imagen7: require('../../images/standard/devoluciones/Devoluciones-07.png'),
        imagen8: require('../../images/standard/devoluciones/Devoluciones-08.png'),
    },

    pasarela: {
        imagen1: require('../../images/standard/carrito/icon-01.png'),
        imagen2: require('../../images/standard/carrito/icon-02.png'),
        imagen3: require('../../images/standard/carrito/icon-03.png'),
        imagen4: require('../../images/standard/carrito/icon-04.png'),
        imagen5: require('../../images/standard/carrito/icon-05.png'),
        imagen6: require('../../images/standard/carrito/icon-06.png'),
        imagen7: require('../../images/standard/carrito/icon-07.png'),
        imagen8: require('../../images/standard/carrito/icon-08.png'),
    },

    modules: {
        points: false,
        pasarelaCarrito: false,
        redZone: false,
        redAliado: false,
        subirArchivoExcel: true,
        BolsaTrabajo: false,
        cupon: false,
        Comments: false,
        Devoluciones: false,
        Address: true,
        EditAddress: false,
        EditAddressSelectAddress: true,
        sendFilePDF: false,
        authorizations: true,
        editPriceAndDiscount: false,
        BackOrder: false,
        ChangeWareHouse: true,
    },


    // Configuracion de manera local
    // BASE_URL: 'http://localhost:3052/api',
    // ASSETS: 'http://localhost:3052/api/uploads/',

    // PRUEBAS
    // BASE_URL: 'https://pruebas.serdi.com.mx:5050/api',
    // ASSETS: 'https://pruebas.serdi.com.mx:5050/api/uploads/',

    // PRODUCCION
    BASE_URL: 'https://tienda.serdi.com.mx:4051/api',
    ASSETS: 'https://tienda.serdi.com.mx:4051/api/uploads/',


    localStorageNamed: local + 'E-commerce',
};